import React from 'react'
import PromotionForm from './PromotionForm'
const PromotionContent = ({ registerUser }) => {
  return (
    <div
      className="container fluid promotion_content shadow card col-12"
      style={{ marginTop: '55px' }}>
      <div className="row p-4">
        <div className="promotion_info col-lg-8">
          <div className="image_card pt-4 mt-4">
            <div className="row p-1" style={{ marginTop: '-25px' }}>
              <div className="col-5 promotion_info-img">
                <img
                  src={require('../../assets/img/image-landing.png')}
                  alt="Banner Jumbo Prime"
                  style={{ width: '90%', paddingTop: '10px' }}
                />
              </div>
              <div className="col-7 promotion_title">
                <h2>
                  ¡Inscríbete gratis en <br /> <span style={{ color: '#ec1c23' }}>Wong Prime!</span>
                </h2>
                <p>
                  Wong Prime es el programa de membresía de Wong que te permite disfrutar de los
                  siguientes beneficios:
                </p>
              </div>
            </div>
          </div>
          <div className="benefits_list mt-2 pl-2">
            <ul className="pt-3" style={{ listStyle: 'none', paddingLeft: '0' }}>
              {/* <li></li> */}
              <li>Despachos gratis e ilimitados en compras de supermercado, desde S/ 79.90.</li>
              <li>Ofertas exclusivas en Wong.pe y la App Wong</li>
              <li>Doble acumulación de puntos Bonus en Wong.pe y la App Wong</li>
              <li>
                Dos despachos gratuitos por mes en electro y tecnología por compras mayores a S/ 299
              </li>
              <li>Descuentos en combustibles en Primax</li>
              <li>Canal exclusivo de atención en el portal Prime</li>
            </ul>
          </div>
          <div className="promotion-end_message first">
            <p
              style={{
                lineHeight: '1.25',
                fontWeight: '300',
                textAlign: 'center',
                fontSize: '22px',
                backgroundColor: '#383838',
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingTop: '10px',
                paddingBottom: '10px',
                marginTop: '-10px',
                borderRadius: '5px',
                color: '#FFFFFF',
              }}>
              Regístrate y disfruta todo lo de{' '}
              <strong style={{ color: '#ec1c23', fontWeight: '700' }}>Wong Prime, </strong> la
              membresía que te da más.
            </p>
          </div>
        </div>
        <div className="promotion_form col-lg-4 mt-4">
          <PromotionForm registerUser={registerUser} />
        </div>
      </div>
    </div>
  )
}

export default PromotionContent
