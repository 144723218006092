import * as Yup from 'yup'
import { encryptedData } from '../../utils/secret'
import { apiSacSensitive } from '../../services/Sac'

export const initialDocumentValues = {
    oldValue: '',
    updateType: 2,
    newValue: '',
    observation: '',
    reCaptcha: '',
  }
  export const initialEmailValues = {
    oldValue: '',
    updateType: 1,
    newValue: '',
    observation: '',
    reCaptcha: '',
  }
  export const initialDocumentTypeValues = {
    oldValue:'',
    updateType: 3,
    newValue:'',
    dni: '',
    observation: '',
    old_document:'',
    reCaptcha: '',
  }

  export const handleEmailChangeValidation = () => {
    return Yup.object().shape({
        newValue: Yup.string()
            .matches(/^[a-zA-Z][\w.-]{0,98}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Formato de correo inválido')
            .required('El email es requerido'),
        observation: Yup.string()
            .required('El motivo de este cambio es requerido'),
        reCaptcha: Yup.string()
            .required('El reCaptcha es requerido'),
        })  
  }
  
  export const handleDocumentChangeValidation = () => {
    return Yup.object().shape({
        newValue: Yup.string()
            .required('El número de identificación es requerido')
            .when('dni_type', {
            is: '1',
            then: Yup.string()
                .max(8, 'El DNI debe ser de 8 digitos')
                .min(8, 'El DNI debe ser de 8 digitos')
                .matches(/^.[0-9,$].*$/, 'El DNI es sólo número'),
            })
            .when('dni_type', {
            is: '4',
            then: Yup.string().max(12, 'El Carnet de Extranjería debe ser máximo 12 digitos'),
            //.min(8, "El Carnet de Extranjería debe ser minimo de 8 digitos"),
            })
            .when('dni_type', {
            is: '5',
            then: Yup.string().max(12, 'El Pasaporte debe ser máximo 12 digitos'),
            })
            .when('dni_type', {
            is: '6',
            then: Yup.string()
                .max(11, 'El RUC debe ser de 11 digitos')
                .min(11, 'El RUC debe ser de 11 digitos')
                .matches(/^.[0-9,$].*$/, 'El RUC debe ser sólo número'),
            }),
          observation: Yup.string()
              .required('El motivo de este cambio es requerido'),
          reCaptcha: Yup.string()
            .required('El reCaptcha es requerido'),
    })
  }

  export const handleDocumentTypeValidation = () => {
    return Yup.object().shape({
      newValue: Yup.string().required('El tipo de documento es requerido'),
      dni: Yup.string()
        .required('El número de identificación es requerido')
        .when('dni_type', {
          is: '1',
          then: Yup.string()
            .max(8, 'El DNI debe ser de 8 digitos')
            .min(8, 'El DNI debe ser de 8 digitos')
            .matches(/^.[0-9,$].*$/, 'El DNI es sólo número'),
        })
        .when('dni_type', {
          is: '4',
          then: Yup.string().max(12, 'El Carnet de Extranjería debe ser máximo 12 digitos'),
          //.min(8, "El Carnet de Extranjería debe ser minimo de 8 digitos"),
        })
        .when('dni_type', {
          is: '5',
          then: Yup.string().max(12, 'El Pasaporte debe ser máximo 12 digitos'),
          //.min(8, "El Pasaporte debe ser minimo 8 digitos"),
        })
        .when('dni_type', {
          is: '6',
          then: Yup.string()
            .max(11, 'El RUC debe ser de 11 digitos')
            .min(11, 'El RUC debe ser de 11 digitos')
            .matches(/^.[0-9,$].*$/, 'El RUC debe ser sólo número'),
        }),
    observation: Yup.string()
          .required('El motivo de este cambio es requerido'),
    reCaptcha: Yup.string()
          .required('El reCaptcha es requerido'),
    })
  }

  // export const handleChangeData = (value)=>{
  //   console.log(value);
  //   let formValues;
  //   if(value.updateType !== 3){
  //     formValues = {
  //       oldValue: encryptedData(value.oldValue),
  //       newValue: encryptedData(value.newValue),
  //       updateType: encryptedData(value.updateType),
  //       observation: encryptedData(value.observation)
  //     }
  //   }else{
  //     formValues = {
  //       oldValue: [encryptedData(value.oldValue), encryptedData(value.old_document)],
  //       newValue: [encryptedData(value.newValue), encryptedData(value.dni)],
  //       updateType: encryptedData(value.updateType),
  //       observation: encryptedData(value.observation)
  //     }
  //   }
  //   console.log(formValues);
  //   apiSacSensitive(formValues)
  //     .then(res=>{
  //       console.log(res)
  //     })
  //     .catch(err=>{
  //       console.log(err)
  //     })
  // }

