import React from "react";
import { getYear } from "../../utils/dateForm";

const Maintenance = () => {
  // deploy to master
  return (
    <div className="home">
      <header className="header_area animated">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 col-lg-10">
              <div className="menu_area">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <a className="navbar-brand" href="/">
                    <img
                      src={require("../../assets/img/WongPrime1.1.png")}
                      className="logo-a"
                      alt="brand white"
                    />
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="form_inscrip " id="home">
        <div className="container ">
          <div className="row py-5">
            <div className="col-lg-6 mx-auto">
              <div className="card shadow">
                <div className="card-header formulario-header px-5 text-center">
                  <span
                    style={{ fontSize: "72px" }}
                    role="img"
                    aria-label="stop"
                  >
                    👷🚧👷‍♀️
                  </span>
                  <h1 className="display-4">
                    Estamos realizando mantenimiento al sitio.
                  </h1>
                  <p className="mb-0 mt-2 text-muted lead">
                    Por favor intenta suscribirte más tarde, volveremos lo antes
                    posible.
                  </p>
                </div>

                <div className="card-body px-5 pb-5 text-center">
                  <a href="/" className="btn btn-primary">
                    Volver al Home
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="page-footer font-small pt-5 ">
          <img
            src={require("../../assets/img/WongPrime1.1.png")}
            width="150px"
            style={{ margin: "0 auto", display: "block" }}
            alt="brand color"
          />
          <div className="footer-copyright text-center text-muted py-3">
            © Cencosud {getYear()}. Todos los derechos reservados.
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Maintenance;
