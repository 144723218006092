import React from 'react'
import { ProfileSubHeader } from '../../../components/Commons/ProfileSubHeader/ProfileSubHeader'
import useLogin from '../../../redux/hooks/useLogin'
import { CardShopping } from './CardShopping'
import { CardShoppingEmpty } from './CardShoppingEmpty'

const Shopping = () => {
  const { purchases } = useLogin();

  return (
    <>
      <ProfileSubHeader />
      <div className="container-fluid mt--5">
        <div className="row">
          <div className="col">
            {!purchases || purchases.length === 0 ? (
              <CardShoppingEmpty />
            ) : (
              <CardShopping purchases={purchases} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Shopping
