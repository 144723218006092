import { useRegisterSave, useRegisterSaveCoupon } from './hooks'
import ModalCoupon from './ModalCoupon'
import IFCondition from '../../../components/Commons/IFCondition/IFCondition'
const RegisterSave = () => {
  const { info, closeSesion, toPayNiubiz } = useRegisterSave()
  const {benefit_end_date, payment_date, errorMessage, data} = useRegisterSaveCoupon(info)
  return (
    <>
      <div className="card shadow">
        <div className="card-header formulario-header px-5">
          <span>Paso 2 de 3</span>
          <h2>Ingresar tarjeta de crédito</h2>

          {!info.is_active ? (
            <p className="mb-0 mt-2 text-muted f14">
              <strong>¡Nos alegra tenerte de vuelta!</strong> Para activar tus beneficios te
              invitamos a registrar una nueva tarjeta para volver a ser Wong Prime. Para validar tu
              tarjeta se realizará en cobro de{' '}
              <strong>{process.env.REACT_APP_MEMBERSHIP_PRICE}</strong> por costo de la membresía
              los siguientes {process.env.REACT_APP_MEMBERSHIP_DURATION}.
            </p>
          ) : (
            <p className="mb-0 mt-2 text-muted f14">
              Una vez finalizado tu {info.subscription_period} ({info.benefit_end_date}) se
              realizará un cobro de {process.env.REACT_APP_MEMBERSHIP_PRICE} a la{' '}
              <strong>tarjeta de crédito</strong> que ingresarás a continuación en Niubiz para
              mantener tus beneficios por los siguientes {process.env.REACT_APP_MEMBERSHIP_DURATION}
              *. Para validar tu tarjeta se hará una transacción de <strong>S/ 1</strong> que serán
              revertidos a tu cuenta
            </p>
          )}
        </div>
        <div className="card-body px-5 pb-5">
          <ul className="list-inline">
            <li className="list-inline-item">
              <img src={require('../../../assets/img/visa.svg')} alt="" width="40px" />
            </li>
            <li className="list-inline-item">
              <img src={require('../../../assets/img/mastercard.svg')} alt="" width="40px" />
            </li>
            <li className="list-inline-item">
              <img src={require('../../../assets/img/americanexpress.svg')} alt="" width="40px" />
            </li>
          </ul>
          <div
            className="card mb-1 bg-light"
            style={{
              border: '1px solid #fdf0d6',
              boxShadow: '0 0 2rem 0 rgba(136, 152, 170, .2)',
            }}>
            <div className="card-body">
              <strong>Suscripción asociada a:</strong>
              <ul className="list-unstyled f14 mb-0 mt-2 text-muted">
                <li> {info.names}</li>
                <li>{info.email}</li>
                <li>
                  {info.dni_type}: {info.dni}
                </li>
              </ul>
            </div>
          </div>
          <table className="table mt-4 f14">
            <tbody>
              <tr>
                <th scope="row">Suscripción</th>
                <td className="text-right">{info.subscription_period}</td>
              </tr>
              <tr>
                <th scope="row">Inicio {info.subscription_period}</th>
                <td className="text-right">{info.benefit_start_date}</td>
              </tr>
              <tr>
                <th scope="row">Fin {info.subscription_period}</th>
                <td className="text-right">{benefit_end_date}</td>
              </tr>
              <tr>
                <th scope="row">Fecha de cobro</th>
                <td className="text-right">{payment_date}</td>
              </tr>
            </tbody>
          </table>

          {info.is_active && (
            <p className="text-center">
              <strong>Sin compromisos.</strong>
              <br />* Puedes cancelar online tu suscripción hasta el {info.cancel_date}
            </p>
          )}
          <div className="alert alert-warning text-center f14">
            Sólo podrás ingresar una tarjeta de crédito
          </div>

          <button type="button" onClick={toPayNiubiz} className="btn btn-lg btn-primary btn-block">
            {' '}
            Registrar Tarjeta
          </button>
          <ModalCoupon />
          <p className="text-center f14 mt-3">
            Servidor seguro <i className="fa fa-lock fa-lg"></i>
          </p>
          <button onClick={() => closeSesion()} className=" btn btn-link btn-block btn-sm">
            ¿No eres tu? Cerrar sesión
          </button>
          <IFCondition cond={!!data && !errorMessage}>
            <div className="alert alert-success col-xl-12">Cupón canjeado correctamente.</div>
          </IFCondition>
        </div>
      </div>
    </>
  )
}

export default RegisterSave
