import { useSelector } from 'react-redux'

export const useLogin = () => {
  const {
    user,
    isLoading,
    errorMessage,
    token,
    passChanged,
    purchases,
    benefitsSummary,
    sessionType,
  } = useSelector((state) => state.auth)
  return {
    user,
    isLoading,
    errorMessage,
    token,
    passChanged,
    purchases,
    benefitsSummary,
    sessionType,
  }
}

export default useLogin
