import React, { useRef } from 'react'
import { getYear } from '../../../../utils/dateForm'
import { useOnScreen } from '../../../../services/Gtm/gtmInteractions'
const FooterSuscription = () => {
  const refFooter = useRef()
  useOnScreen(refFooter)
  return (
    <div className="page-footer font-small pt-5 " ref={refFooter}>
      <img
        src={require('../../../../assets/img/WongPrime1.1.png')}
        alt=""
        width="150px"
        style={{ margin: '0 auto', display: 'block' }}
      />
      <div className="footer-copyright text-center text-muted py-3">
        © Cencosud {getYear()}. Todos los derechos reservados.
      </div>
    </div>
  )
}

export default FooterSuscription
