import React from 'react'
import HeaderPromotion from './HeaderPromotion';
import PromotionContent from './PromotionContent';
import FooterSuscription from '../../components/Commons/Forms/Layout/FooterSuscription';
import NavbarClean from '../../components/Commons/NavbarClean';
import PromotionModalValidate from './PromotionModalValidate'

const Promotion = () => {
  const [userData, setUserData] = React.useState({});
    function registerUser(_userData) {
        setUserData(_userData);
        window.$('#validate-info-promotion').modal('show');
    }
  return (
    <div className='home'>
      <HeaderPromotion>
        <NavbarClean/>
        <PromotionContent registerUser={registerUser}/>
        <FooterSuscription/>
      </HeaderPromotion>
      <PromotionModalValidate userData={userData}/>
    </div>
  )
}

export default Promotion