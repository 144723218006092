import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { handleCouponValidation, initialCouponValues } from './constans'
import { couponAction } from '../../../redux/actions/CouponActions'
import { AppDispatch } from '../../../redux/utils/AppDispatch'
import Spinner from '../../../components/Commons/Spinner/Spinner'
import Loading from '../../../components/Commons/Loading/Loading'
import IFCondition from '../../../components/Commons/IFCondition/IFCondition'
import { useRegisterCoupon } from './hooks'

const ModalCoupon = () => {
  const { errorMessage, isLoading } = useSelector((state) => state.coupon)
  const { dni } = useRegisterCoupon()
  const btnModal = useRef()
  const handleSubmitCoupon = (values) => {
    values.dni = dni
    AppDispatch(couponAction(values))
  }
  return (
    <>
      <div
        className="modal"
        id="validate-coupon"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header" style={{ display: 'block' }}>
              <h5 className="modal-title">¿Tienes un cupón promocional?</h5>
              <span>En caso de tener un cupón por favor agregalo a continuación.</span>
            </div>
            <Formik
              className="mt-4"
              initialValues={initialCouponValues}
              validationSchema={handleCouponValidation}
              onSubmit={handleSubmitCoupon}>
              {({ values, errors, touched, isValid, dirty, setFieldValue }) => (
                <Form className="form">
                  <div className="modal-body" style={{ padding: '40px' }}>
                    <div className="modal-body">
                      <div className="form-group">
                        <label htmlFor="dni">Cupón:</label>
                        <br />
                        <Field type="text" id="coupon" name="coupon" required />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      ref={btnModal}
                      data-dismiss="modal">
                      No tengo cupón
                    </button>

                    <button
                      type="submit"
                      className={`btn btn-primary`}
                      disabled={!(isValid && dirty)}>
                      Validar cupón
                    </button>
                  </div>
                </Form>
              )}
            </Formik>

            <IFCondition cond={!!errorMessage}>
              <div
                className={` m-3 alert alert-${
                  errorMessage?.internal_message?.level || 'danger'
                } alert-dismissible fade show mt-4`}
                role="alert">
                <strong>
                  <i className="fa fa-exclamation-circle"></i> {'Lo sentimos'}
                </strong>{' '}
                <br />
                {errorMessage}
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </IFCondition>
          </div>
        </div>
      </div>
      <Spinner visible={isLoading} />
      <Loading visible={isLoading} />
    </>
  )
}

export default ModalCoupon;
