import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Spinner from '../../../components/Commons/Spinner/Spinner'
import Loading from '../../../components/Commons/Loading/Loading'
import IFCondition from '../../../components/Commons/IFCondition/IFCondition'
import {
  userCardsAction,
  updateCardAction,
  deleteCardAction,
} from '../../../redux/actions/RegisterActions'
import { AppDispatch } from '../../../redux/utils/AppDispatch'
import { securityPayNiubizAction } from '../../../redux/actions/RegisterPayNiubizActions'
import { encryptedData } from '../../../utils/secret'

const ModalChangeCard = ({ user }) => {
  const { cards, updated, isLoading } = useSelector((state) => state.register)
  const addCard = () => {
    AppDispatch(securityPayNiubizAction(user, true))
  }
  console.log(isLoading)

  const favoriteCard = (card) => {
    let registerForm = {
      id: user?.id,
      email: user?.email,
      first_name: user?.first_name,
      last_name: user?.last_name,
      accepts: true,
      dni: user?.dni,
      phone: user?.phone,
      authenticity_token: user?.authenticity_token,
      plates: {
        plate_code: '',
      },
      coupon_code: '',
      date_birth: user?.date_birth,
      cardId: encryptedData(card.cardId),
    }
    AppDispatch(updateCardAction(registerForm))
  }

  const deleteCard = (card) => {
    AppDispatch(deleteCardAction(encryptedData(card.cardId)))
  }

  useEffect(() => {
    if (user && !updated) {
      AppDispatch(userCardsAction())
    }
  }, [user])

  return (
    <div
      className="modal fade"
      id="change-card"
      tabIndex="-1"
      role="dialog"
      aria-labelledby=""
      aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Cambio de método de pago
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Puedes establecer cualquier tarjeta como método de pago por defecto</p>
            <div className="form-group mb-3">
              {cards && cards.length
                ? cards?.map((card, index) => (
                    <div
                      key={index}
                      className="form-check"
                      style={{
                        border: card.isFavorite
                          ? '2px solid #65ab46'
                          : '1px solid rgba(0, 0, 0, 0.05)',
                        padding: '20px 0 20px 20px',
                        cursor: 'pointer',
                        marginTop: '10px',
                      }}>
                      <IFCondition cond={!card.isFavorite}>
                        <button
                          className="btn btn-sm btn-circle float-right"
                          title="Eliminar tarjeta"
                          type="button"
                          onClick={() => deleteCard(card)}>
                          <i className="fa fa-trash text-danger" aria-hidden="true"></i>
                        </button>
                        <button
                          className="btn btn-sm btn-circle float-right"
                          title="Marcar como favorito"
                          type="button"
                          onClick={() => favoriteCard(card)}>
                          <i className="fa fa-star text-warning" aria-hidden="true"></i>
                        </button>
                      </IFCondition>
                      <label
                        className="form-check-label"
                        style={{
                          display: 'flex',
                        }}>
                        <IFCondition cond={card.brand === 'VISA'}>
                          <img
                            src={require('../../../assets/img/visa.png')}
                            width="45px"
                            alt="icono"
                            className="align-self-center mr-3"
                          />
                        </IFCondition>
                        <IFCondition cond={card.brand === 'MASTERCARD'}>
                          <img
                            src={require('../../../assets/img/mastercard.png')}
                            width="45px"
                            alt="icono"
                            className="align-self-center mr-3"
                          />
                        </IFCondition>
                        <IFCondition cond={card.brand === 'AMEX'}>
                          <img
                            src={require('../../../assets/img/american-express.png')}
                            width="45px"
                            alt="icono"
                            className="align-self-center mr-3"
                          />
                        </IFCondition>
                        <IFCondition
                          cond={!['VISA', 'MASTERCARD', 'AMERICAN-EXPRESS'].includes(card.brand)}>
                          <div>
                            <i
                              className="fa fa-credit-card mr-2"
                              style={{ fontSize: '31px', marginTop: '20px' }}></i>
                          </div>
                        </IFCondition>
                        <span className="d-block text-muted f14">
                          Terminada en **** **** {card.lastDigits}
                        </span>
                      </label>
                      <span className="d-block text-muted f14">Guardada en Wong Prime</span>
                    </div>
                  ))
                : 'No tiene tarjetas agregadas!'}
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-outline-secondary" data-dismiss="modal">
              Cancelar
            </button>
            <button type="button" className="btn btn-primary" onClick={addCard}>
              <i className="fa fa-lock"></i> Agregar tarjeta
            </button>
          </div>
        </div>
      </div>
      <Spinner visible={isLoading} />
      <Loading visible={isLoading} />
    </div>
  )
}

export default ModalChangeCard
