import { useFormik } from "formik";
import {
  unsubscribeAction,
  updateAction,
} from "../../../redux/actions/RegisterActions";
import {
  couponAction
} from "../../../redux/actions/CouponActions";
import { AppDispatch } from "../../../redux/utils/AppDispatch";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  logoutAction,
  updatePasswordAction,
} from "../../../redux/actions/LoginActions";
import useLogin from "../../../redux/hooks/useLogin";
import { toast } from "react-toastify";

export const useModalChange = (btnModal) => {
  const { errorMessage, isLoading, updated, from, fromLoading } = useSelector(
    (state) => state.register
  );
  const { user } = useLogin();
  const formik = useFormik({
    initialValues: {
      dni: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      bday: {
        day: "",
        month: "",
        year: "",
      },
      gender: "",
      address: "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const bday = `${values.bday.day}/${values.bday.month}/${values.bday.year}`;
      const newDate = new Date(
        values.bday.year,
        values.bday.month-1,
        values.bday.day
      );
      delete values.dni;
      delete values.email;
      AppDispatch(updateAction({ ...values, bday: newDate }, bday));
    },
  });

  useEffect(() => {
    if (updated) {
      btnModal.current.click();
    }
    if (user) {
      const dateBirth = (user.bday || "01/01/2000").split("/");
      formik.setValues({
        dni: user.dni,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
        bday: {
          day: dateBirth[0],
          month: dateBirth[1],
          year: dateBirth[2],
        },
        gender: user.gender === "Otro" ? "0" : user.gender === "Femenino" ? "1" : "2",
        address: user.address,
      });
    }
  }, [updated, user]);

  return { formik, errorMessage, isLoading, from, fromLoading };
};

export const useModalChangePassword = (btnModal) => {
  const { errorMessage, passChanged } = useLogin();
  const formik = useFormik({
    initialValues: {
      previewPassword: "",
      password: "",
      confirmPassword: "",
    },
    onSubmit: (values) => {
      AppDispatch(updatePasswordAction(values));
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required("La contraseña es requerida")
        .min(8, "La contraseña debe tener mas de 8 caracteres")
        .max(12, "La contraseña debe tener menos de 12 caracteres")
        .matches(
          /^.*(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "Contraseña debe tener al menos una letra mayuscula, minuscula y un número"
        ),
      confirmPassword: Yup.string()
        .required("La contraseña es requerida")
        .oneOf([Yup.ref("password")], "Las contraseñas no son iguales"),
    }),
  });

  useEffect(() => {
    btnModal.current.click();
    if (passChanged) {
      toast.success("Contraseña actualizada exitosamente.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [passChanged, btnModal]);

  return { formik, passChanged, errorMessage };
};

export const useModalCancel = () => {
  const [submit, setSubmit] = useState(false);
  const { errorMessage, data, isLoading, isUnsubscribed, from, fromLoading } = useSelector(
    (state) => state.register
  );
  const submitCancel = (pass) => {
    setSubmit(true);
    AppDispatch(unsubscribeAction(pass));
  };

  if (isUnsubscribed) {
    setTimeout(() => {
      document.getElementById("cancel-s").click();
      AppDispatch(logoutAction());
    }, 15000);
  }

  return {
    submitCancel,
    errorMessage,
    data,
    isLoading,
    submit,
    isUnsubscribed,
    from,
    fromLoading
  };
};

export const useCouponValidate = () => {
  const { errorMessage, data, isLoading } = useSelector(
    (state) => state.coupon
  );
  
  const couponValidate = (pass) => {
    AppDispatch(couponAction(pass));
  };

  return {
    couponValidate,
    errorMessage,
    data,
    isLoading
  };
};
