import React from 'react'

const HistorialInscripcion = ({ data: info }) => {
  const { data } = info
  const { history } = data.user_detail

  return (
    <div className="col-md-12" style={{ overflow: 'auto' }}>
      <div className="card mb-4 shadow-sm">
        <div className="card-header py-3">
          <p className="float-left display-5 mb-0">
            <i className="fa fa-user"></i> Historial de inscripcion
          </p>
        </div>
        <table className="table align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              <th scope="col">Email</th>
              <th scope="col">Estado</th>
              <th scope="col text-right">Fecha de Inscripción</th>
              <th scope="col">Fecha de Término</th>
              <th scope="col">Fecha de Desuscripción</th>
              <th scope="col text-right">4 últimos digitos</th>
              <th scope="col text-right">Brand</th>
            </tr>
          </thead>
          <tbody>
            {history.map((h, index) => (
              <tr key={index}>
                <td>
                  <strong>{h.email}</strong>
                </td>
                <td>{h.status}</td>
                <td>{h.subscriptionDate}</td>
                <td>
                  <strong>{h.benefitEndDate}</strong>
                </td>
                <td>{h.unsubscribeDate}</td>
                <td>{h.cardLastDigits}</td>
                <td>{h.cardBrand}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default HistorialInscripcion
