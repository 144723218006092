import React from "react";

const HeaderFormPay = ({ children }) => {
  return (
    <div>
      <div className="card shadow">
        <div className="card-header formulario-header px-5">
          <span>Paso 3 de 3</span>
          <h1 className="display-4">Web Pay U</h1>
        </div>
        <div className="card-body px-5 pb-5">{children}</div>
      </div>
    </div>
  );
};

export default HeaderFormPay;
