import { apiCoupon } from '../../services/Coupon'
import { COUPON_VALIDATE_LOAD, COUPON_VALIDATE_SUCCESS, COUPON_VALIDATE_FAILED } from '../reducers/CouponReducers'

const _COUPON_VALIDATE_LOAD = () => ({
  type: COUPON_VALIDATE_LOAD,
  payload: { data: null, error: null },
})

const _COUPON_VALIDATE_SUCCESS = (data) => ({
  type: COUPON_VALIDATE_SUCCESS,
  payload: data,
})

const _COUPON_VALIDATE_FAILED = (error) => ({
  type: COUPON_VALIDATE_FAILED,
  payload: {
    error,
    data: null,
  },
})

export const couponAction = (body) => (dispatch) => {
  dispatch(_COUPON_VALIDATE_LOAD())
  apiCoupon(body)
    .then((res) => {
      dispatch(_COUPON_VALIDATE_SUCCESS(res.data))
    })
    .catch((error) => dispatch(_COUPON_VALIDATE_FAILED(error)))
}
