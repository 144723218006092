import React from 'react'

const HeaderPromotion = ({children}) => {
  return (
        <div className="main_promotion w-100">
            <div className="main_promotion-content mx-auto">
                <div className="row pt-4">
                        {children}
                </div>
            </div>
        </div>
  )
}

export default HeaderPromotion