import React from 'react'
import ReactDOM from 'react-dom'
import AppRouter from './routes/AppRouter'
import './assets/css/profile.scss'
import './assets/css/style.scss'
// import './assets/css/fontawesome-v5.11-all.css';
//

ReactDOM.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>,
  document.getElementById('root')
)
