import * as Yup from 'yup'
import { AppDispatch } from '../../redux/utils/AppDispatch'
import {
  registerPremiumAction,
  _REGISTER_END_LOADING,
  _REGISTER_START_LOADING,
} from '../../redux/actions/RegisterActions'

export const handleValidation = () => {
  return Yup.object().shape({
    dni_type: Yup.string().required('El tipo de documento es requerido'),
    dni: Yup.string()
      .required('El número de identificación es requerido')
      .when('dni_type', {
        is: '1',
        then: Yup.string()
          .max(8, 'El DNI debe ser de 8 digitos')
          .min(8, 'El DNI debe ser de 8 digitos')
          .matches(/^.[0-9,$].*$/, 'El DNI es sólo número'),
      })
      .when('dni_type', {
        is: '4',
        then: Yup.string().max(12, 'El Carnet de Extranjería debe ser máximo 12 digitos'),
        //.min(8, "El Carnet de Extranjería debe ser minimo de 8 digitos"),
      })
      .when('dni_type', {
        is: '5',
        then: Yup.string().max(12, 'El Pasaporte debe ser máximo 12 digitos'),
        //.min(8, "El Pasaporte debe ser minimo 8 digitos"),
      })
      .when('dni_type', {
        is: '6',
        then: Yup.string()
          .max(11, 'El RUC debe ser de 11 digitos')
          .min(11, 'El RUC debe ser de 11 digitos')
          .matches(/^.[0-9,$].*$/, 'El RUC debe ser sólo número'),
      }),
    email: Yup.string().email('No es un email válido').required('El email es requerdio'),
    password: Yup.string()
      .required('La contraseña es requerida')
      .min(8, 'La contraseña debe tener más de 8 caracteres')
      .max(12, 'La contraseña debe tener menos de 12 caracteres')
      .matches(
        /^.*(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        'Contraseña debe tener al menos una letra mayuscula, minuscula y un número'
      )
      .oneOf([Yup.ref('password_confirmation'), null], 'Las contraseñas no son iguales'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Las contraseñas no son iguales')
      .required('La confirmación del password es requerida'),
    accepts: Yup.string().required('Debe aceptar términos y condiciones'),
    // accepts_policies: Yup.string().required('Debe aceptar términos y condiciones'),
    isProd: Yup.boolean(),
  })
}

export const initialValues = {
  dni: '',
  email: '',
  address: '',
  password: '',
  password_confirmation: '',
  accepts: '',
  accepts_policies: '',
  dni_type: '1',
  country: 'Peru',
  department: '15',
  district: '',
  city: '',
  gender: '1',
}

export const handleRegister = (formValue) => {
  AppDispatch(registerPremiumAction({ ...formValue }))
}
