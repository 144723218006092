import { React, useEffect, useState } from 'react'
import HeroImageForms from '../../../components/Commons/Forms/Layout/HeroImageForms'
import FooterSuscription from '../../../components/Commons/Forms/Layout/FooterSuscription'
import { useRegisterSuccess } from './hooks'
import { Link } from 'react-router-dom'
import Confetti from '../../../components/Commons/Confetti/Confetti'
import API from '../../../services/Api'
import Skeleton from 'react-loading-skeleton'
import { decryptedData, encryptedData } from '../../../utils/secret'

const RegisterSuccess = () => {
  const [user, setUser] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isBonus, setIsBonus] = useState(true)

  const { showEffect } = useRegisterSuccess()

  const params = new URLSearchParams(document.location.search)
  const dni_params = decodeURIComponent(params.get('token'))

  const userInfo = () => {
    const data = decryptedData(dni_params, true)
    setUser(data)
    handleRequestBonus({ dni: data.dni, dniType: data.dniType })
    setIsLoading(false)
  }

  const handleRequestBonus = (dni) => {
    API.post(`${process.env.REACT_APP_API_URL}/api/v1/niubiz/validate/bonus`, {
      dni: encryptedData(dni),
    })
      .then(function (response) {
        console.log('Consulta Bonus')
        setIsBonus(response.data.data.isAffiliated)
      })
      .catch(function (error) {
        console.log('Error Consulta Bonus', error)
      })
  }

  useEffect(() => {
    if (dni_params !== null) {
      userInfo()
    } else {
      window.location.replace('/*')
    }
  }, [])

  return (
    <div className="home">
      <HeroImageForms>
        <div className="card shadow">
          <div className="card-body px-5 pb-5 card-body-confirm">
            <div className="text-center">
              <img
                src={require('../../../assets/img/success-wong.jpg')}
                style={{ width: '60%', marginTop: '-60px' }}
                alt=""
              />

              <h2 className="mt-3">
                Bienvenido a <strong>Wong Prime</strong>
              </h2>
              <p className="text-muted">
                <strong>
                  {isLoading ? (
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                  ) : (
                    user?.fullname
                  )}
                </strong>{' '}
                Ya eres Wong Prime. Tus beneficios estarán habilitados en pocos minutos.
                <br />
                Ante cualquier duda escribenos a wongprime@cencosud.com.pe
              </p>
            </div>

            <table className="table mt-5 f14">
              <tbody>
                <tr>
                  <th scope="row">Inicio suscripción</th>
                  <td className="text-right">
                    {isLoading ? (
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    ) : (
                      user?.subscriptionDate
                    )}{' '}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Fecha de Cobro</th>
                  <td className="text-right">
                    {isLoading ? (
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    ) : (
                      user?.payment_date
                    )}{' '}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Usuario</th>
                  <td className="text-right">
                    {isLoading ? (
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    ) : (
                      user?.email
                    )}{' '}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Tarjeta Asociada</th>
                  <td className="text-right">
                    **** **** ****{' '}
                    {isLoading ? (
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    ) : (
                      user?.card_last_digits
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="text-muted  text-center f12">
              Recuerda que puedes desuscribirte sin cobro hasta el {user?.benefit_end_date}.
            </p>
            <p className="text-center f12">
              A tu correo remitimos un mail de bienvenida con la información de tu membresía, si no
              llega a tu bandeja de entrada te invitamos a revisar tu bandeja de Spam o correo no
              deseado
            </p>
            <div>
              {isBonus ? (
                ''
              ) : (
                <a
                  href="https://afiliate.bonus.com.pe"
                  className="btn btn-lg btn-primary btn-block">
                  Registrate en Bonus
                </a>
              )}
            </div>
            <div className="text-center mt-5">
              <Link to="/" className="btn btn-link btn-extended ">
                Ir al Home
              </Link>
              <Link to="/auth/login" className="btn btn-outline-prime btn-extended ">
                Ir Wong Prime
              </Link>
            </div>
          </div>
        </div>
        <FooterSuscription />
      </HeroImageForms>
      {showEffect && <Confetti />}
    </div>
  )
}

export default RegisterSuccess
