import React from "react";
import { Link } from "react-router-dom";
import { closeSesionAction } from "../../../redux/actions/LoginActions";
import { AppDispatch } from "../../../redux/utils/AppDispatch";
import IFCondition from "../IFCondition/IFCondition";
import { useProfileNavbar } from "./hooks";
import { useSelector } from "react-redux";

const ProfileNavbar = ({sac}) => {
    const {errorMessage} = useSelector(state=>state.auth)
    const { user } = useProfileNavbar();
    return (
        <>
            <nav className="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
            <div className="container-fluid">
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    {/* Navbar links */}
                    <ul className="navbar-nav align-items-center  ml-md-auto ">
                        <li className="nav-item d-xl-none">
                            {/* Sidenav toggler */}
                            <div className="pr-3 sidenav-toggler sidenav-toggler-dark" data-action="sidenav-pin" data-target="#sidenav-main">
                                <div className="sidenav-toggler-inner">
                                    <i className="sidenav-toggler-line"></i>
                                    <i className="sidenav-toggler-line"></i>
                                    <i className="sidenav-toggler-line"></i>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul className="navbar-nav align-items-center  ml-auto ml-md-0 ">
                        {/* <li className="nav-item dropdown">
                            <a className="nav-link" href="/#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-info-circle"></i>
                            </a>
                            <div className="dropdown-menu  dropdown-menu-right ">
                                <div className="dropdown-header noti-title">
                                    <h6 className="text-overflow m-0">Información</h6>
                                </div>
                                <Link to="terminos-y-condiciones" className="dropdown-item">
                                    <i className="fa fa-file"></i>
                                    <span>Términos y condiciones</span>
                                </Link>
                                <Link to="preguntas-frecuentes" className="dropdown-item">
                                    <i className="fa fa-question-circle"></i>
                                    <span>Preguntas frecuentes </span>
                                </Link>
                            </div>
                        </li> */}
                        <li className="nav-item dropdown">
                            <a className="nav-link pr-0" href="/#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div className="media align-items-center">
                                    <span className="avatar avatar-sm rounded-circle">
                                        <img alt="" src={require("../../../assets/img/user.png")} />
                                    </span>
                                    <div className="media-body  ml-2  d-none d-lg-block">
                                        <span className="mb-0 text-sm  font-weight-bold">{user?.first_name}</span>
                                    </div>
                                </div>
                            </a>
                            <div className="dropdown-menu  dropdown-menu-right ">
                                <div className="dropdown-header noti-title">
                                    <h6 className="text-overflow m-0">Bienvenido</h6>
                                </div>
                                <IFCondition cond={!sac}>
                                    <Link to="cuenta" className="dropdown-item">
                                        <i className="fa fa-user"></i>
                                        <span>Mi cuenta</span>
                                    </Link>
                                </IFCondition>
                                <div className="dropdown-divider"></div>
                                <button onClick={() => {AppDispatch(closeSesionAction());}} className="dropdown-item">
                                    <i className="fa fa-sign-out"></i>
                                    <span>Cerrar sesión</span>
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <IFCondition cond={errorMessage}>
          <div className="alert-close_sesion">
          <div className="alert alert-danger alert-dismissible fade show content" role="alert">
            <span className="alert-icon">
              <i className="fa fa-exclamation-circle"></i>
            </span>
            <span className="alert-text">Hubo un error al cerrar sesión en su cuenta. Por favor intente nuevamente.</span>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          </div>
        </IFCondition>
        </>
    )
}

export default ProfileNavbar