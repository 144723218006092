import React from 'react'
import HeroImageForms from '../../../components/Commons/Forms/Layout/HeroImageForms'
import FooterSuscription from '../../../components/Commons/Forms/Layout/FooterSuscription'
import { useRegisterPremiumSave } from './hooks'
import moment from 'moment'
const RegisterPremiumSave = () => {
  const { info, closeSesion, toPayNiubiz } = useRegisterPremiumSave()
  return (
    <div className="home">
      <HeroImageForms>
        <div className="card shadow">
          <div className="card-header formulario-header px-5">
            <h2>Ingresar tarjeta de crédito</h2>

            <p className="mb-0 mt-2 text-muted f14">
              Una vez finalizado tu periodo bonificado (
              {moment(info.benefit_end_date).format('DD/MM/YYYY')}) se realizará un cobro de{' '}
              {process.env.REACT_APP_MEMBERSHIP_PRICE} a la <strong>tarjeta de crédito</strong> que
              ingresarás a continuación en Niubiz para mantener tus beneficios por los siguientes{' '}
              {process.env.REACT_APP_MEMBERSHIP_DURATION}
              *. Para validar tu tarjeta se hará una transacción de <strong>S/ 1</strong> que serán
              revertidos a tu cuenta
            </p>
          </div>
          <div className="card-body px-5 pb-5">
            <ul className="list-inline">
              <li className="list-inline-item">
                <img src={require('../../../assets/img/visa.svg')} alt="" width="40px" />
              </li>
              <li className="list-inline-item">
                <img src={require('../../../assets/img/mastercard.svg')} alt="" width="40px" />
              </li>
              <li className="list-inline-item">
                <img src={require('../../../assets/img/americanexpress.svg')} alt="" width="40px" />
              </li>
            </ul>
            <div
              className="card mb-1 bg-light"
              style={{
                border: '1px solid #fdf0d6',
                boxShadow: '0 0 2rem 0 rgba(136, 152, 170, .2)',
              }}>
              <div className="card-body">
                <strong>Suscripción asociada a:</strong>
                <ul className="list-unstyled f14 mb-0 mt-2 text-muted">
                  <li> {info.names}</li>
                  <li>{info.email}</li>
                  <li>{info.dni}</li>
                </ul>
              </div>
            </div>
            <table className="table mt-4 f14">
              <tbody>
                {/* <tr>
                  <th scope="row">Suscripción</th>
                  <td className="text-right">{info.subscription_period}</td>
                </tr> */}
                <tr>
                  <th scope="row">Inicio</th>
                  <td className="text-right">
                    {moment(info.benefit_start_date).format('DD/MM/YYYY')}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Fin</th>
                  <td className="text-right">
                    {moment(info.benefit_end_date).format('DD/MM/YYYY')}
                  </td>
                </tr>
                {/* Se eliminó fecha de cobro */}
              </tbody>
            </table>

            {info.is_active && (
              <p className="text-center">
                <strong>Sin compromisos.</strong>
                <br />* Puedes cancelar online tu suscripción hasta el {info.cancel_date}
              </p>
            )}
            <div className="alert alert-warning text-center f14">
              Sólo podrás ingresar una tarjeta de crédito
            </div>

            <button
              type="button"
              onClick={toPayNiubiz}
              className="btn btn-lg btn-primary btn-block">
              {' '}
              Registrar Tarjeta
            </button>
            <p className="text-center f14 mt-3">
              Servidor seguro <i className="fa fa-lock fa-lg"></i>
            </p>
            <button onClick={() => closeSesion()} className=" btn btn-link btn-block btn-sm">
              ¿No eres tu? Cerrar sesión
            </button>
          </div>
        </div>
        <FooterSuscription />
      </HeroImageForms>
    </div>
  )
}

export default RegisterPremiumSave
