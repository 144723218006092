import React, { useState } from 'react'
import HeroImageForms from '../../../components/Commons/Forms/Layout/HeroImageForms'
import FooterSuscription from '../../../components/Commons/Forms/Layout/FooterSuscription'
import { useRegisterSuccess } from './hooks'
import { Link } from 'react-router-dom'
import Confetti from '../../../components/Commons/Confetti/Confetti'
import moment from 'moment'

const RegisterPremiumSuccess = () => {
  const { state, showEffect } = useRegisterSuccess()

  const { data } = state
  return (
    <div className="home">
      <HeroImageForms>
        <div className="card shadow">
          <div className="card-body px-5 pb-5 card-body-confirm">
            <div className="text-center">
              <img
                src={require('../../../assets/img/success-wong.jpg')}
                style={{ width: '60%', marginTop: '-60px' }}
                alt=""
              />

              <h2 className="mt-3">
                Bienvenido a <strong>Wong Prime</strong>
              </h2>
              <p className="text-muted">
                <strong>{data?.first_name}</strong> Ya eres Wong Prime. Tus beneficios estarán
                habilitados en pocos minutos.
                <br />
                Ante cualquier duda escribenos a wongprime@cencosud.com.pe
              </p>
            </div>

            <table className="table mt-5 f14">
              <tbody>
                <tr>
                  <th scope="row">Inicio suscripción</th>
                  <td className="text-right">
                    {moment(data?.benefit_start_date).format('DD/MM/YYYY')}{' '}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Usuario</th>
                  <td className="text-right">{data?.email} </td>
                </tr>
              </tbody>
            </table>
            <p className="text-muted  text-center f12">
              Recordá que podés desuscribirte sin costo hasta el {data?.benefit_end_date}.
            </p>
            <p className="text-center f12">
              A tu correo remitimos un mail de bienvenida con la información de tu membresía, si no
              llega a tu bandeja de entrada te invitamos a revisar tu bandeja de Spam o correo no
              deseado
            </p>
            <div className="text-center mt-5">
              <Link to="/" className="btn btn-link btn-extended ">
                Ir al Home
              </Link>
              <Link to="/auth/login" className="btn btn-outline-prime btn-extended ">
                Ir Wong Prime
              </Link>
            </div>
          </div>
        </div>
        <FooterSuscription />
      </HeroImageForms>
      {showEffect && <Confetti />}
    </div>
  )
}

export default RegisterPremiumSuccess
