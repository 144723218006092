import React from 'react'
import DatosClientes from './DatosClientes'
import DatosCobros from './DatosCobros'
import HistorialCompras from './HistorialCompras'
import HistorialInscripcion from './HistorialInscripcion'
import Search from './Search'
import { useSelector } from 'react-redux'
import SkeletonComponent from '../../components/Commons/SkeletonComponent'
import NotFoundUser from '../../components/Commons/NotFoundUser'
import ModalChangeEmail from './ModalChangeEmail'
import ModalChangeDocument from './ModalChangeDocument'
import ModalChangeDocumentType from './ModalChangeDocumentType'

const InicioSac = (props) => {
  const { data, isLoading } = useSelector((state) => state.sac)
  const { user } = useSelector((state) => state.auth)
  const pages = ['inicio', 'cobros', 'compras', 'inscripcion']
  const BodySac = () => {
    if (pages.includes(props.path)) {
      if (data.length === 0) {
        return ''
      }
      if (Object.keys(data).length === 3) {
        return <NotFoundUser />
      }
      if (Object.keys(data.data).length === 0) {
        return <NotFoundUser />
      }
    }
    const render = {
      inicio: (
        <>
          <DatosClientes data={data} sacUser={user} />
          <ModalChangeEmail data={data} />
          <ModalChangeDocument data={data} />
          <ModalChangeDocumentType data={data} />
        </>
      ),
      cobros: <DatosCobros data={data} />,
      compras: <HistorialCompras data={data} />,
      inscripcion: <HistorialInscripcion data={data} />,
    }
    return render[props.path] || <Search />
  }

  return (
    <div className="container mt--5">
      <div className="row justify-content-md-center">
        <div className="col-md-10 justify-content-md-center">
          <Search />
          {isLoading && data.length === 0 ? <SkeletonComponent /> : <BodySac />}
        </div>
      </div>
    </div>
  )
}

export default InicioSac
