import React, { useState } from 'react'
import QuestionTree from './questionTree'
import useLogin from '../../../redux/hooks/useLogin'
import { sendTicket } from '../../../services/Help'
import ReCAPTCHA from 'react-google-recaptcha'
import { GOOGLE_CAPTCHA_KEY } from '../../../utils/constants'
import TextURL from '../../../components/Commons/TextURL/TextURL'

const QuestionNode = (props) => {
  const [show, setShow] = useState(false)
  const [captcha, setCaptcha] = useState(null)
  const [showTicket, setShowTicket] = useState(false)
  const [ticket, setTicket] = useState('')
  const [showComment, setShowComment] = useState(false)
  const [comment, setComment] = useState('')
  const { question, customKey } = props
  const { user } = useLogin()
  const bookUrl = 'https://www.wong.pe/consultas-y-sugerencias'

  const sendFeedback = () => {
    sendTicket({
      comments: comment,
      business_help_category_id: question.id,
      reCaptcha: captcha,
    })
      .then((res) => {
        setTicket(res.data.data.data.ticket.external_code)
        window.open(bookUrl)
        setShowTicket(true)
      })
      .catch(() => {})
  }
  const updateComment = (event) => {
    setComment(event.target.value)
  }

  if (!question || !user) return <></>

  return (
    <div>
      {showTicket && (
        <div className="alert alert-success alert-dismissible fade show" role="alert">
          <span className="alert-icon">
            <i className="fa fa-check-circle "></i>
          </span>
          <span className="alert-text">Consulta enviada: Su ticket es N°{ticket}</span>
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      )}
      {question.final === 0 && (
        <a
          className="list-group-item text-decoration-none"
          id="list-home-list"
          href="#list-home"
          role="tab"
          data-toggle="collapse"
          data-target={'#accordion-tab-' + question.id + '-content-' + customKey}
          aria-expanded="false"
          aria-controls={'accordion-tab-' + question.id + '-content-' + customKey}
          onClick={() => {
            setShow(!!true)
          }}>
          {question.text === 'Revisar T&C.' ? (
            <span size="1">{question.text}</span>
          ) : (
            <>
              {' '}
              <i className="fa fa-info-circle text-info" aria-hidden="true"></i>
              <span>&nbsp; {question.text} </span>{' '}
            </>
          )}
        </a>
      )}

      <div>
        {question.final === 1 && <TextURL text={question.text} />}

        {question.data_list && (
          <ul>
            {question.data_list.map((element, index) => (
              <li key={index}>{element}</li>
            ))}
          </ul>
        )}

        <br />
        {question.show_form && show && (
          <div id={'accordion-tab-' + question.id + '-content-' + customKey}>
            <>
              <textarea
                type="text"
                name="comment"
                id="comment"
                style={{ minHeight: '120px' }}
                className="input empty form-control"
                placeholder="Cuentanos que te paso y te ayudaremos a buscar la mejor solución."
                value={comment}
                onChange={updateComment}
              />
              <br />

              <div className="row align-items-center">
                <div className="col-6">
                  <ReCAPTCHA
                    sitekey={GOOGLE_CAPTCHA_KEY}
                    name="reCaptcha"
                    onChange={(value) => setCaptcha(value)}
                  />
                </div>
                <div className="col-6">
                  <button
                    className="btn btn-primary"
                    disabled={comment === '' || captcha === null}
                    onClick={() => sendFeedback()}>
                    Enviar comentario
                  </button>
                </div>
              </div>
            </>
          </div>
        )}

        {question.choose_answer && (
          <>
            <div className="row justify-content-md-center">Resolvimos tu inquietud</div>
            <div className="row justify-content-md-center">
              <button className="btn btn-primary" onClick={() => setShowComment(false)}>
                Sí
              </button>
              <button className="btn btn-danger" onClick={() => setShowComment(true)}>
                No
              </button>
            </div>
            {showComment && (
              <>
                <textarea
                  type="text"
                  name="comment"
                  id="comment"
                  style={{ minHeight: '120px', marginTop: '20px' }}
                  className="input empty form-control"
                  placeholder="Tu comentario"
                  value={comment}
                  onChange={updateComment}
                />

                <br />

                <div className="row align-items-center">
                  <div className="col-6">
                    <ReCAPTCHA
                      sitekey={GOOGLE_CAPTCHA_KEY}
                      name="reCaptcha"
                      onChange={(value) => setCaptcha(value)}
                    />
                  </div>
                  <div className="col-6">
                    <button
                      className="btn btn-primary"
                      disabled={comment === '' || captcha === null}
                      onClick={() => sendFeedback()}>
                      Enviar comentario
                    </button>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <div id={'accordion-tab-' + question.id + '-content-' + customKey} className="collapse">
        <div className="card-body">
          {show && question.final === 0 && !question.show_form ? (
            <QuestionTree parentId={question.id} />
          ) : null}
        </div>
      </div>
    </div>
  )
}
export default QuestionNode
