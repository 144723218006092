import * as CryptoJS from 'crypto-js'

export const encryptedData = (data) => {
  let dataToEncrypt = typeof data == 'string' ? data : JSON.stringify(data)
  const ciphertext = CryptoJS[process.env.REACT_APP_TYPE_ENCRYPTION].encrypt(
    dataToEncrypt,
    process.env.REACT_APP_SECRET_ENCRYPTION
  )
  return ciphertext.toString()
}

export const decryptedData = (ciphertext, isObject) => {
  const bytes = CryptoJS[process.env.REACT_APP_TYPE_ENCRYPTION].decrypt(
    ciphertext.toString(),
    process.env.REACT_APP_SECRET_ENCRYPTION
  )
  const decryptedMessage = isObject
    ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    : bytes.toString(CryptoJS.enc.Utf8)
  return decryptedMessage
}
