

export const COUPON_VALIDATE_LOAD = "coupon/COUPON_VALIDATE_LOAD";
export const COUPON_VALIDATE_SUCCESS = "coupon/COUPON_VALIDATE_SUCCESS";
export const COUPON_VALIDATE_FAILED = "coupon/COUPON_VALIDATE_FAILED";

const initialValues = { data: '', isLoading: false, errorMessage: '' }

const CouponReducers = (state = initialValues, action) => {
    switch (action.type) {
        case COUPON_VALIDATE_LOAD:
            return {
                ...state,
                isLoading: true,
                data: ''
            };
        case COUPON_VALIDATE_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                isLoading: false,
                errorMessage: action.payload.error,
            };

        case COUPON_VALIDATE_FAILED:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload.error.response.data.error.user_message,
            };
        default:
            return state;
    }
};

export default CouponReducers;
