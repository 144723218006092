import React, { useState } from 'react'
import { AppDispatch } from '../../redux/utils/AppDispatch'
import { sacAction } from '../../redux/actions/SacActions'

const Search = () => {
  const [user, setUser] = useState('')
  const handleChange = (e) => {
    setUser(e.target.value)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    const dni = {
      dni: user,
    }
    AppDispatch(sacAction(dni))
    setUser('')
  }

  return (
    <div className="col-12">
      <div className="card  shadow-sm">
        <div className="card-body">
          <label>Consultar información del cliente</label>
          <form className="input-group mb-3" onSubmit={handleSubmit}>
            <input
              type="text"
              className="form-control"
              placeholder="DNI ó email"
              value={user}
              required
              onChange={handleChange}
            />
            <div className="input-group-append">
              <button className="btn btn-primary" type="submit">
                Buscar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Search
