import React from 'react';
import { Link } from 'react-router-dom';

const NavbarClean = () => {
    return <>
        <header className="header_area animated">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-10">
                        <div className="menu_area">
                            <nav className="navbar navbar-expand-lg navbar-light">
                                <Link className="navbar-brand" to="/">
                                    <img src={require('../../assets/img/WongPrime1.1.png')} className="logo-a" alt="wongprime" />
                                </Link>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </>
};

export default NavbarClean;
