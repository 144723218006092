import React from "react";
import FormPayU from "./FormPayU";
import HeroImageForms from "../../../components/Commons/Forms/Layout/HeroImageForms";
import FooterSuscription from "../../../components/Commons/Forms/Layout/FooterSuscription";
import HeadersFormPay from "../../../components/Commons/Forms/HeaderFormPay";

const RegisterPayU = () => {
  return (
    <div className="home">
      <HeroImageForms>
        <HeadersFormPay>
          <FormPayU modal={false} />
        </HeadersFormPay>
        <FooterSuscription />
      </HeroImageForms>
    </div>
  );
};

export default RegisterPayU;
