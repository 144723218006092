import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppDispatch } from '../../redux/utils/AppDispatch'
import { getParameters } from '../../services/Register'
import { _REGISTER_END_LOADING, _REGISTER_START_LOADING } from '../../redux/actions/RegisterActions'
import { encryptedData } from '../../utils/secret'
import { apiSacSensitive } from '../../services/Sac'
import { toast } from 'react-toastify'
import { sacAction } from '../../redux/actions/SacActions'
import { number } from 'yup'

export const useGetParameters = () => {
  const [oldValues, setOldValues] = useState({
    oldDni: null,
    validDni: true,
    active: false,
    status: null,
  })
  const { isLoading } = useSelector((state) => state.register)
  const [parameters, setParameters] = useState({
    dniTypes: [],
  })

  useEffect(() => {
    AppDispatch(_REGISTER_START_LOADING())
    Promise.all([getParameters('dniTypes')])
      .then(([dni]) => {
        setParameters({
          dniTypes: dni.data.data.data,
        })
      })
      .finally(() => {
        AppDispatch(_REGISTER_END_LOADING())
      })
  }, [])

  return { isLoading, parameters }
}

export const useSacSensitive = () => {
  const [loading, setLoading] = useState(false)
  const handleChangeData = (value) => {
    setLoading(true)
    if (
      (typeof value.oldValue === 'string' || value.oldValue instanceof String) &&
      value.updateType === 2
    ) {
      value.oldValue = value.oldValue * 1
    }
    let formValues
    if (value.updateType !== 3) {
      formValues = {
        oldValue: encryptedData(value.oldValue),
        newValue: encryptedData(value.newValue),
        updateType: encryptedData(value.updateType),
        observation: encryptedData(value.observation),
        reCaptcha: value.reCaptcha,
      }
    } else {
      formValues = {
        oldValue: [encryptedData(value.oldValue), encryptedData(value.old_document)],
        newValue: [encryptedData(value.newValue), encryptedData(value.dni)],
        updateType: encryptedData(value.updateType),
        observation: encryptedData(value.observation),
        reCaptcha: value.reCaptcha,
      }
    }
    apiSacSensitive(formValues)
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
          toast.error(`${res.data.data.message}`, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.success(`${res.data.data.message}`, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          let newValue
          if (value.updateType !== 3) {
            const data = isNaN(value.newValue) ? value.newValue : '' + value.newValue
            newValue = {
              dni: data,
            }
          } else {
            newValue = {
              dni: '' + value.dni,
            }
          }
          AppDispatch(sacAction(newValue))
        }
        setLoading(false)
      })
      .catch((err) => {
        if (err.response.status === 500) {
          toast.error('Hubo un error al actualizar los datos. Intente nuevamente', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          const message =
            err.response.data.message == undefined
              ? err.response.data.data.message
              : err.response.data.message
          toast.error(`${message}`, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
        setLoading(false)
      })
  }
  return {
    handleChangeData,
    loading,
  }
}
