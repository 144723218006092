import React from 'react';
import { Link } from 'react-router-dom';
import NavbarClean from '../NavbarClean';

const NotFound = () => {
    return <div className="home">
        <NavbarClean />
        <div id="notfound">
            <div className="notfound">
                <div className="notfound-404">
                    <h1>Oops!</h1>
                </div>
                <h2>404 - Pagina no encontrada</h2>
                <p>Es posible que la página que está buscando se haya eliminado si se cambió el nombre o no está disponible temporalmente.</p>
                <Link to="/" className="btn btn-primary btn-extended">ir al Home</Link>
            </div>
        </div>
    </div>
};

export default NotFound;
