import React from 'react'

const HistorialCompras = ({ data: info }) => {
  const { data } = info
  const { purchases } = data.user_detail

  return (
    <div className="col-lg-12">
      <div className="card mb-4 shadow-sm">
        <div className="card-header py-3">
          <p className="float-left display-5 mb-0">
            <i className="fa fa-shopping-cart"></i> Historial de compras
          </p>
        </div>
        {purchases.length > 0 ? (
          <table className="table align-items-center table-flush">
            <thead className="thead-light">
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Fecha</th>
                <th scope="col text-right">Monto</th>
                {/* <th scope="col">Tipo</th> */}
              </tr>
            </thead>
            <tbody>
              {purchases.map((p, index) => (
                <tr key={index}>
                  <td>{p.id}</td>
                  <td>{p.purchaseDate}</td>
                  <td>{p.finalAmount}</td>
                  {/* <td>{p?.type}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="col-6 mt-5">
            <div
              className="alert alert-danger text-center"
              role="alert"
              style={{ with: '70%', fontSize: '18px' }}>
              No hay datos de compras para este usuario
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default HistorialCompras
