import { encryptedData } from "../../utils/secret";
import API from "../Api";

export const apiRegister = (registerForm) =>
  API.post(`/api/v1/user`, registerForm);

export const apiRegisterPremium = (registerForm) =>
  API.post(`/api/v1/user/premiumCreate`, registerForm);

export const apiRegisterPayU = (registerForm) =>
  API.patch(`/api/v1/user/${registerForm.dni}/paymethod`, registerForm);


export const apiSecurityNiubiz = () =>
  API.get(`/api/v1/niubiz`);

export const apiUserEnable = (registerForm) =>
  API.patch(`/api/v1/user/${registerForm.dni}/enable`, registerForm);

export const apiUpdate = (updateForm) => 
  API.put(`/api/v1/user`, updateForm);

export const apiUnsubscribe = () => 
  API.put(`/api/v1/subscription/unsubscribe`);

export const apiUserCards = () =>
  API.get(`/api/v1/user/cards`);

export const apiUpdateCard = (registerForm) =>
  API.put('/api/v1/user/card', {cardId: registerForm.cardId} );

  export const apiDeleteCard = (cardId) =>
  API.delete('/api/v1/user/card', {data: {cardId}});

export const apiCreateCard = (registerForm) =>
  API.post(`/api/v1/card`, registerForm);

export const getParameters = (type, other = "") =>
  API.get(`/api/v1/parameters?type=${type}&${other}`);
