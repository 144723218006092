import React from 'react'

const HeroHelp = () => {
    return (
        <>
            <div className="header pb-6">
                <div className="container-fluid">
                    <div className="header-body">
                        <div className="row align-items-center py-4">
                            <div className="col text-center">
                                <h6 className="h2 text-white d-inline-block mb-0"><i className="fa fa-headset"></i> Ayuda Prime</h6>
                                <p className="text-white mt-2"> <i className="fa fa-clock"></i> Horario de atención de Lunes a sabado 7:00 am a 9:00 pm Domingo y festivo 7:00 am a 7:00 pm</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeroHelp
