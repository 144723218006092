import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import { getYear } from '../../utils/dateForm'
import { useOnScreen } from '../../services/Gtm/gtmInteractions'
import { clickFooterGTM } from '../../services/Gtm/gtmInteractions'

const Footer = () => {
  const { handleClick } = clickFooterGTM()
  const refFooter = useRef()
  useOnScreen(refFooter)
  return (
    <div id="footer" ref={refFooter}>
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-links">
              <h4 className="mt-2">Contácto</h4>
              <ul
                onClick={(e) => {
                  handleClick(e, 'Contácto')
                }}>
                <li>
                  <i className="fa fa-envelope"></i>{' '}
                  <a href={'mailto: wongprime@cencosud.com.pe'}> wongprime@cencosud.com.pe</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-6 footer-links">
              <h4 className="mt-2">Enlaces útiles</h4>
              <ul
                onClick={(e) => {
                  handleClick(e, 'Enlaces útiles')
                }}>
                <li>
                  <i className="fa fa-angle-right"></i>{' '}
                  <a href={'http://wong.pe/'} rel="noreferrer" target="_blank">
                    Home
                  </a>
                </li>
                <li>
                  <i className="fa fa-angle-right"></i> <a href="/faq/#faq">Preguntas frecuentes</a>
                </li>
                <li>
                  <i className="fa fa-angle-right"></i>{' '}
                  <a href="/terminos-y-condiciones/#terminos">Términos y condiciones</a>
                </li>
                <li>
                  <i className="fa fa-angle-right"></i>{' '}
                  <a href="/terminos-y-condiciones-promocionales">Términos Promocionales</a>
                </li>
                <li>
                  <i className="fa fa-angle-right"></i>{' '}
                  <a href="/politicas-y-privacidad/#politicas">Politicas y Privacidad</a>
                </li>

                <li>
                  <i className="fa fa-angle-right"></i>{' '}
                  <a href="https://www.wong.pe/consultas-y-sugerencias">Consultas y Sugerencias</a>
                </li>
                <li>
                  <i className="fa fa-angle-right"></i>{' '}
                  <a href="https://linkr.bio/wongoficial">Descarga la App Wong aquí</a>
                </li>
                <li>
                  <i className="fa fa-angle-right"></i> <a href="https://www.wong.pe/">Wong.pe</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 footer-contact">
              {/*
                <Link to="/">
                  <img
                    src={require("../../assets/img/brand-white.png")}
                    alt=""
                    className="logo-footer"
                    style={{ height: "50px" }}
                  />
                </Link>
              */}
              <a href={'/'} rel="noreferrer" className="ml-2">
                <img
                  src={require('../../assets/img/WongPrime1.1.png')}
                  alt=""
                  className="logo-footer"
                  style={{ height: '50px', backgroundColor: '#fff', borderRadius: '25px' }}
                />
              </a>
              <br />
              <a
                onClick={(e) => {
                  handleClick(e, 'Libro de reclamaciones')
                }}
                target="__blank"
                href={'https://librorec.wong.pe/frmLRRegistro.aspx'}>
                <img
                  src={require('../../assets/img/libro-de-reclamaciones.png')}
                  style={{
                    width: '171px',
                    backgroundColor: 'rgb(255, 255, 255)',
                    borderRadius: '5px',
                  }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="copyright text-center">
            <img
              className="mr-3"
              src={require('../../assets/img/WongPrime1.1.png')}
              alt="logo Cencosud"
              width="75px"
              style={{ backgroundColor: '#fff', borderRadius: '10px' }}
            />
            ©{' '}
            <strong>
              <span>Cencosud {getYear()}</span>
            </strong>
            . Todos los derechos reservados. Creado por Gerencia de Nuevos Negocios
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
