import React from 'react'

function TextURL({ text }) {
  console.log('TEXT: ', text)
  function convertTextToLink(text) {
    return text.replace(/(https?:\/\/[^\s]+)/g, (url) => `<a href="${url}">${url}</a>`)
  }

  return (
    <div className="text-black-50 justify-content-md-center">
      <p dangerouslySetInnerHTML={{ __html: convertTextToLink(text) }}></p>
    </div>
  )
}

export default TextURL
