import * as Yup from 'yup'
import { AppDispatch } from '../../../redux/utils/AppDispatch'
import { loginAction } from '../../../redux/actions/LoginActions'

export const handleValidation = () => {
  return Yup.object().shape({
    email: Yup.string().required('¡Este campo es requerido!').email('¡Debe ser un email correcto!'),
    password: Yup.string()
      .required('¡Este campo es requerido!')
      .min(4, 'La contraseña debe tener al menos 4 caracteres'),
  })
}
export const initialValues = { email: '', password: '' }
export const handleLogin = (formValue) => {
  return AppDispatch(loginAction(formValue))
}
