import { useEffect } from "react";
import { geInfoUserAction, getInfoBenefitsAction } from "../../../redux/actions/LoginActions";
import useLogin from "../../../redux/hooks/useLogin";
import { AppDispatch } from "../../../redux/utils/AppDispatch";

export const useProfileNavbar = () => {
    const { user, token, isLoading } = useLogin();
    useEffect(() => {
        if(token) {
            AppDispatch(geInfoUserAction());
        }
    }, []);

    useEffect(() => {
        AppDispatch(getInfoBenefitsAction());
    }, [user]);

    return { user, isLoading }
}