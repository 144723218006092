import * as Yup from "yup";
import { AppDispatch } from "../../../redux/utils/AppDispatch";
import { loginSacAction } from "../../../redux/actions/LoginActions";

export const handleValidation = () => {
    return Yup.object().shape({
        email: Yup.string().required("¡Este campo es requerido!").email("¡Debe ser un email correcto!"),
        password: Yup.string().required("¡Este campo es requerido!").min(4),
    });
}
export const initialValues = { email: "", password: "" };
export const handleLogin= (formValue) => AppDispatch(loginSacAction(formValue));