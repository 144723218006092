import React from 'react'
import { Link } from 'react-router-dom'
import { clickGTM } from '../../services/Gtm/gtmInteractions'
const About = () => {
  const { handleClick } = clickGTM()

  return (
    <div className="home">
      {/* <div className="section-anniversary">
        <img
          className="anniversary-section-desktop"
          src={require('../../assets/img/new-section-desktop-anniversary.png')}
          alt="Sección aniversario"
        />
        <img
          className="anniversary-section-mobile"
          src={require('../../assets/img/new-section-mobile-anniversary.png')}
          alt="Sección aniversario"
        />
      </div> */}
      <div className="container-fluid">
        <div className="row no-gutter">
          <div
            className="d-none d-md-flex col-md-4 col-lg-6 bg-image-about"
            style={{ zIndex: '1' }}>
            <div className="col-md-12 mt-3"></div>
          </div>
          <div className="col-md-8 col-lg-6 bg-white background-frutas">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-8 mx-auto">
                    <div className="special_description_content first">
                      <h2 className="mt-1">
                        ¿Conoces <span>Wong Prime?</span>
                      </h2>
                      <p>
                        Es el programa de membresía de Wong que te permite disfrutar de los
                        siguientes beneficios:&nbsp;
                      </p>
                      <ul style={{ padding: '0%, 8%, 8%, 8%' }}>
                        <li className="lh-1-3 margen-bottom align_li_to_icon">
                          <img src={require('../../assets/img/boton3.png')} width="60" alt="" />
                          &nbsp;
                          <span>
                            Despachos gratis e ilimitados en compras de supermercado, desde S/
                            79.90.
                          </span>
                        </li>
                        <li className="lh-1-3 margen-bottom align_li_to_icon">
                          <img src={require('../../assets/img/boton2.png')} width="60" alt="" />
                          &nbsp;
                          <span>Ofertas exclusivas en Wong.pe y la App Wong.</span>
                        </li>
                        <li className="margen-bottom align_li_to_icon">
                          <img src={require('../../assets/img/boton1.png')} width="60" alt="" />
                          &nbsp;
                          <span>Doble acumulación de puntos Bonus en Wong.pe y la App Wong.</span>
                        </li>
                        <li className="lh-1-3 margen-bottom align_li_to_icon">
                          <img src={require('../../assets/img/boton6.png')} width="60" alt="" />
                          &nbsp;
                          <span>
                            Dos despachos gratuitos por mes en electro y tecnología por compras
                            mayores a S/ 299
                          </span>
                        </li>
                        <li className="lh-1-3 margen-bottom align_li_to_icon">
                          <img src={require('../../assets/img/boton5.png')} width="60" alt="" />
                          &nbsp;
                          <span>
                            Descuentos en combustibles en Primax{' '}
                            <img
                              src={require('../../assets/img/primax-transparente.png')}
                              width="150"
                              alt=""
                            />
                          </span>
                        </li>
                        <li className="lh-1-3 margen-bottom align_li_to_icon">
                          <img src={require('../../assets/img/boton4.png')} width="60" alt="" />
                          &nbsp;
                          <span>Canal exclusivo de atención en el portal Prime.</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="special_description_area secction-2  section_padding_100_70"
        style={{ zIndex: '1' }}>
        <div className="container">
          <div className="row justify-content-end">
            <div className=" col-md-8 col-lg-6">
              <div className="special_description_content">
                <h2>
                  ¡Prueba Wong Prime
                  <br />
                  <span>Gratis!</span>
                </h2>
                <p>
                  Disfruta de la experiencia Prime por un periodo de{' '}
                  {process.env.REACT_APP_IS_NEW_CAMPAIGN === 'true' ? (
                    <>
                      <del>{process.env.REACT_APP_CURRENT_NUMBER_FREE_DAYS}</del>{' '}
                      {process.env.REACT_APP_FUTURE_NUMBER_FREE_DAYS}
                    </>
                  ) : (
                    `${process.env.REACT_APP_CURRENT_NUMBER_FREE_DAYS}`
                  )}{' '}
                  días.
                  <br /> Luego solo te costará {process.env.REACT_APP_MEMBERSHIP_PRICE} cada{' '}
                  {process.env.REACT_APP_MEMBERSHIP_DURATION}*.
                </p>
                <p>
                  <strong>Sin compromisos,</strong> puedes cancelar la suscripción de manera online
                  cuando quieras. 
                </p>
                <Link
                  onClick={(e) => {
                    handleClick(e, 'Home - Body')
                  }}
                  to="/auth/register"
                  className="btn btn-primary btn-extended">
                  Inicia ahora
                </Link>
                <p className="mt-1" style={{ fontSize: '16px' }}>
                  {/* redeploy */}
                  *Vigencia de la campaña del {process.env.REACT_APP_VALIDITY_CAMPAIGN}. Stock
                  mínimo promocional: 5,000 promociones. Terminada la vigencia de la campaña el
                  precio de suscripción será de S/ 90 cada 6 meses.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="special_description_area section_padding_70  secction-3">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-6 col-xl-5">
              <div className="special_description_content">
                <h2 className="mt-5">
                  Wong Prime<span>¡Te conviene!</span>
                </h2>
                <div className="row justify-content-md-end">
                  <div className="col-md-10">
                    <table className="table table-borderless table-striped table-sm pl-2 text-right">
                      <thead>
                        <tr>
                          <th scope="col f16"></th>
                          <th scope="col" className="text-center">
                            <img
                              src={require('../../assets/img/WongPrime1.1.png')}
                              width="70"
                              className="text-center"
                              alt=""
                            />
                          </th>
                          <th scope="col" className="text-center">
                            Sin Prime
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-left pl-3 f16">1 envio</td>
                          <td className="text-center f16">S/ 0</td>
                          <td className="text-center f16">S/ 9.90</td>
                        </tr>
                        <tr>
                          <td className="text-left pl-3 f16">3 envios</td>
                          <td className="text-center f16">S/ 0</td>
                          <td className="text-center f16">S/ 29.70</td>
                        </tr>
                        <tr>
                          <td className="text-left pl-3 f16">6 envios</td>
                          <td className="text-center f16">S/ 0</td>
                          <td className="text-center f16">S/ 59.40</td>
                        </tr>
                        {/* <tr>
                          <td className="text-left pl-3 f16">5 envios</td>
                          <td className="text-center f16">S/ 0</td>
                          <td className="text-center f16">S/ 49.50</td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>

                <p style={{ lineHeight: 'normal' }}>
                  <small>*Sujeto a restricciones.</small>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="special_description_img">
                <img
                  src={require('../../assets/img/BOLSA_SOLA.png')}
                  style={{ width: '60%' }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="special_description_area section_padding_70  secction-4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="text-white text-center">
                <h1>
                  ¿Cómo me uno a
                  <img
                    src={require('../../assets/img/WongPrime1.1.png')}
                    style={{ width: '20%', marginLeft: '-1%' }}
                    className="logo_wong_step"
                    alt=""
                  />
                  ?
                </h1>
              </div>
            </div>
          </div>

          <div className="row justify-content-md-center mt-5">
            <div className="col-12 col-md-3 mb-2">
              <div
                className="single-special bg-light text-center  wow fadeInUp h-100"
                data-wow-delay="0.2s">
                <img src={require('../../assets/img/B1.png')} alt="" />
                <p className="mt-3">
                  <strong>Suscríbete</strong> a Wong Prime y comienza tu{' '}
                  <strong>prueba gratis</strong> por{' '}
                  {process.env.REACT_APP_IS_NEW_CAMPAIGN === 'true' ? (
                    <>
                      <del>{process.env.REACT_APP_CURRENT_NUMBER_FREE_DAYS}</del>{' '}
                      {process.env.REACT_APP_FUTURE_NUMBER_FREE_DAYS}
                    </>
                  ) : (
                    `${process.env.REACT_APP_CURRENT_NUMBER_FREE_DAYS}`
                  )}{' '}
                  días.
                </p>
              </div>
            </div>

            <div className="col-12 col-md-3 mb-2">
              <div
                className="single-special bg-light text-center wow fadeInUp h-100"
                data-wow-delay="0.4s">
                <img src={require('../../assets/img/B2.png')} alt="" />
                <p className="mt-3">
                  Ingresa a{' '}
                  <a
                    onClick={(e) => {
                      handleClick(e, 'Home - Body')
                    }}
                    href="http://wong.pe/"
                    rel="noreferrer"
                    target="_blank"
                    style={{ color: '#ec1c24' }}>
                    wong.pe
                  </a>{' '}
                  o App Wong, con el mismo correo electrónico inscrito.
                </p>
              </div>
            </div>

            <div className="col-12 col-md-3 mb-2">
              <div
                className="single-special bg-light text-center wow fadeInUp h-100"
                data-wow-delay="0.8s">
                <img src={require('../../assets/img/B3.png')} alt="" />
                <p className="mt-3">
                  Haz tus compras y aprovecha las{' '}
                  <strong>ofertas exclusivas y grandes beneficios por ser Wong Prime</strong>.
                </p>
              </div>
            </div>
            <div className="col-12">
              <p
                className="text-center text-white"
                style={{ fontSize: '22px', marginTop: '10%' }}></p>
              <p className="text-center text-white" style={{ fontSize: '22px' }}></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
