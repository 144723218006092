import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import IFCondition from '../../../components/Commons/IFCondition/IFCondition'
import { useCouponValidate } from './hooks'
import Loading from '../../../components/Commons/Loading/Loading'

const Detail = ({ user }) => {
  const couponRef = useRef(HTMLInputElement)
  const { updated } = useSelector((state) => state.register)
  const { errorMessage, couponValidate, data, isLoading } = useCouponValidate()
  const setCouponValidate = () => {
    const val = couponRef.current.value
    if (val) {
      couponValidate({
        coupon: val,
        dni: user.dni,
        persist: true,
        coupon_profile: true,
      })
    }
  }
  return (
    <div>
      <div className="header pb-6">
        <div className="container-fluid">
          <div className="header-body">
            <div className="row pt-4">
              <div className="col-lg-7 col-md-10">
                <h1 className="display-2 text-white">{user?.fullname}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt--5">
        <IFCondition cond={updated}>
          <div className="alert alert-success alert-dismissible fade show" role="alert">
            <span className="alert-icon">
              <i className="fa fa-check-circle "></i>
            </span>
            <span className="alert-text">Su cambios han sido guardado con éxito.</span>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </IFCondition>
        <div className="row">
          <div className="col-xl-8">
            <div className="card">
              <div className="card-header">
                <h3 className="mb-0">Información personal</h3>
              </div>
              <div className="card-body ">
                <div className="row mb-3 f14">
                  <div className="col w-50">
                    <span className="d-block">Nombre completo</span>
                    <span className="d-block text-muted ml-1">{user?.fullname}</span>
                  </div>
                  <div className="col w-50 ">
                    <span className="d-block">Número de identificación</span>
                    <span className="d-block text-muted ml-1">{user?.dni}</span>
                  </div>
                </div>
                <div className="row mb-3 f14">
                  <div className="col w-50 ">
                    <span className="d-block">Email</span>
                    <span className="d-block text-muted ml-1">{user?.email}</span>
                  </div>
                  <div className="col w-50 ">
                    <span className="d-block">Fecha de nacimiento</span>
                    <span className="d-block text-muted ml-1">{user?.bday}</span>
                  </div>
                </div>
                <div className="row mb-3 f14">
                  <div className="col w-50">
                    <span className="d-block">Teléfono </span>
                    <span className="d-block text-muted ml-1">+51 {user?.phone}</span>
                  </div>
                  <div className="col w-50 ">
                    <span className="d-block">Género</span>
                    <span className="d-block text-muted ml-1">{user?.gender}</span>
                  </div>
                </div>
                <div className="row mb-3 f14">
                  <div className="col w-50 ">
                    <span className="d-block">Dirección</span>
                    <span className="d-block text-muted ml-1">{user?.address}</span>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div className="row">
                  <div className="col">
                    <button className="btn btn-link" data-toggle="modal" data-target="#change-pass">
                      <i className="fa fa-key"></i> Cambiar contraseña
                    </button>
                  </div>
                  <div className="col text-right">
                    <button
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#change-info">
                      <i className="fa fa-edit"></i> Cambiar datos
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h3 className="mb-0">Código Promocional</h3>
              </div>
              <div className="card-body ">
                <div className="row mb-3 f14">
                  <div className="col-xl-8">
                    <div className="form-group">
                      <input style={{ display: 'none' }} />
                      <input type="text" style={{ display: 'none' }} />
                      <input
                        type="text"
                        ref={couponRef}
                        className="form-control"
                        placeholder="Ingresa tu cupón"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-xl-4">
                    <button onClick={setCouponValidate} className="btn btn-success w-100">
                      Canjear
                    </button>
                  </div>
                </div>
                <div className="row mb-3 f14">
                  <IFCondition cond={!!data && !errorMessage}>
                    <div className="alert alert-success col-xl-12">
                      Cupon canjeado correctamente.
                    </div>
                  </IFCondition>
                </div>
                <div className="row mb-3 f14">
                  <IFCondition cond={!!errorMessage}>
                    <div className="alert alert-warning col-xl-12">{errorMessage}</div>
                  </IFCondition>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="card">
              <div className="card-header">
                <h3 className="mb-0">Tu suscripción</h3>
              </div>
              <div className="card-body">
                <IFCondition cond={user?.subscription_type === 'free'}>
                  <div className="card mb-2  card-suscripcion">
                    <div className="card-body">
                      <strong>Período de prueba gratis</strong>
                      <br />
                      <span className="text-muted">
                        {' '}
                        <del>{process.env.REACT_APP_MEMBERSHIP_PRICE}</del>
                      </span>
                      <strong className="text-success"> Gratis</strong>
                    </div>
                  </div>
                  <p className="f12">
                    Haremos la renovación de tu suscripción el {user?.benefit_end_date} de forma
                    automática. Si no deseas que se realice, puedes cancelarla en cualquier momento.
                  </p>
                </IFCondition>
                <IFCondition cond={user?.subscription_type === 'full'}>
                  <div className="card mb-2  card-suscripcion">
                    <div className="card-body">
                      Prime <strong>Full</strong>
                      <br />
                      <span className="text-muted">
                        {process.env.REACT_APP_MEMBERSHIP_PRICE}{' '}
                        <small>Cada {process.env.REACT_APP_MEMBERSHIP_DURATION}</small>
                      </span>
                    </div>
                  </div>
                  <p className="f12">
                    Tu suscripción comenzo el {user?.benefit_start_date} y se renovará
                    automaticamente el {user?.benefit_end_date}. Si no deseas que se realice, puedes
                    cancelar su suscripción en cualquier momento.
                  </p>
                </IFCondition>

                <div className="card mb-2">
                  <div className="card-body media p-3">
                    <IFCondition cond={user?.card_brand === 'VISA'}>
                      <img
                        src={require('../../../assets/img/visa.png')}
                        width="45px"
                        alt="icono"
                        className="align-self-center mr-3"
                      />
                    </IFCondition>
                    <IFCondition cond={user?.card_brand === 'MASTERCARD'}>
                      <img
                        src={require('../../../assets/img/mastercard.png')}
                        width="45px"
                        alt="icono"
                        className="align-self-center mr-3"
                      />
                    </IFCondition>
                    <IFCondition cond={user?.card_brand === 'AMEX'}>
                      <img
                        src={require('../../../assets/img/american-express.png')}
                        width="45px"
                        alt="icono"
                        className="align-self-center mr-3"
                      />
                    </IFCondition>
                    <IFCondition
                      cond={!['VISA', 'MASTERCARD', 'AMERICAN-EXPRESS'].includes(user?.card_brand)}>
                      <div>
                        <i
                          className="fa fa-credit-card mr-2"
                          style={{ fontSize: '31px', marginTop: '20px' }}></i>
                      </div>
                    </IFCondition>
                    <div className="media-body">
                      <span className="d-block">Método de pago</span>
                      <span className="d-block text-muted f14">
                        Terminada en <strong>**** **** {user?.card_last_digits}</strong>
                      </span>
                      <br />
                      <a href="/" className="f12" data-toggle="modal" data-target="#change-card">
                        Editar <i className="fa fa-edit"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <button
                  data-toggle="modal"
                  data-target="#cancel-s"
                  className="btn  btn-block btn-danger">
                  Cancelar la suscripción
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loading visible={isLoading} />
    </div>
  )
}

export default Detail
