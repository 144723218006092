import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { usePageViewsGTM } from '../services/Gtm/gtmInteractions'
import store from '../redux/store'
import Home from '../views/Home/Home'
import Login from '../views/Auth/Login/Login'
import Register from '../views/Auth/Register/Register'
import Reset from '../views/Auth/Reset/Reset'
import Sidebar from '../components/Commons/Sidebar'
import SidebarSac from '../components/Commons/SidebarSac'
import Index from '../views/Profile/Index/Index'
import Shopping from '../views/Profile/Shopping/Shopping'
import Account from '../views/Profile/Account/Account'
import Faq from '../views/Faq/Faq'
import Terms from '../views/Terms/Terms'
import TermsPromotions from '../views/Terms/TermsPromotions'

import TermsAndConditions from '../views/Profile/Policies/TermsAndConditions'
import PolicyAndPrivacy from '../views/Profile/Policies/PolicyAndPrivacy'
import FaQProfile from '../views/Profile/Policies/FaQProfile'
import HelpPrime from '../views/Profile/HelpPrime/HelpPrime'
import ConfirmError from '../views/Errors/ConfirmError'
import Maintenance from '../views/Errors/Maintenance'
import RegisterSaveResult from '../views/Auth/Register/RegisterSaveResult'
import RegisterSuccess from '../views/Auth/Register/RegisterSuccess'
import RegisterPayU from '../views/Auth/Register/RegisterPayU'
import LandingPayNiubiz from '../views/Auth/Register/LandingPayNiubiz'
import NotFound from '../components/Commons/NotFound/NotFound'
import LoginSac from '../views/Auth/LoginSac/LoginSac'
import Benefits from '../views/Profile/Benefits/Benefits'
import InicioSac from '../views/Sac/InicioSac'
import Public from './middlewares/Public'
import PublicSac from './middlewares/PublicSac'
import Private from './middlewares/Private'
import PrivateSac from './middlewares/PrivateSac'
import Recover from '../views/Auth/Recover/Recover'
import Policies from '../views/Policies/Policies'
import RegisterPremiumSuccess from '../views/Auth/Register/RegisterPremiumSuccess'
import RegisterPremiumSave from '../views/Auth/Register/RegisterPremiumSave'
import Promotion from '../views/Promotion/Promotion'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import IFCondition from '../components/Commons/IFCondition/IFCondition'

const AppRouter = () => {
  console.log('MAINTENANCE_MODE: ', process.env.REACT_APP_MAINTENANCE_MODE)
  console.log('ENV: ', process.env.REACT_APP_ENV)
  const MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE_MODE === 'true'
  const RouteTract = ({ children }) => {
    usePageViewsGTM()
    return children
  }

  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <IFCondition cond={MAINTENANCE_MODE && MAINTENANCE_MODE === true}>
            <Routes>
              <Route
                path="*"
                element={
                  <RouteTract>
                    <Maintenance />
                  </RouteTract>
                }
              />
            </Routes>
          </IFCondition>
          <IFCondition cond={!MAINTENANCE_MODE || MAINTENANCE_MODE === false}>
            <Routes>
              <Route
                path="/"
                element={
                  <RouteTract>
                    <Home />
                  </RouteTract>
                }
              />
              <Route
                path="/faq"
                element={
                  <RouteTract>
                    <Faq />
                  </RouteTract>
                }
              />

              <Route path="/promocion" element={<Promotion />} />
              <Route
                path="/auth/login"
                element={
                  <Public>
                    <RouteTract>
                      <Login />
                    </RouteTract>
                  </Public>
                }
              />
              <Route
                path="/auth/login/sac"
                element={
                  <PublicSac>
                    <LoginSac />
                  </PublicSac>
                }
              />
              <Route
                path="/auth/reset"
                element={
                  <RouteTract>
                    <Reset />
                  </RouteTract>
                }
              />
              <Route
                path="/auth/recover"
                element={
                  <RouteTract>
                    <Recover />
                  </RouteTract>
                }
              />
              <Route
                path="/auth/register"
                element={
                  <Public>
                    <RouteTract>
                      <Register />
                    </RouteTract>
                  </Public>
                }
              />
              <Route path="/auth/prueba" element={<LandingPayNiubiz />} />
              <Route
                path="/auth/register-save"
                element={
                  <RouteTract>
                    <RegisterSaveResult />
                  </RouteTract>
                }
              />
              <Route
                path="/auth/register-success"
                element={
                  <RouteTract>
                    <RegisterSuccess />
                  </RouteTract>
                }
              />
              <Route path="/auth/register-premium-success" element={<RegisterPremiumSuccess />} />
              <Route path="/auth/register-premium-save" element={<RegisterPremiumSave />} />
              <Route
                path="/auth/register-payu"
                element={
                  <RouteTract>
                    <RegisterPayU />
                  </RouteTract>
                }
              />
              <Route
                path="/perfil"
                element={
                  <Private>
                    <Sidebar />
                  </Private>
                }>
                <Route path="inicio" element={<Index />} />
                <Route path="compras" element={<Shopping />} />
                <Route path="cuenta" element={<Account />} />
                <Route path="beneficios" element={<Benefits />} />
                <Route path="cuponera" element={<Index />} />
                <Route path="ayuda" element={<HelpPrime />} />
                <Route path="terminos-y-condiciones" element={<TermsAndConditions />} />

                <Route path="preguntas-frecuentes" element={<FaQProfile />} />
              </Route>
              <Route
                path="/terminos-y-condiciones"
                element={
                  <RouteTract>
                    <Terms />
                  </RouteTract>
                }
              />
              <Route path="terminos-y-condiciones-promocionales" element={<TermsPromotions />} />

              <Route
                path="politicas-y-privacidad"
                element={
                  <RouteTract>
                    <Policies />
                  </RouteTract>
                }
              />
              {/* <Route path="/politicas-y-proteccion" element={<Policies />} /> */}
              <Route path="/form-confirm-error" element={<ConfirmError />} />
              <Route path="/mantenimiento" element={<Maintenance />} />
              <Route
                path="/sac"
                element={
                  <PrivateSac>
                    <SidebarSac />
                  </PrivateSac>
                }>
                <Route path="inicio" element={<InicioSac path="inicio" />} />
                <Route path="datos-cobros" element={<InicioSac path="cobros" />} />
                <Route path="historial-compras" element={<InicioSac path="compras" />} />
                <Route path="historial-inscripcion" element={<InicioSac path="inscripcion" />} />
                <Route path="terminos-y-condiciones" element={<TermsAndConditions />} />

                <Route path="preguntas-frecuentes" element={<FaQProfile />} />
              </Route>
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </IFCondition>
        </BrowserRouter>
      </Provider>
      <ToastContainer />
    </>
  )
}

export default AppRouter
