import React, { useState, useEffect } from 'react'
import { helpTree } from '../../../services/Help'
import QuestionNode from './questionNode'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const QuestionTree = (props) => {
  const { parentId } = props
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    helpTree(parentId).then(({ data }) => {
      // console.log('help data: ', data.data.data.help)
      setQuestions(data.data.data.help)
    })
  }, [parentId])

  return (
    <>
      {questions.length !== 0 || <Skeleton count={3} />}
      <div className="list-group list-group-flush" id="list-tab" role="tablist">
        {questions.map((question, key) => {
          // console.log('arbol: ', question.id + '-' + key)
          const newKey = question.id + '-' + key
          return <QuestionNode question={question} key={newKey} customKey={newKey} />
        })}
      </div>
    </>
  )
}

export default QuestionTree
