import React from 'react'
import { ProfileSubHeader } from '../../../components/Commons/ProfileSubHeader/ProfileSubHeader'

const Index = () => {
  return (
    <>
      <ProfileSubHeader />
      {/* Page content */}
      <div className="container-fluid mt--5">
        <div className="card">
          <div className="card-body">
            <div className="row py-4 justify-content-lg-center">
              <div className="col-md-6 col-lg-5">
                <img
                  src={require('../../../assets/img/RECIBIMIENTO-fondo.jpg')}
                  className="img-fluid"
                  alt="wongprime"
                  style={{ margin: '0 auto' }}
                />
              </div>
              <div className="col-md-6">
                <h2 className="mt-3">
                  Recuerda <span className="display-3 d-block text-primary"> Tus beneficios</span>
                </h2>
                <div className="card mb-2">
                  <div className="card-body">
                    Delivery gratis e ilimitado en compras desde S/ 79.90 en supermercado.
                  </div>
                </div>
                <div className="card mb-2">
                  <div className="card-body">
                    Dos deliverys gratis por mes en electro, tecnología, muebles y colchones por
                    compras desde S/ 299
                  </div>
                </div>
                <div className="card mb-2">
                  <div className="card-body">Descuentos en combustibles en Primax</div>
                </div>
                <div className="card mb-2">
                  <div className="card-body">
                    Doble acumulación de puntos Bonus en Wong.pe y la App Wong.
                  </div>
                </div>
                <div className="card mb-2">
                  <div className="card-body">
                    Ofertas exclusivas en Wong.pe y la App Wong.
                  </div>
                </div>
                <p className="btn btn-primary mt-3">
                  <a href="https://www.wong.pe/" class="color-buttom" target="_blank">
                    Ir a comprar aquí
                  </a>
                </p>
              </div>
            </div>

            <div className="row py-4 justify-content-lg-center">
              <div className="col-lg-5">
                <h2 className="mt-5 text-right">
                  Wong Prime
                  <span className="display-3 d-block text-primary">¡Te conviene!</span>
                </h2>
                <table className="table table-borderless table-striped table-sm pl-2 text-right">
                  <thead>
                    <tr>
                      <th scope="col f16"></th>
                      <th scope="col" className="text-center">
                        <img
                          src={require('../../../assets/img/WongPrime1.1.png')}
                          width="70"
                          className="text-center"
                          alt=""
                        />
                      </th>
                      <th scope="col" className="text-center">
                        Sin Prime
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-left pl-3 f16">1 envio</td>
                      <td className="text-center f16">S/ 0</td>
                      <td className="text-center f16">S/ 9.90</td>
                    </tr>
                    <tr>
                      <td className="text-left pl-3 f16">3 envios</td>
                      <td className="text-center f16">S/ 0</td>
                      <td className="text-center f16">S/ 29.70</td>
                    </tr>
                    <tr>
                      <td className="text-left pl-3 f16">6 envios</td>
                      <td className="text-center f16">S/ 0</td>
                      <td className="text-center f16">S/ 59.40</td>
                    </tr>
                  </tbody>
                </table>

                <p style={{ lineHeight: 'normal' }} className="text-right">
                  <small>*Sujeto a restricciones.</small>
                </p>
                <div className="text-right">
                  <p className="btn btn-primary mt-3">
                    <a href="https://www.wong.pe/" class="color-buttom" target="_blank">
                      Ir a comprar aquí
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  src={require('../../../assets/img/BOLSA_SOLA.png')}
                  alt="wongprime"
                  className="img-fluid"
                  style={{ width: '50%' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Index
