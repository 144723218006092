import React from 'react'

const LandingPayNiubiz = (props) => {

   
    return (
        <div>
            <h1>HOLA LANDING</h1>
        </div>
    )
}

export default LandingPayNiubiz
