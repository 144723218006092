import React from "react";
import './HeaderStep.css';

const HeaderStep = (props) => {
  const { step } = props;
  return (
    <ol className="wizard">
      <li className={step === 1 ? 'current' : ''}>Datos <br/> Personales</li>
      <li className={step === 2 ? 'current' : ''}>Dirección <br/> Correo</li>
      <li className={step === 3 ? 'current' : ''}>Seguridad</li>
    </ol>
  );
};

export default HeaderStep;
