import React from 'react'
import { Navigate } from 'react-router-dom'
import useLogin from '../../redux/hooks/useLogin'

const Private = ({ children }) => {
  const { user, token, sessionType } = useLogin()
  if ((!user && !token) || sessionType !== 'USER') return <Navigate to="/auth/login" />
  if (user && [0, 7, 6].includes(user.status) && sessionType === 'USER')
    return <Navigate to="/auth/register-save" state={{ data: user }} />

  return children
}

export default Private
