import React from 'react'
import HeroImageForms from '../../../components/Commons/Forms/Layout/HeroImageForms'
import FooterSuscription from '../../../components/Commons/Forms/Layout/FooterSuscription'
import RegisterSave from './RegisterSave'

const RegisterSaveResult = () => {
    return (
        <div className="home">
            <HeroImageForms>
                <RegisterSave />
                <FooterSuscription />
            </HeroImageForms>

        </div>
    )
}

export default RegisterSaveResult
