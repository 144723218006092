import {
  apiLogin,
  apiLogged,
  apiUserInfo,
  apiLoginSac,
  apiUpdatePassword,
  apiUserAttempts,
  apiCloseSession,
} from '../../services/Login'
import { apiBenefits } from '../../services/Profile'
import {
  LOADING,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  CLOSE_SESION_FAILED,
  LOGOUT,
  ENABLE_USER,
  SET_TOKEN,
  LOGIN_PASSWORD_CHANGED,
  UPDATE_USER,
  INFO_BENEFITS_SUCCESS,
  INFO_BENEFITS_FAIL,
} from '../reducers/LoginReducers'
import { removeRegisterData } from './RegisterActions'

const _LOADING = () => ({
  type: LOADING,
  payload: { data: null, error: null },
})

const _SET_TOKEN = (data, sessionType) => ({
  type: SET_TOKEN,
  payload: { data, error: null, sessionType },
})

const _LOGIN_SUCCESS = (data) => ({
  type: LOGIN_SUCCESS,
  payload: {
    data,
    error: null,
  },
})

const _LOGIN_PASSWORD_CHANGED = () => ({
  type: LOGIN_PASSWORD_CHANGED,
  payload: {
    data: null,
    error: null,
  },
})

const _LOGIN_FAILED = (error) => ({
  type: LOGIN_FAILED,
  payload: {
    error,
    data: null,
  },
})

const _CLOSE_SESION_FAILED = (error) => ({
  type: CLOSE_SESION_FAILED,
  payload: {
    error,
    data: null,
  },
})

const _LOGOUT = () => ({
  type: LOGOUT,
  payload: { data: null, error: null },
})

const _ENABLE_USER = () => ({
  type: ENABLE_USER,
  payload: { data: null, error: null },
})

const _UPDATE_USER = (data) => ({
  type: UPDATE_USER,
  payload: { data, error: null },
})

const _INFO_BENEFITS_SUCCESS = (data) => ({
  type: INFO_BENEFITS_SUCCESS,
  payload: {
    data,
  },
})

const _INFO_BENEFITS_FAIL = (error, from = '') => ({
  type: INFO_BENEFITS_FAIL,
  payload: {
    error,
    from,
  },
})

export const loginAction = (authForm) => (dispatch) => {
  dispatch(_LOADING())
  apiLogin(authForm)
    .then((res) => {
      if (!res.data.success) {
        return dispatch(_LOGIN_FAILED(res.data.error.user_message))
      }
      dispatch(_SET_TOKEN(res.data.data, 'USER'))
    })
    .catch((error) => {
      if (error.response.status === 500) {
        return dispatch(_LOGIN_FAILED('Error interno'))
      }
      dispatch(_LOGIN_FAILED(error.response.data.error.user_message))
    })
}

export const loginSacAction = (authForm) => (dispatch) => {
  dispatch(_LOADING())
  apiLoginSac(authForm)
    .then((res) => {
      if (!res.data.success) {
        return dispatch(_LOGIN_FAILED(res.data.error.user_message))
      }
      dispatch(_SET_TOKEN(res.data.data, 'SAC'))
    })
    .catch((error) => {
      if (error.response.status === 500) {
        return dispatch(_LOGIN_FAILED('Error interno'))
      }
      dispatch(_LOGIN_FAILED(error.response.data.error.user_message))
    })
}

export const geInfoUserAction = () => (dispatch, state) => {
  dispatch(_LOADING())
  apiLogged()
    .then((res) => {
      if (!res.data.success) {
        return dispatch(_LOGIN_FAILED(res.data.error.user_message))
      }
      apiUserInfo()
        .then((res) => {
          if (!res.data.success) {
            return dispatch(_LOGIN_FAILED(res.data.error.user_message))
          }
          dispatch(setAuthData(res.data))
        })
        .catch((error) => {
          if (error.response.status === 500) {
            return dispatch(_LOGIN_FAILED('Error interno'))
          }
          dispatch(_LOGIN_FAILED(error.response.data.error.user_message))
        })
    })
    .catch((error) => {
      if (error.response.status === 500) {
        return dispatch(_LOGIN_FAILED('Error interno'))
      }
      if (error.response.status === 401) {
        return dispatch(logoutAction())
      }
      dispatch(_LOGIN_FAILED(error.response.data.error.user_message))
    })
}

export const setAuthData = (data) => (dispatch) => {
  dispatch(_LOGIN_SUCCESS(data))
}

export const logoutAction = () => (dispatch) => {
  dispatch(_LOGOUT())
  dispatch(removeRegisterData())
}
export const closeSesionAction = () => (dispatch) => {
  apiCloseSession()
    .then((res) => {
      if (res.data.success) {
        dispatch(_LOGOUT())
        dispatch(removeRegisterData())
      } else {
        dispatch(_CLOSE_SESION_FAILED('Error interno'))
      }
    })
    .catch((err) => {
      if (err.response.status != 401) {
        dispatch(_CLOSE_SESION_FAILED('Error interno'))
      }
    })
}
export const enableUserAction = () => (dispatch) => dispatch(_ENABLE_USER())

export const updatePasswordAction = (updateForm) => (dispatch) => {
  dispatch(_LOADING())
  apiUpdatePassword(updateForm)
    .then((res) => {
      if (!res.data.success) {
        return dispatch(_LOGIN_FAILED(res.data.error.user_message))
      }
      dispatch(_LOGIN_PASSWORD_CHANGED())
    })
    .catch((error) => {
      if (error.response.status === 500) {
        return dispatch(_LOGIN_FAILED('Error interno'))
      }
      dispatch(_LOGIN_FAILED(error.response.data.error.user_message))
    })
}

export const updateUserInfo = (data) => (dispatch) => dispatch(_UPDATE_USER(data))

export const getInfoBenefitsAction = () => (dispatch) => {
  apiBenefits()
    .then((res) => {
      if (!res.data.success) {
        return dispatch(_INFO_BENEFITS_FAIL(res.data.error.user_message))
      }
      dispatch(_INFO_BENEFITS_SUCCESS(res.data))
    })
    .catch((error) => {
      if (error.response.status === 500) {
        return dispatch(_INFO_BENEFITS_FAIL('Error interno'))
      }
      dispatch(_INFO_BENEFITS_FAIL(error.response.data.error.user_message))
    })
}
