import React, { useRef } from "react";
import {
  handleRegister
} from "./constans";

const ModalInfoValidate = ( props) => {
  const btnModal = useRef();
  const { userData } = props;
  function register( ) {
    window.$('#validate-info').modal('hide');
    handleRegister(userData)
  }
  if(!userData){
    return <></>
  }

  return (
    <>
      <div
        className="modal fade"
        id="validate-info"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true" >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header" style={{display: "block"}}>
              <h5 className="modal-title">
                Te suscribirás con los siguientes datos, por favor revisa que estén correctos.
              </h5>
              <span>Recuerda ingresar con este mismo correo electrónico a wong.pe o App Wong.</span>
            </div>
            <div className="modal-body" style={{padding: '40px'}}>
              <div className="row card" style={{padding: '20px 0px', borderColor: '#e9bb92'}}>
                <div className="col-lg-12">
                  <div className="col-lg-12">{userData.first_name} {userData.last_name} {userData.last_name_2}</div>
                  <div className="col-lg-12">{userData.dni}</div>
                  <div className="col-lg-12">{userData.email}</div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary-validate"
                ref={btnModal}
                data-dismiss="modal" >
                Editar
              </button>
              <button onClick={() => register()} className="btn btn-primary-validate">
                Continuar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalInfoValidate;
