import React from 'react'

const HeadersForms = ({ children }) => {
    return (
        <div className="form_inscrip" id="home">
            <a
            className="navbar-brand"
            href="/"
            rel="noopener noreferrer"
            >
                <img
                    src={require("../../../../assets/img/LOGOPRIME.png")}
                    alt="brand white"
                    className="logo-a logo-nav-subscription"
                />
            </a>
            <div className="container ">
                <div className="row py-5">
                    <div className="col-lg-6 mx-auto">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeadersForms
