//export const REGISTER_REQUEST = "register/REGISTER_REQUEST";
export const REGISTER_LOADING = 'register/REGISTER_LOADING'
export const REGISTER_END_LOADING = 'register/REGISTER_END_LOADING'
export const REGISTER_SUCCESS = 'register/REGISTER_SUCCESS'
export const REGISTER_FAIL = 'register/REGISTER_FAIL'
export const UPDATE_SUCCESS = 'register/UPDATE_SUCCESS'

export const USER_CARDS_LOADING = 'register/USER_CARDS_LOADING'
export const USER_CARDS_SUCCESS = 'register/USER_CARDS_SUCCESS'
export const USER_CARDS_FAIL = 'register/USER_CARDS_FAIL'
export const UPDATE_CARD_LOADING = 'register/UPDATE_CARD_LOADING'
export const UPDATE_CARD_SUCCESS = 'register/UPDATE_CARD_SUCCESS'
export const UPDATE_CARD_FAIL = 'register/UPDATE_CARD_FAIL'
export const CREATE_CARD_LOADING = 'register/CREATE_CARD_LOADING'
export const CREATE_CARD_SUCCESS = 'register/CREATE_CARD_SUCCESS'
export const CREATE_CARD_FAIL = 'register/CREATE_CARD_FAIL'
export const REMOVE_REGISTER_DATA = 'register/REMOVE_REGISTER_DATA'

export const USER_UNSUBSCRIBE_SUCCESS = 'register/USER_UNSUBSCRIBE_SUCCESS'
export const USER_UNSUBSCRIBE_FAILED = 'register/USER_UNSUBSCRIBE_FAILED'

export const RegisterReducers = (state = {}, action) => {
  switch (action.type) {
    case REGISTER_LOADING:
      return { ...state, isLoading: true, fromLoading: action.payload.from }
    case REMOVE_REGISTER_DATA:
      return {}
    case REGISTER_END_LOADING:
      return { ...state, isLoading: false }
    case REGISTER_SUCCESS:
      return {
        registering: true,
        errorMessage: '',
        data: action.payload.data,
        isLoading: false,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updated: true,
        errorMessage: '',
        isLoading: false,
      }
    case USER_UNSUBSCRIBE_SUCCESS:
      return {
        isUnsubscribed: true,
        errorMessage: '',
        data: action.payload.data,
        isLoading: false,
      }
    case REGISTER_FAIL:
      return {
        registering: false,
        errorMessage: action.payload.error,
        from: action.payload.from,
        isLoading: false,
      }
    case USER_UNSUBSCRIBE_FAILED:
      return {
        errorMessage: action.payload.error,
        from: action.payload.from,
        isLoading: false,
      }
    case USER_CARDS_LOADING:
      return { ...state, isLoading: true, fromLoading: action.payload.from }
    case USER_CARDS_SUCCESS:
      return {
        errorMessage: '',
        cards: action.payload.data.data.cards,
      }
    case USER_CARDS_FAIL:
      return {
        errorMessage: action.payload.error,
        from: action.payload.from,
        cards: [],
      }
    case UPDATE_CARD_LOADING:
      return { ...state, isLoading: true, fromLoading: action.payload.from }
    case UPDATE_CARD_SUCCESS:
      return {
        errorMessage: '',
        cards: action.payload.data.data.cards,
      }
    case UPDATE_CARD_FAIL:
      return {
        ...state,
        errorMessage: action.payload.error,
        from: action.payload.from,
      }
    case CREATE_CARD_LOADING:
      return { isLoading: true, cards: [], fromLoading: action.payload.from }
    case CREATE_CARD_SUCCESS:
      return {
        errorMessage: '',
        cards: action.payload.cards,
      }
    case CREATE_CARD_FAIL:
      return {
        ...state,
        errorMessage: action.payload.error,
        from: action.payload.from,
        cards: [],
      }
    default:
      return state
  }
}
