import React from 'react'
import { Link } from 'react-router-dom'
import { clickGTM } from '../../services/Gtm/gtmInteractions'
const Navbar = () => {
  const { handleClick } = clickGTM()
  return (
    <div id="header_area_home" className="header_area animated">
      
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-12 col-lg-10">
            <div className="menu_area">
              <div className="navbar navbar-expand-lg navbar-light">
                <Link className="navbar-brand" to="/" 
                  onClick={(e) => {
                  handleClick(e, 'Header')
                }}>
                  <img
                    src={require('../../assets/img/WongPrime1.1.png')}
                    className="logo-a"
                    alt="WongPrime.svg"
                  />
                  <img
                    src={require('../../assets/img/WongPrime2.2.png')}
                    className="logo-b"
                    alt=""
                  />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#ca-navbar"
                  aria-controls="ca-navbar"
                  aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="ca-navbar">
                  <ul className="navbar-nav ml-auto" id="nav">
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href={'#faq-section'}
                        onClick={(e) => {
                          handleClick(e, 'Header')
                        }}>
                        Preguntas frecuentes
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/auth/login"
                        onClick={(e) => {
                          handleClick(e, 'Header')
                        }}>
                        <i className="fa fa-user-circle"></i> Ingresar
                      </Link>
                    </li>
                  </ul>
                  <div
                    className="sing-up-button d-lg-none"
                    onClick={(e) => {
                      handleClick(e, 'Header')
                    }}>
                    <Link to="/auth/register">Suscríbete</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-2">
            <div className="sing-up-button d-none d-lg-block">
              <Link
                to="/auth/register"
                onClick={(e) => {
                  handleClick(e, 'Header')
                }}>
                Suscríbete
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
