import React from 'react'
import { Link } from 'react-router-dom'
import { clickGTM } from '../../services/Gtm/gtmInteractions'
import axios from 'axios'

const Header = () => {
  const { handleClick } = clickGTM()

  return (
    <div className="wellcome_area clearfix" id="home">
      <div className="container h-100">
        <div className="row h-100 align-items-center">
          <div className="col-12 col-md-7 col-lg-6 header-content">
            <div className="wellcome-heading">
              <h2>
                <span className="text-white text-uppercase">
                  ¡Empieza a vivir momentos Wong Prime!
                </span>
              </h2>
              <p>
                <strong className="text-white  d-block mb-3">
                  Tus compras de supermercado con{' '}
                  <span>
                    envíos gratis e ilimitados<sup>*</sup>, doble acumulación de puntos bonus,{' '}
                  </span>{' '}
                  descuentos en combustible en Primax y mucho más.
                </strong>
              </p>
              <p className="text-white">
                <strong className="d-block">
                  Prueba desde hoy{' '}
                  {process.env.REACT_APP_IS_NEW_CAMPAIGN === 'true' ? (
                    <>
                      <del>{process.env.REACT_APP_CURRENT_NUMBER_FREE_DAYS}</del>{' '}
                      {process.env.REACT_APP_FUTURE_NUMBER_FREE_DAYS}
                    </>
                  ) : (
                    `${process.env.REACT_APP_CURRENT_NUMBER_FREE_DAYS}`
                  )}{' '}
                  días ¡Sin costo!
                </strong>
              </p>
            </div>
            <div className="get-start-area">
              <Link
                className="submit"
                to="/auth/register"
                onClick={(e) => {
                  handleClick(e, 'Home - Body')
                }}>
                {' '}
                <i className="fa fa-bookmark"></i> Prueba{' '}
                {process.env.REACT_APP_IS_NEW_CAMPAIGN === 'true' ? (
                  <>
                    <del>{process.env.REACT_APP_CURRENT_NUMBER_FREE_DAYS}</del>{' '}
                    {process.env.REACT_APP_FUTURE_NUMBER_FREE_DAYS}
                  </>
                ) : (
                  `${process.env.REACT_APP_CURRENT_NUMBER_FREE_DAYS}`
                )}{' '}
                días gratis{' '}
              </Link>
              <p>
                <strong className="d-block">* Válido para compras desde S/ 79.90</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
