import { apiSecurityNiubiz } from '../../services/Register'
import {
  REGISTER_NIUBIZ_LOADING,
  REGISTER_NIUBIZ_FAIL,
} from '../reducers/RegisterPayNiubizReducers'
import { REGISTER_LOADING, REGISTER_END_LOADING } from '../reducers/RegisterReducers'
import { getPurchaseNumber } from '../../utils/niubiz'

export const _REGISTER_START_LOADING = (from = 'register') => ({
  type: REGISTER_LOADING,
  payload: { from },
})

export const _REGISTER_END_LOADING = () => ({
  type: REGISTER_END_LOADING,
  payload: {},
})

const _REGISTER_NIUBIZ_LOADING = () => ({
  type: REGISTER_NIUBIZ_LOADING,
  payload: { data: null, error: null },
})

const _REGISTER_NIUBIZ_FAIL = (error) => ({
  type: REGISTER_NIUBIZ_FAIL,
  payload: {
    error,
    data: null,
  },
})

const handleCheckout = (token, info, isProfile) => {
  window.VisanetCheckout.configure({
    action: isProfile
      ? `${process.env.REACT_APP_API_URL}/api/v1/niubiz/validate/token/redirect/profile`
      : `${process.env.REACT_APP_API_URL}/api/v1/niubiz/validate/token/redirect/success`,
    sessiontoken: token,
    channel: 'paycard',
    merchantid: `${process.env.REACT_APP_MERCHANT_ID}`,
    purchasenumber: getPurchaseNumber(),
    amount: `${process.env.REACT_APP_AMOUNT}`,
    cardholdername: isProfile ? info.first_name : info.name,
    cardholderlastname: info.last_name,
    cardholderemail: info.email,
    usertoken: '',
    expirationminutes: '20',
    timeouturl: 'https://www.micomercio.com/timeout.html',
    merchantlogo: 'https://wongprime.pe/static/media/WongPrime1.1.62210c7f42354628050a.png',
    formbuttoncolor: '#D80000',
    formbuttontext: 'Registrar',
    showamount: 'FALSE',
    complete: function (params) {
      alert(JSON.stringify(params))
    },
  })
  window.VisanetCheckout.open()
}

export const securityPayNiubizAction =
  (info, isProfile, registerForm, mail, modal, navigate) => (dispatch) => {
    dispatch(_REGISTER_NIUBIZ_LOADING())
    dispatch(_REGISTER_START_LOADING())
    console.log('Action Pay Niubiz')

    apiSecurityNiubiz()
      .then((res) => {
        if (!res.data.success) {
          return dispatch(_REGISTER_NIUBIZ_FAIL(res.data.error.user_message))
        }
        handleCheckout(res.data.data.sessionToken.sessionKey, info, isProfile)
        //return dispatch(_REGISTER_NIUBIZ_SUCCESS(res.data));
        dispatch(_REGISTER_END_LOADING())
      })
      .catch((e) => {
        console.log('Error')
        console.log(e)
        dispatch(_REGISTER_NIUBIZ_FAIL(e))
        dispatch(_REGISTER_END_LOADING())
      })
  }
