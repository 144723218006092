import { React, useState, useEffect } from 'react'
import { decryptedData } from '../../utils/secret'

const ConfirmError = () => {
  const [detailError, setErrorDetail] = useState('')
  const params = new URLSearchParams(document.location.search)
  const dataError = decodeURIComponent(params.get('detail'))

  const getDetailError = () => {
    const errorDetail = decryptedData(dataError, false)
    setErrorDetail(errorDetail)
  }

  useEffect(() => {
    if (dataError) {
      getDetailError()
    }
  })

  return (
    <div className="home">
      <header className="header_area animated">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="menu_area">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <a className="navbar-brand" href="/" rel="noopener noreferrer">
                    <img
                      src={require('../../assets/img/LOGOPRIME.png')}
                      className="logo-a logo-nav-subscription"
                      alt="brand white"
                    />
                  </a>
                  <ul className="navbar-nav ml-auto" id="nav">
                    <li className="nav-item">
                      <a className="nav-link" rel="noopener noreferrer" href="/">
                        <i className="fa fa-sign-out-alt"></i> Cerrar sesíon
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="form_inscrip" id="home">
        <div className="container">
          <div className="row py-5">
            <div className="col-md-10 col-lg-6 mx-auto">
              <div className="card shadow">
                <div className="card-body px-5 pb-5">
                  <div className="text-center">
                    <img
                      src={require('../../assets/img/bg-error-wong.jpg')}
                      style={{ width: '75%', marginTop: '-70px' }}
                      alt="bg error"
                    />

                    <h2 className="mt-3">Algo salió mal.</h2>
                    <p className="text-muted">
                      {detailError
                        ? detailError
                        : 'No pudimos registrar tu tarjeta, por favor háznoslo saber.'}
                    </p>
                  </div>

                  <div className="text-center mt-5">
                    <a href="/" className="btn btn-outline-prime btn-extended ">
                      Intentarlo nuevamente
                    </a>

                    <p className="mt-3 ">
                      ¿Tuviste problemas con tu tarjeta Scotiabank Cencosud? <br />
                      <a
                        href="/"
                        rel="noopener noreferrer"
                        data-toggle="modal"
                        data-target="#modalcat">
                        Haz clic aquí
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="page-footer font-small pt-4 ">
        <img
          src={require('../../assets/img/WongPrime1.1.png')}
          width="150px"
          style={{ margin: '0 auto', display: 'block' }}
          alt="brand color"
        />
        <div className="footer-copyright text-center text-muted py-3">
          © Cencosud 2022. Todos los derechos reservados.
        </div>
      </footer>
    </div>
  )
}

export default ConfirmError
