import React from 'react'

const DatosCobros = ({ data: info }) => {
  const { data } = info
  const { payments, user } = data.user_detail
  return (
    <>
      <div className="col-lg-12">
        <div className="card mb-4 shadow-sm">
          <div className="card-header py-3">
            <p className="float-left display-5 mb-0">
              <i className="fa fa-credit-card"></i> Datos Cobros
            </p>
          </div>
          <div className="card-body media p-3">
            <img src="../img/visa.svg" width="45px" className="align-self-center mr-3" alt="" />
            {payments.length > 0 && (
              <div className="media-body">
                <span className="d-block">Tarjeta asociada</span>
                <span className="d-block text-muted f14">
                  Terminada en **** **** <strong>{user.cardLastDigits}</strong>
                </span>
              </div>
            )}
          </div>
          {payments.length > 0 ? (
            <table className="table table-payment align-items-center table-flush">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Orden</th>
                  <th scope="col">Fecha</th>
                  <th scope="col">Estado</th>
                  <th scope="col text-right">Monto</th>
                  <th scope="col text-right">Observación</th>
                </tr>
              </thead>
              <tbody>
                {payments.map((payment, index) => (
                  <tr key={index}>
                    <td>
                      <ul className="list-unstyled">
                        <li>{payment.transactionbuyorder}</li>
                      </ul>
                    </td>
                    <td>{payment.paymentdate}</td>
                    <td>{payment.status}</td>
                    <td>{payment.amount}</td>
                    <td className={`${payment.classLevel}`}>{payment?.observation}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="col-6 mt-5">
              <div
                className="alert alert-danger text-center"
                role="alert"
                style={{ with: '70%', fontSize: '18px' }}>
                No hay datos de cobros para este usuario
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default DatosCobros
