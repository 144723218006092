import { apiSac } from '../../services/Sac'
import { USER_INFO_LOAD, USER_INFO_SUCCESS, USER_INFO_FAILED } from '../reducers/SacReducers'

const _USER_INFO_LOAD = () => ({
  type: USER_INFO_LOAD,
  payload: { data: null, error: null },
})

const _USER_INFO_SUCCESS = (data) => ({
  type: USER_INFO_SUCCESS,
  payload: data,
})

const _USER_INFO_FAILED = (error) => ({
  type: USER_INFO_FAILED,
  payload: {
    error,
    data: null,
  },
})

export const sacAction = (dni) => (dispatch) => {
  dispatch(_USER_INFO_LOAD())
  apiSac(dni)
    .then((res) => {
      dispatch(_USER_INFO_SUCCESS(res.data))
    })
    .catch((error) => dispatch(_USER_INFO_FAILED(error)))
}
