export const REGISTER_PAYU_LOADING = "register/REGISTER_PAYU_LOADING";
export const REGISTER_PAYU_SUCCESS = "register/REGISTER_PAYU_SUCCESS";
export const REGISTER_PAYU_WARNING = "register/REGISTER_PAYU_WARNING";
export const REGISTER_PAYU_BLOCKED = "register/REGISTER_PAYU_BLOCKED";
export const REGISTER_PAYU_UNBLOCK = "register/REGISTER_PAYU_UNBLOCK";
export const REGISTER_PAYU_FAIL = "register/REGISTER_PAYU_FAIL";
export const REGISTER_PAYU_ENABLEU_USER = "register/REGISTER_PAYU_ENABLEU_USER";

const RegisterPayUReducers = (state = {}, action) => {
  switch (action.type) {
    case REGISTER_PAYU_LOADING:
      return {
        isLoading: true
      };
    case REGISTER_PAYU_UNBLOCK:
      return {
        blocked: false
      };
    case REGISTER_PAYU_SUCCESS:
      return { 
        isLoading: false,
        blocked: false,
        errorMessage: null,
        payData: action.payload.data
      };
    case REGISTER_PAYU_ENABLEU_USER:
      return { 
        isLoading: false,
        isEnableUser: true,
      };
    case REGISTER_PAYU_FAIL:
      return {
        ...state,
        isLoading: false,
        blocked: false,
        errorMessage: action.payload.error,
      };
    case REGISTER_PAYU_WARNING:
      return {
        ...state,
        warningMessage: action.payload.data,
      };
    case REGISTER_PAYU_BLOCKED:
      return {
        blocked: true,
        blockedMessage: action.payload.data
      };
    default:
      return state;
  }
};

export default RegisterPayUReducers;
