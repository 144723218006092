import React from 'react'
import { Link } from 'react-router-dom'
import { Formik, Field, Form } from 'formik'
import { handleLogin, handleValidation, initialValues } from './constants'
import useLogin from '../../../redux/hooks/useLogin'
import Loading from '../../../components/Commons/Loading/Loading'
import { getYear } from '../../../utils/dateForm'
import { clickGTM } from '../../../services/Gtm/gtmInteractions'
import { useErrorsGTM } from '../../../services/Gtm/gtmInteractions'
const Login = () => {
  const { isLoading, errorMessage } = useLogin()
  useErrorsGTM(errorMessage)
  const { handleClick } = clickGTM()
  return (
    <div className="home">
      <div className="container-fluid">
        <div className="row no-gutter">
          <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image">
            <div className="col-md-12 mt-3">
              <h1
                style={{
                  color: 'white',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}>
                BIENVENIDO A WONG PRIME
              </h1>
            </div>
          </div>
          <div className="col-md-8 col-lg-6 bg-white">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-8 mx-auto">
                    <img
                      src={require('../../../assets/img/WongPrime1.1.png')}
                      alt="jumprime"
                      width="150px"
                      style={{ margin: '0 auto', display: 'block' }}
                    />
                    <Formik
                      className="mt-4"
                      initialValues={initialValues}
                      validationSchema={handleValidation}
                      onSubmit={handleLogin}>
                      {({ errors, touched, isValid, dirty }) => (
                        <Form>
                          <div className="form-label-group mb-2">
                            <label htmlFor="inputEmail">Usuario</label>
                            <Field
                              placeholder="Usuario"
                              id="inputEmail"
                              name="email"
                              type="text"
                              className="form-control"
                              autoFocus
                            />
                            {errors.email && touched.email ? (
                              <div className="text-danger">{errors.email}</div>
                            ) : null}
                          </div>

                          <div className="form-label-group">
                            <label htmlFor="inputPassword">Contraseña</label>
                            <Field
                              placeholder="Contraseña"
                              id="inputPassword"
                              name="password"
                              type="password"
                              className="form-control"
                            />
                            {errors.password && touched.password ? (
                              <div className="text-danger">{errors.password}</div>
                            ) : null}
                            <Link
                              to="/auth/reset"
                              className="btn btn-sm btn-link"
                              onClick={(e) => {
                                handleClick(e, 'Login')
                              }}>
                              Olvide mi contraseña
                            </Link>
                          </div>
                          {errorMessage && (
                            <div
                              className="alert alert-danger alert-dismissible fade show mt-4"
                              role="alert">
                              <strong>
                                <i className="fa fa-exclamation-circle"></i> Lo sentimos
                              </strong>{' '}
                              <br />
                              {errorMessage}
                              <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                          )}

                          <button
                            disabled={isLoading || !isValid}
                            onClick={(e) => {
                              handleClick(e, 'Login')
                            }}
                            className="btn btn-lg btn-primary btn-block btn-login mb-2 mt-5"
                            type="submit">
                            Ingresar
                          </button>
                          <div className="row mt-3">
                            <div className="col-md-6">
                              <Link
                                to="/"
                                className="btn btn-sm btn-link btn-block"
                                onClick={(e) => {
                                  handleClick(e, 'Login')
                                }}>
                                {' '}
                                <i className="fa fa-arrow-circle-left"></i> Volver al Home
                              </Link>
                            </div>
                            <div className="col-md-6">
                              <Link
                                to="/auth/register"
                                className="btn btn-sm btn-link btn-block"
                                onClick={(e) => {
                                  handleClick(e, 'Login')
                                }}>
                                Crear una cuenta
                              </Link>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
                <footer className="page-footer font-small pt-5 ">
                  <div className="footer-copyright text-center text-muted py-3">
                    © Cencosud {getYear()}. Todos los derechos reservados.
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loading visible={isLoading} />
    </div>
  )
}

export default Login
