import React from 'react'

export const CardShoppingEmpty = () => {
    return (
        <>
            <div className="card card-compra">
                <div className="card-body text-center">
                    <p className="lead"> <i className="ni ni-bag-17 display-2 d-block text-primary"></i>Aún no tiene compras registradas.</p>
                </div>
            </div>
        </>
    )
}
