import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const clickGTM = () => {
  const handleClick = (e, category, idx = 0) => {
    let btnTitle
    // if (e.target.innerText === 'www.tiendasjumbo.co') {
    //   btnTitle = 'tiendasjumbo.co ' + idx
    // } else
    if (e.target.innerText === 'Inicia ahora') {
      btnTitle = 'Prueba gratis - inicia ahora'
    } else if (e.target.innerText === '') {
      btnTitle = 'Logo'
    } else {
      btnTitle = e.target.innerText
    }

    //window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'interaction',
      category: category,
      action: 'click',
      tag: btnTitle,
    })
  }

  return { handleClick }
}

export const clickFooterGTM = () => {
  const handleClick = (e, category) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'interaction',
      category: 'Menu - Footer',
      action: category,
      tag: e.target.innerText,
    })
  }
  return { handleClick }
}

//Hook para registrar páginas visitadas
export const usePageViewsGTM = () => {
  const selectedLocation = useLocation()
  useEffect(() => {
    let pageTitle
    switch (selectedLocation.pathname) {
      case '/':
        pageTitle = 'Home - Suscripción Prime'
        break
      case '/auth/register':
        pageTitle = 'Suscripción - paso 1'
        break
      case '/auth/register-save':
        pageTitle = 'Suscripción - paso 2'
        break
      // case '/auth/register-payu':
      //   pageTitle = 'Suscripción - paso 3'
      //   break
      case '/auth/register-success/':
        pageTitle = 'Suscripción - paso 3'
        break
      case '/auth/login':
        pageTitle = 'Login'
        break
      case '/faq/':
        pageTitle = 'Preguntas Frecuentes'
        break
      case '/auth/recover':
        pageTitle = 'Recupera tu contraseña'
        break
      case '/auth/reset':
        pageTitle = '¿Olvido su contraseña?'
        break
      case '/terminos-y-condiciones/':
        pageTitle = 'Institucional - Terminos y condiciones'
        break
      case '/politicas-y-privacidad/':
        pageTitle = 'Institucional - Politicas y privacidad'
        break
      default:
        pageTitle = document.title
    }
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'PageviewVirtual',
      page: selectedLocation.pathname,
      title: pageTitle,
      location: window.location.href,
    })
  }, [selectedLocation.pathname])
}

//Hook para verificar si un componente está en el viewport
export function useOnScreen(ref) {
  const [pageLoad, setPageLoad] = useState(true)
  const [isIntersecting, setIntersecting] = useState(false)
  const location = useLocation()
  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))
  setTimeout(() => {
    setPageLoad(false)
  }, 200)
  useEffect(() => {
    observer.observe(ref.current)
    if (isIntersecting && pageLoad) {
      let titleFooter
      location.pathname.includes('/auth/')
        ? (titleFooter = 'Footer - Registro')
        : (titleFooter = 'Footer - Home')
      window.dataLayer.push({
        event: 'PageviewVirtual',
        page: window.location.pathname,
        title: titleFooter,
        location: window.location.href,
      })
    }

    return () => {
      observer.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting])

  return isIntersecting
}

export const useErrorsGTM = (errorMessage) => {
  const selectedLocation = useLocation()
  let error
  errorMessage?.user_message ? (error = errorMessage?.user_message) : (error = errorMessage)

  useEffect(() => {
    let pageTitle
    switch (selectedLocation.pathname) {
      case '/':
        pageTitle = 'Home - Suscripción Prime'
        break
      case '/auth/register':
        pageTitle = 'Suscripción - paso 1'
        break
      case '/auth/register-save':
        pageTitle = 'Suscripción - paso 2'
        break
      case '/auth/register-payu':
        pageTitle = 'Suscripción - paso 3'
        break
      case '/auth/register-success':
        pageTitle = 'Suscripción - paso 4'
        break
      case '/auth/login':
        pageTitle = 'Login'
        break
      case '/faq/':
        pageTitle = 'Preguntas Frecuentes'
        break
      case '/auth/recover':
        pageTitle = 'Recupera tu contraseña'
        break
      case '/auth/reset':
        pageTitle = '¿Olvido su contraseña?'
        break
      case '/terminos-y-condiciones/':
        pageTitle = 'Institucional - Terminos y condiciones'
        break
      case '/politicas-y-privacidad/':
        pageTitle = 'Institucional - Politicas y privacidad'
        break
      default:
        pageTitle = document.title
    }
    if (error) {
      window.dataLayer.push({
        event: 'interaction',
        category: 'error',
        action: pageTitle,
        tag: error,
      })
    }
  }, [error])
}
