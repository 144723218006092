import React from "react";

const Header = () => {
  return (
    <>
      <section className="page_area clearfix" id="faq">
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-content-md-center">
            <div className="col-12 col-md-6">
              <div className="faq-heading">
                <h2>
                  <span className="text-uppercase">faq</span> <br />
                  Respusta rápidas a preguntas frecuentes
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
