import * as Yup from 'yup'
import { registerAction } from '../../../redux/actions/RegisterActions'
import { AppDispatch } from '../../../redux/utils/AppDispatch'

export const handleSequeiryValidation = () => {
  return Yup.object().shape({
    password: Yup.string()
      .required('La contraseña es requerida')
      .min(8, 'La contraseña debe tener mas de 8 caracteres')
      .max(12, 'La contraseña debe tener menos de 12 caracteres')
      .matches(
        /^.*(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        'Contraseña debe tener al menos una letra mayuscula, minuscula y un número'
      )
      .oneOf([Yup.ref('password_confirmation'), null], 'Las contraseñas no son iguales'),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Las contraseñas no son iguales'
    ),
    accepts: Yup.string().required('Debe aceptar terminos y condiciones'),
    isProd: Yup.boolean(),
    reCaptcha: Yup.string().when('isProd', {
      is: true,
      then: Yup.string().required('El reCaptcha es requerido').nullable(),
    }),
    /*accepts_policies: Yup.string().required(
      "Debe aceptar terminos y condiciones"
    ),*/
  })
}

export const handleAddressValidation = () => {
  return Yup.object().shape({
    address: Yup.string().required('La dirección es requerida'),
    phone: Yup.string()
      .required('El teléfono es requerido')
      .max(9, `El teléfono no es correcto`)
      .min(9, `El teléfono no es correcto`),
    email: Yup.string()
      .matches(/^[a-zA-Z][\w.-]{0,98}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Formato de correo inválido')
      .required('El email es requerido')
      .oneOf([Yup.ref('email_confirmation'), null], 'Los correos no son iguales'),
    email_confirmation: Yup.string()
      .matches(/^[a-zA-Z][\w.-]{0,98}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Formato de correo inválido')
      .required('La confirmación del email es requerida')
      .oneOf([Yup.ref('email'), null], 'Los correos no son iguales'),
  })
}

export const handlePerfonalInfoValidation = () => {
  return Yup.object().shape({
    dni_type: Yup.string().required('El tipo de documento es requerido'),
    dni: Yup.string()
      .required('El número de identificación es requerido')
      .when('dni_type', {
        is: '1',
        then: Yup.string()
          .max(8, 'El DNI debe ser de 8 digitos')
          .min(8, 'El DNI debe ser de 8 digitos')
          .matches(/^.[0-9,$].*$/, 'El DNI es sólo número'),
      })
      .when('dni_type', {
        is: '4',
        then: Yup.string().max(12, 'El Carnet de Extranjería debe ser máximo 12 digitos'),
        //.min(8, "El Carnet de Extranjería debe ser minimo de 8 digitos"),
      })
      .when('dni_type', {
        is: '5',
        then: Yup.string().max(12, 'El Pasaporte debe ser máximo 12 digitos'),
        //.min(8, "El Pasaporte debe ser minimo 8 digitos"),
      })
      .when('dni_type', {
        is: '6',
        then: Yup.string()
          .max(11, 'El RUC debe ser de 11 digitos')
          .min(11, 'El RUC debe ser de 11 digitos')
          .matches(/^.[0-9,$].*$/, 'El RUC debe ser sólo número'),
      }),
    first_name: Yup.string()
      .matches(/^[a-zA-Z]{1,100}$/, 'No se admiten caracteres especiales')
      .required('El nombre es requerido'),
    last_name: Yup.string()
      .matches(/^[a-zA-Z]{1,100}$/, 'No se admiten caracteres especiales')
      .required('El primer apellido es requerido'),
    last_name_2: Yup.string().matches(/^[a-zA-Z]{1,100}$/, 'No se admiten caracteres especiales'),
  })
}

export const handleRegister = (formValue) => {
  const newDate = new Date(formValue.bday.year, formValue.bday.month, formValue.bday.day)
  AppDispatch(registerAction({ ...formValue, bday: newDate, coupon_profile: false }))
}

export const handleCouponValidation = () => {
  return Yup.object().shape({
    coupon: Yup.string().required('El cupón es requerido'),
  })
}

export const initialCouponValues = {
  coupon: '',
  is_login: true
}
export const initialSecurityValues = {
  accepts: '',
  accepts_policies: false,
  password: '',
  password_confirmation: '',
  isProd: process.env.NODE_ENV === 'production',
  reCaptcha: '',
  coupon_code: '',
}
export const initialAddressValues = {
  email: '',
  email_confirmation: '',
  phone: '',
  address: '',
  city: '11001',
  department: '15',
  district: '',
  country: 'Peru',
  isProd: process.env.NODE_ENV === 'production',
}
export const initialPersonalValues = {
  first_name: '',
  last_name: '',
  last_name_2: '',
  dni_type: '1',
  dni: '',
  coupon_code: '',
  bday: {
    day: '01',
    month: '01',
    year: '2000',
  },
  gender: '1',
  isProd: process.env.NODE_ENV === 'production',
}
