import React from 'react';
import Lottie from 'lottie-react';
import animationData from '../../../assets/lotties/confetti';

const Confetti = () => {
    return (
        <div style={{ position: 'fixed', width: '100%', height: '100%', top: 0, left: 0,  zIndex: 9999, display: 'flex', justifyContent: 'center' }}>
            <Lottie animationData={animationData} loop={false} style={{width: "60%"}} />
        </div>
    )
}

export default Confetti