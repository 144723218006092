import React from "react";
import Navbar from "../../components/Commons/Navbar";
import Footer from "../../components/Layouts/Footer";
const Policies = () => {
  return (
    <div className="home">
      <Navbar />
      <div className="page_area clearfix" id="politicas">
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-content-md-center">
            <div className="col-12 col-md-6">
              <div className="page-heading text-uppercase">
                <h2>
                  POLÍTICAS DE PRIVACIDAD Y PROTECCIÓN DE DATOS WONG PRIME
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="container py-5">
          <div className="row justify-content-md-center">
            <div className="col-12 col-lg-10 text-muted">
              <h3 className="f18">1.- INTRODUCCIÓN</h3>
              <p className="f14" align="justify">
              En CENCOSUD RETAIL PERÚ S.A. (en adelante “Wong Prime”), identificado con RUC No. 20109072177, y domicilio en 
              calle Augusto Angulo N° 130, Urb. San Antonio, distrito de Miraflores, provincia y departamento de Lima, 
              aseguramos la máxima reserva y protección sobre aquellos datos personales de los clientes/usuarios que sean 
              ingresados en el Programa de Beneficios Wong Prime (en adelante, Wong Prime o el Programa) y sus distintas plataformas
              de uso.
              </p>
              <p className="f14" align="justify">
              En este documento describimos la “Política de Privacidad” (en adelante, también denominado “Política”) que 
              regula el tratamiento de los datos personales que los clientes/usuarios registran en el Programa.
              </p>
              <h3 className="f21">
                2. OBJETIVO.
              </h3>
              <p className="f14" align="justify">
              La presente Política tiene como objetivo cumplir con el deber de informar a los clientes/usuarios acerca del 
              tratamiento de los datos personales que compartirán a Wong Prime, en cumplimiento de la normativa de protección de 
              datos personales. 
              </p>
              <p className="f14" align="justify">
              En Wong Prime estamos especialmente interesados en ofrecer a nuestros clientes/usuarios el más alto nivel 
              de seguridad y proteger la confidencialidad de los datos que nos aporta. Por ello, las relaciones comerciales 
              se realizan en un entorno de servidor seguro bajo protocolo SSL (Secure Socket Layer), actualmente empleado por 
              las empresas más importantes del mundo para realizar transacciones electrónicas seguras.
              </p>
              <p className="f14" align="justify">
              Nuestro Programa ha sido creado y diseñado para facilitar todo tipo de información que creemos puede 
              ser de interés y crear así un espacio de comunicación para los clientes/usuarios.
              </p>

              <h3 className="f18">3. DESTINATARIOS DE LOS DATOS PERSONALES.</h3>

              <p className="f14" align="justify">
              El destinatario de la información personal de los usuarios/clientes que se recopile mediante los formularios y/o 
              documentos análogos es Wong Prime. Asimismo, pueden existir terceros destinatarios para realizar funciones 
              en nombre y representación de Wong Prime, los cuales tienen acceso a la información personal necesaria 
              para realizar sus funciones, pero no pueden usarla para fines diferentes a los señalados en la presente Política.
              </p>

              <p className="f14" align="justify">
              En este sentido, usted podrá acceder a la lista completa de los terceros proveedores de servicios mediante el siguiente enlace: <a href="https://www.wong.pe/Legales/privacidad-datos-proveedores" target="_blank">aquí</a>
              Es necesario precisar que los mencionados terceros tratarán la información personal de acuerdo con la presente Política y 
              según lo permitido por la legislación de Protección de Datos Personales.
              </p>
              
              <h3 className="f18">4. LEGISLACIÓN</h3>
              <p className="f14" align="justify">
              Esta política está regulada por la legislación peruana y en particular por:
              </p>
              <ul>
                <li>Ley N° 29733 – Ley de Protección de Datos Personales (en adelante, denominado <b>“LPDP”</b> ).</li>
                <li>Reglamento de la Ley N° 29733, aprobado por el Decreto Supremo N° 003-2013-JUS (en adelante, denominado <b>“RLPDP”</b> ).</li>
                <li>Directiva de Seguridad de la Información, aprobada por la Resolución Directoral N° 019-2013-JUS/DGPDP (en adelante, denominado <b>“Directiva”</b> ).</li>
              </ul>
              <p className="f14" align="justify">
              De acuerdo con la LPDP y el RLPDP, se entiende por datos personales toda información numérica, alfabética, 
              gráfica, fotográfica, acústica, sobre hábitos personales, o de cualquier otro tipo concerniente a una persona 
              natural que la identifica o la hace identificable a través de medios que pueden ser razonablemente utilizados.
              </p>
              <p className="f14" align="justify">
              Asimismo, se entiende por tratamiento de datos personales a cualquier operación o procedimiento técnico, automatizado o no, 
              que permite la recopilación, registro, organización, almacenamiento, conservación, elaboración, modificación, extracción, 
              consulta, utilización, bloqueo, supresión, comunicación por transferencia o por difusión o cualquier otra forma de 
              procesamiento que facilite el acceso, correlación o interconexión de los datos personales.
              </p>
              <h3 className="f18">5. CALIDAD DE LOS DATOS PERSONALES.</h3>
              <p className="f14" align="justify">
              Los datos personales solicitados a nuestros clientes/usuarios, son datos básicos de contacto y son adecuados, pertinentes y no excesivos en relación con la finalidad para los que se recogen.
              </p>
              <p className="f14" align="justify">
              Los datos personales a los que en Wong Prime tendremos acceso, serán aquellos que los clientes/usuarios faciliten voluntariamente rellenando los formularios puestos a tal efecto.
              </p>
              <p className="f14" align="justify">
              En Wong Prime les proporcionamos a nuestros clientes/usuarios los recursos técnicos adecuados para que tomen conocimiento de la presente Política y de cualquier otra información que pueda ser relevante.
              </p>
              <p className="f14" align="justify">
              Los datos personales facilitados por nuestros clientes/usuarios tienen que ser exactos y correctos de forma que respondan con veracidad a su situación actual. En caso contrario estos datos serán cancelados.
              </p>
              <p className="f14" align="justify">
              Los datos personales serán cancelados cuando hayan dejado de ser necesarios para las finalidades para las cuales han sido recopilados. Sin embargo, se conservarán durante el tiempo en que en Wong Prime nos puedan exigir algún tipo de responsabilidad derivada de esta relación con nuestros clientes/usuarios.
              </p>

              <h3 className="f18">6. TIEMPO DE CONSERVACIÓN DE SUS DATOS PERSONALES.</h3>
              <p className="f14" align="justify">
              Los datos personales del cliente/usuario serán conservados en los términos establecidos en la LPDP y el RLPDP, por el tiempo que sea necesario para cumplir las finalidades identificadas en el punto número 8 de la presente Política.
              </p>
              <p className="f14" align="justify">
              Wong Prime, en condición de Titular del banco de datos, se abstendrá de conservar los datos personales del Consumidor/titular en los siguientes eventos:
              </p>
              <ul>
                <li>Cuando tenga conocimiento de su carácter inexacto o incompleto, sin perjuicio de los derechos del cliente/usuario al respecto</li>
                <li>Cuando los datos personales objeto de tratamiento hayan dejado de ser necesarios o pertinentes para el cumplimiento de las finalidades previstas en la presente Política, salvo medie procedimiento de anonimización o disociación.</li>
              </ul>
              <p className="f14" align="justify">
              El titular de los datos personales podrá solicitar la supresión de sus datos personales conforme el procedimiento establecido en el punto número 13 de la Política. La supresión no procede cuando los datos personales deban ser conservados en virtud de un mandato legal o en virtud de las relaciones contractuales entre Wong Prime, en condición de responsable del tratamiento y el Titular de los datos personales, que justifiquen el tratamiento de estos, con fundamento en el Artículo 69 del RLPDP.
              </p>
              <h3 className="f18">7.  INFORMACIÓN QUE RECOLECTAMOS.</h3>
              <p className="f14" align="justify">
              Wong Prime, como responsable del tratamiento, a través de los formularios subidos en la página web: www.wongprime.com.pe (en adelante, la página web) y en documentos análogos, así como a través de proveedores de servicios, redes sociales, registros públicos y fuentes accesibles al público, puede recopilar los siguientes datos:
              </p>
              <ul>
                <li>Nombres</li>
                <li>Dirección</li>
                <li>Teléfono</li>
                <li>Correo electrónico</li>
                <li>Número de documento de identidad</li>
              </ul>
              <p className="f14" align="justify">
              Cabe precisar que para efectuar el registro en Wong Prime los mencionados datos deberán ser compartidos por el cliente/usuario de manera obligatoria. 
              </p>
              <p className="f14" align="justify">
              Adicionalmente, en el futuro y para actividades comerciales específicas Wong Prime podrá recopilar otros datos personales a fin de darles tratamiento en el marco de dichas campañas. En estos casos, Wong Prime informará oportunamente al cliente -en momento previo a la obtención del consentimiento- sobre la finalidad y tratamientos que brindará a dicha información y, adicionalmente, actualizará esta sección de la presente Política de Privacidad describiendo dichos datos adicionales a fin de que sean conocidos por nuestros clientes.
              </p>
              <h3 className="f18">8.  FINALIDAD DE LOS TRATAMIENTOS DE LA INFORMACIÓN PERSONAL.</h3>
              <p className="f14" align="justify">
              Las finalidades de tratamiento de los datos personales que nuestros clientes/usuario introducen en los diferentes formularios se orientan a gestionar los beneficios ofrecidos por el Programa por las transacciones que el cliente realice en el sitio www.wong.pe y el App Wong, atención de sus consultas, peticiones y la atención de cualquier otro tipo de información que nos requieran a través de éstos.
              </p>
              <p className="f14" align="justify">
              De la misma manera, sus datos personales serán tratados para la ejecución del despacho a costo S/ 0, el envío de ofertas exclusivas para clientes Wong Prime, la duplicación de puntos Bonus, y demás beneficios que otorgue el programa a sus clientes, de conformidad con los Términos y Condiciones.
              </p>
              <p className="f14" align="justify">
              Informamos que el cliente podrá autorizar el tratamiento para “Finalidades Adicionales” o secundarias mediante el marcado voluntario de las casillas correspondientes incorporadas en el sitio Web procederemos a tratar dichos datos para:
              </p>
              <ul>
                <li>Envío de publicidad: Enviar al cliente información comercial, promocional y/o publicitaria con relación a los diversos productos y/o servicios brindados por las unidades de negocio operadas por Cencosud Retail Perú S.A. en el territorio nacional, es decir, Wong, Metro y Spid, mediante el envío de correos electrónicos, llamadas al teléfono fijo y/o teléfono celular, envío de sms; </li>
                <li>Elaboración de perfiles: Registrar y analizar historiales de compra, elaborar perfiles de compra, ejecutar acciones de prospección comercial, almacenar y tratar la información con fines estadísticos o históricos; </li>
                <li>Recibir beneficios: Brindar beneficios (personalizados o generales), obsequios e invitaciones a diversos eventos organizados por Wong los que serán asignados conforme a los criterios que oportunamente definirá Cencosud Retail Perú S.A. </li>
              </ul>
              <p className="f14" align="justify">
              Asimismo, informamos que en caso el cliente, no acepten el (los) tratamiento (s) adicional (es) de sus datos personales, esto no afectará la prestación del servicio contratado. 
              </p>
              <p className="f14" align="justify">Los datos personales facilitados por nuestros clientes/usuarios se almacenarán en los bancos de datos que forman parte del sistema de información de Wong Prime y serán tratados para poder llevar a cabo las finalidades expuestas anteriormente.</p>
              <p className="f14" align="justify">Los bancos de datos que están registrados actualmente ante la Autoridad de Protección de Datos Personales son los siguientes:</p>
              <ul>
                <li>CLIENTES con código RNPDP-PJP N° 1680.</li>
                <li>COLABORADORES con código RNPDP-PJP N° 1681.</li>
                <li>CONTRO DE ACCESOS con código RNPDP-PJP N° 1682.</li>
                <li>PROVEEDORES con código RNPDP-PJP N° 1683.</li>
                <li>RECLUTAMIENTO DE COLABORADORES con código RNPDP-PJP N° 1684.</li>
                <li>SEGURIDAD Y SALUD EN EL TRABAJO con código RNPDP-PJP N° 1685. VIDEOVIGILANCIA con código RNPDP-PJP N° 1686.</li>
              </ul>
              <p className="f14" align="justify">Cabe destacar que este apartado se actualizará conforme se hagan modificaciones en los bancos de datos.</p>
              <p className="f14" align="justify">La dirección del titular del banco de datos es la siguiente: Calle Augusto Angulo N° 130. Urbanización San Antonio, distrito de Miraflores, provincia y departamento de Lima.</p>
              <p className="f14" align="justify">La información que se recaba a través de los formularios y/o documentos análogos alojados en el sitio web de Wong Prime se almacenarán en el banco de datos denominado CLIENTES, inscrito ante la Autoridad de Protección de Datos Personales con el código RNPDP-PJP N° 1680.</p>
              <p className="f14" align="justify">En ese sentido, los bancos de datos que contienen datos personales han sido inscritos en el Registro de Protección de Datos de la Autoridad de Protección de Datos Personales. Los datos personales que faciliten nuestros clientes/usuarios sólo podrán ser conocidos y manejados por el personal de Wong Prime que necesite conocer dicha información para poder enviar información o contestar las solicitudes de nuestros clientes/usuarios. Estos datos personales serán tratados de forma leal y lícita y no serán utilizados para otras finalidades incompatibles con las especificadas</p>
              <h3 className="f18">9. COMUNICACIÓN POR TRANSFERENCIA DE DATOS PERSONALES Y TRATAMIENTOS POR ENCARGO.</h3>
              <p className="f14" align="justify">
              Es conveniente que nuestros clientes/usuarios sepan que sus datos personales podrán ser comunicados por transferencia a las entidades administrativas, autoridades judiciales y/o policiales, siempre y cuando esté establecido por Ley.
              </p>
              <p className="f14" align="justify">
              Sin perjuicio de lo expresado, comunicamos a nuestros clientes/usuarios que a fin de gestionar de modo más eficiente las transacciones y operaciones comerciales, y cumplir con sus expectativas de compra, Wong Prime podrá valerse de proveedores de servicios (los cuales fueron detallados en el punto 3 de la presente Política) a fin de llevar adelante ciertas actividades propias de la actividad comercial. En consecuencia, respecto a los datos personales de nuestros clientes/usuarios estos proveedores tendrán la condición de encargados de tratamiento de acuerdo a las disposiciones de la LPDP y el RLPDP.
              </p>
              <h3 className="f18">10. FLUJO TRANSFRONTERIZO DE DATOS PERSONALE.</h3>
              <p className="f14" align="justify">La información personal que se recopila mediante los formularios, cupones de descuentos y/o documentos análogos alojados en las plataformas de Wong Prime se almacena en nuestros servidores, los cuales están alojados en Estados Unidos de América.</p>
              <p className="f14" align="justify">En ese sentido, con la finalidad de ejecutar las prestaciones que corresponden y que están relacionadas con el servicio, y previa declaración de la autoridad competente, transfiere a los siguientes Terceros los datos personales cuyo acceso ha sido brindado por usted en condición de titular de la Información:</p>
              <p className="f14" align="justify"><b>Datos recopilados mediante formularios:</b>
              </p>
              <ul>
                  <li>VTEX (Software as a Service) y Amazon - EEUU (Ubicación Física: EEUU. Este - Norte de Virginia us-east-1).</li>
                </ul>
              <p className="f14" align="justify">
              <b>Cupones de descuento:</b>
              </p>
              <ul>
                <li>IBM EEUU (Ubicación física: WDC01 4030 Lafayette Center Circle, Chantilly, VA 20151 / WDC04 44060 Digital Loudoun Plaza (Bldg K) Ashburn, VA 20147.</li>
              </ul>
              <p className="f14" align="justify">La inscripción de la comunicación del flujo transfronterizo se hace a través del formulario N° 66974. Es importante resaltar que la única finalidad de dicha transferencia fuera de territorio peruano es el almacenamiento, conservación y mantenimiento de los datos personales, y en ningún caso su circulación o uso fuera de territorio peruano.</p>
              <h3 className="f18">11. CONFIDENCIALIDAD DE LOS DATOS PERSONALES.</h3>
              <p className="f14" align="justify">Los datos personales facilitados por los clientes/usuarios serán tratados con total confidencialidad. Wong Prime se compromete a guardar secreto profesional indefinidamente respecto de los mismos y garantiza el deber de guardarlos adoptando todas las medidas de seguridad necesarias.</p>
              <h3 className="f18">12. SEGURIDAD DE LOS DATOS PERSONALES.</h3>
              <p className="f14" align="justify">En cumplimiento de la normativa vigente, en Wong Prime hemos adoptado las medidas técnicas de seguridad apropiadas a la categoría de los datos personales necesarias para mantener el nivel de seguridad requerido, con el objetivo de evitar la alteración, pérdida o el tratamiento o accesos no autorizados que puedan afectar a la integridad, confidencialidad y disponibilidad de la información.</p>
              <p className="f14" align="justify">En Wong Prime tenemos implementadas todas las medidas de índole legal, técnica y organizativa necesarias para garantizar la seguridad de los datos personales y evitar su alteración, pérdida y tratamiento y/o acceso no autorizado, teniendo en cuenta el estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que están expuestos, ya sea que provengan de la acción humana, del medio físico o natural, tal y como establece la legislación peruana vigente de protección de datos personales.</p>
              <p className="f14" align="justify">En Wong Prime también tenemos implementadas medidas de seguridad adicionales para reforzar la confidencialidad e integridad de la información y continuamente mantenemos la supervisión, control y evaluación de los procesos para asegurar la privacidad de los datos personales. Sin embargo, la transmisión de información mediante las redes de comunicación y de Internet no es totalmente segura; por eso, y a pesar de que en Wong Prime realizamos los mejores esfuerzos para proteger los datos personales, no podemos garantizar la seguridad de los mismos durante el tránsito en el Programa.</p>
              <p className="f14" align="justify">En tal sentido, toda la información que nuestros los clientes/usuarios proporcionen, se enviará por su cuenta y riesgo. Es por ello que en Wong Prime recomendamos la máxima diligencia a nuestros clientes/usuarios cuando trasladen a terceros o publiquen información personal para evitar poner en riesgo sus datos personales, eludiendo, Wong Prime de toda responsabilidad en caso de sustracciones, modificaciones o pérdidas de datos ilícitas.</p>
              <h3 className="f18">13. EJERCICIO DE DERECHOS.</h3>
              <p className="f14" align="justify">Los clientes/usuarios que nos hayan facilitado sus datos personales, pueden dirigirse a Wong Prime, con el fin de poder ejercer sus derechos de información, acceso, rectificación, cancelación y oposición en los términos recogidos en la legislación peruana vigente.</p>
              <p className="f14" align="justify">Los clientes/usuarios podrán ejercer sus derechos presentando una solicitud escrita en nuestra oficina principal, ubicada en Calle Augusto Angulo Nº 130 Urb. San Antonio Miraflores - Lima, conforme al “Formato Modelo para el Ejercicio de Derechos ARCO” en el horario establecido para la atención al público o mediante el envío del formato debidamente llenado al correo electrónico: wongprime@cencosud.com.pe, incluyendo en el asunto del correo: “DERECHOS ARCO”.</p>
              <h3 className="f18">14. MENORES DE EDAD.</h3>
              <p className="f14" align="justify">En Wong Prime entendemos la importancia de proteger la privacidad de los niños, especialmente en un entorno online. Por este motivo, nuestro sitio web no está diseñado ni dirigido a menores de 14 años. Wong Prime no llevará a cabo voluntariamente el tratamiento de datos personales relativos a menores de edad. En el supuesto que tengamos conocimiento que los datos personales recogidos corresponden a un menor de edad sin autorización, adoptaremos las medidas oportunas para eliminar estos datos tan pronto como sea posible.</p>
              <h3 className="f18">15. CONSENTIMIENTO.</h3>
              <p className="f14" align="justify">Al aceptar esta Política de Privacidad, nuestros clientes/usuarios están de acuerdo con todos los aspectos expuestos en este documento y nos autorizan a tratar sus datos de carácter personal para las finalidades expuestas anteriormente.</p>
              <p className="f14" align="justify">El usurario/cliente titular de la información, autoriza expresamente a Wong Prime, a realizar el tratamiento de sus datos personales identificados en la presente Política, ejecutando actividades tales como recopilar, registrar, organizar, almacenar, conservar, elaborar, modificar, extraer, consultar, utilizar, bloquear, suprimir, comunicar por transferencia, difundir, o cualquier otra forma de procesamiento que facilite el acceso, correlación o interconexión de los datos personales de forma parcial o total, en los términos expresados en la presente Política.</p>
              <h3 className="f18">16. LAS CONSECUENCIAS DE PROPORCIONAR SUS DATOS PERSONALES Y SU NEGATIVA A HACERLO.</h3>
              <p className="f14" align="justify">El suministro de los datos personales identificados en la presente Política y la obtención expresa e inequívoca del consentimiento contenido en la misma, conducirá al tratamiento de los datos personales suministrados por el titular de datos personales, según las finalidades determinadas.</p>
              <p className="f14" align="justify">Usted en condición de titular de los datos personales puede negarse a proporcionar sus datos personales a Wong Prime. Por lo que este último se abstendrá de realizar el tratamiento de sus datos personales, en caso de negarse a aceptar la presente Política que contiene el consentimiento para tratamiento de datos personales. En este sentido, como consecuencia de dicha acción, usted no podrá acceder al Programa proporcionado por Wong Prime toda vez que para el desarrollo del servicio resulta imprescindible contar con los datos personales del titular.</p>
              <h3 className="f18">17.  VIGENCIA Y MODIFICACIÓN DE LA PRESENTE POLÍTICA DE PRIVACIDAD</h3>
              <p className="f14" align="justify">La Política de Privacidad web de Wong Prime ha sido actualizada el mes de julio de 2022.</p>
              <p className="f14" align="justify">Wong Prime se reserva el derecho a modificar su Política de Privacidad web en el supuesto de que exista un cambio en la legislación vigente, doctrinal, jurisprudencial o por criterios propios empresariales. Si se introdujera algún cambio en esta Política, el nuevo texto se publicará en las plataformas de uso del Programa. Se recomienda a nuestros clientes/usuarios que accedan periódicamente a esta Política de privacidad que encontraran en este mismo sitio web.</p>
              <h3 className="f18" align="justify">19. OTROS</h3>
              <p className="f14" align="justify">Para realizar cualquier tipo de consulta respecto a esta política puede dirigirse a la siguiente dirección de correo electrónico: wongprime@cencosud.com.pe.</p>
              <h3 className="f18" align="center">Información para el ejercicio de Derechos ARCO</h3>
              <h4>1. INFORMACIÓN PARA NUESTROS CLIENTES</h4>
              <p className="f14" align="justify">Los datos personales que el Cliente ha facilitado para el uso del Programa de Beneficio de Wong Prime (en adelante, el Programa), son utilizados con la finalidad de ejecutar la relación contractual que lo vincula con nosotros y forman parte del banco de datos denominado “Clientes” de titularidad de Cencosud Retail Perú S.A. (en adelante Wong Prime).</p>
              <p className="f14" align="justify">El Cliente puede ejercer sus derechos de acceso, actualización, inclusión, rectificación, supresión y oposición, respecto de sus datos personales en los términos previstos en la Ley N° 29733 – Ley de Protección de Datos Personales, y su Reglamento aprobado por el Decreto Supremo N° 003-2013-JUS, conforme a lo indicado en el acápite siguiente “Información para el Ejercicio de Derechos ARCO”.</p>
              <h4>2. INFORMACIÓN PARA EL EJERCICIO DE DERECHOS ARCO</h4>
              <p className="f14" align="justify">El titular de los datos personales, para ejercer sus derechos de acceso, actualización, inclusión, rectificación, supresión y oposición, o cualquier otro que la ley establezca, deberá presentar una solicitud escrita en nuestra oficina principal ubicada en Calle Augusto Angulo Nº 130 Urb. San Antonio Miraflores – Lima, conforme al “Formato Modelo para el Ejercicio de Derechos ARCO” en el horario establecido para la atención al público o presentar una solicitud al correo electrónico: wongprime@cencosud.com.pe incluyendo en el asunto del correo: “DERECHOS ARCO”.  . Se podrán establecer otros canales para tramitar estas solicitudes, lo que será informado oportunamente por WONG.</p>
              <h4>3. FORMATOS MODELO PARA EL EJERCICIO DE DERECHOS ARCO</h4>
              <p>En el siguiente enlace puede descargar los formatos Modelo para el Ejercicio de Derechos ARCO</p>
              <a className="btn btn-primary" href=" https://drive.google.com/file/d/1va_NEasRGuw0RirinB0g0eYowov9NHMH/view" target="_blank">DESCARGAR FORMATOS MODELO</a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Policies;
