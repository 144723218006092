import { React } from 'react'
import Navbar from '../../components/Commons/Navbar'
import Header from './Header'
import About from './About'
import Faq from './Faq'
import Footer from '../../components/Layouts/Footer'

const Home = () => {
  return (
    // banner
    <div className="home">
      <Navbar />
      <Header />
      <About />
      <Faq />
      <Footer />
    </div>
  )
}

export default Home
