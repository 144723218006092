import React, { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Link, useNavigate } from 'react-router-dom'
import IFCondition from '../../../components/Commons/IFCondition/IFCondition'
import Loading from '../../../components/Commons/Loading/Loading'
import { getYear } from '../../../utils/dateForm'
import { sendMail } from './constants'
import { GOOGLE_CAPTCHA_KEY } from '../../../utils/constants'

const Reset = () => {
  const navigate = useNavigate()
  const mail = useRef()
  const [captcha, setCatpcha] = useState('')
  const recaptchaRef = React.createRef()

  const [state, setState] = useState({
    failed: false,
    success: false,
    loading: false,
  })

  useEffect(() => {
    if (state.success) {
      navigate('/auth/recover', {
        state: { email: mail.current.value },
      })
    }

    if (state.loading) {
      recaptchaRef?.current?.reset()
    }
  }, [state])

  return (
    <div className="home container-fluid">
      <div className="row no-gutter">
        <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image">
          <div className="col-md-12 mt-3">
            <h1
              style={{
                color: 'white',
                textAlign: 'center',
                fontWeight: 'bold',
              }}>
              PÁSATE A VIVIR MOMENTOS WONG PRIME
            </h1>
          </div>
        </div>
        <div className="col-md-8 col-lg-6 bg-white">
          <div className="login d-flex align-items-center py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-9 col-lg-8 mx-auto">
                  <Link to="/">
                    <img
                      src={require('../../../assets/img/WongPrime1.1.png')}
                      alt="wongprime"
                      width="150px"
                      style={{ margin: '0 auto', display: 'block' }}
                    />
                  </Link>
                  <h4 className="text-center mt-4">¿Olvidó su contraseña?</h4>
                  <form onSubmit={(e) => sendMail(e, captcha, setState)} className="mt-4">
                    <div className="form-label-group mb-2">
                      <label htmlFor="inputEmail">Ingresa tu correo electrónico</label>
                      <input
                        type="email"
                        name="email"
                        id="inputEmail"
                        className="form-control"
                        placeholder="ejemplo@sitio.pe"
                        required
                        ref={mail}
                        autoFocus
                      />
                    </div>

                    <IFCondition cond={state.failed}>
                      <div
                        className="alert alert-danger alert-dismissible fade show mt-4"
                        role="alert">
                        <strong>
                          <i className="fa fa-exclamation-circle"></i> Lo sentimos
                        </strong>{' '}
                        El email ingresado no está registrado.
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </IFCondition>

                    <br />
                    <ReCAPTCHA
                      style={{ display: 'flex', justifyContent: 'center' }}
                      sitekey={GOOGLE_CAPTCHA_KEY}
                      ref={recaptchaRef}
                      onExpired={() => setCatpcha('')}
                      onChange={setCatpcha}
                    />

                    <button
                      className="btn btn-lg btn-primary btn-block btn-login mb-2 mt-5"
                      hidden={state.loading}
                      disabled={!captcha}
                      type="submit">
                      Enviar
                    </button>

                    <Link className="btn btn-sm btn-link btn-block" to="/auth/login">
                      <i className="fa fa-arrow-circle-left"></i> Volver al login
                    </Link>
                  </form>
                </div>
              </div>
              <footer className="page-footer font-small pt-5 ">
                <div className="footer-copyright text-center text-muted py-3">
                  © Cencosud {getYear()}. Todos los derechos reservados.
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
      <Loading visible={state.loading} />
    </div>
  )
}

export default Reset
