import React from 'react';
import Lottie from 'lottie-react';
import animationData from '../../../assets/lotties/sad-empty-box';

const SadBox = ({ visible }) => {
    if (!visible)
        return null;

    return (
        <div style={{ position: 'fixed', width: '100%', height: '100%', top: 0, left: 0, backgroundColor: "rgb(0 0 0 / 33%)",  zIndex: 9999, display: 'flex', justifyContent: 'center' }}>
            <Lottie animationData={animationData} loop={true} style={{width: "60%"}} />
        </div>
    )
}

export default SadBox