import React, { useEffect } from "react";
import { NavLink, Link, Outlet } from "react-router-dom";
import ProfileNavbar from "./ProfileNavbar/ProfileNavbar";
import Loading from "./Loading/Loading";
import useLogin from "../../redux/hooks/useLogin";
import { useLocation } from 'react-router-dom';


const Sidebar = () => {
  const { isLoading, user } = useLogin();
  let location = useLocation();

  useEffect(() => {
    if(user && user.id){
      window.Layout();
    }
  }, [user])
  

  return (
    <>
    <div className="profile">
      <nav
        className="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
        id="sidenav-main" >
        <div className="scrollbar-inner">
          {/* Brand */}
          <div className="sidenav-header  align-items-center">
            <Link className="navbar-brand" to="inicio">
              <img
                src={require("../../assets/img/WongPrime1.1.png")}
                className="navbar-brand-img"
                alt="cencoprime"
              />
            </Link>
          </div>
          <div className="navbar-inner">
            {/* Collapse */}
            <div
              className="collapse navbar-collapse"
              id="sidenav-collapse-main" >
              {/* Nav items */}
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink className='nav-link' to="inicio" >
                    <i className={`fa fa-home ${location.pathname === '/perfil/inicio' && 'text-primary'}`}></i>
                    <span className="nav-link-text">Inicio</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className='nav-link' to="compras" >
                    <i className={`fa fa-shopping-bag ${location.pathname === '/perfil/compras' && 'text-primary'}`}></i>
                    <span className="nav-link-text">Mis compras</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className='nav-link' to="cuenta" >
                    <i className={`fa fa-user ${location.pathname === '/perfil/cuenta' && 'text-primary'}`}></i>
                    <span className="nav-link-text">Mi cuenta</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className='nav-link' to="beneficios" >
                    <i className={`fa fa-bookmark ${location.pathname === '/perfil/beneficios' && 'text-primary'}`}></i>
                    <span className="nav-link-text">Mis beneficios</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className='nav-link' to="ayuda" >
                    <i className={`fa fa-headphones ${location.pathname === '/perfil/ayuda' && 'text-primary'}`}></i>
                    <span className="nav-link-text">Ayuda Prime</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className='nav-link' to="terminos-y-condiciones" >
                    <i className={`fa fa-file ${location.pathname === '/perfil/terminos-y-condiciones' && 'text-primary'}`}></i>
                    <span className="nav-link-text">Términos y condiciones</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className='nav-link' to="preguntas-frecuentes" >
                    <i className={`fa fa-question-circle ${location.pathname === '/perfil/preguntas-frecuentes' && 'text-primary'}`}></i>
                    <span className="nav-link-text">Preguntas frecuentes</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div className="main-content" id="panel">
        <ProfileNavbar />
        <Outlet />
      </div>
      <Loading visible={isLoading} />
    </div>
    </>
  );
};

export default Sidebar;
