import React from 'react'

const NotFoundUser = () => {
    return (
        <div className="col-6">
            <div className="alert alert-danger text-center" role="alert" style={{ with: '70%', fontSize: '18px' }}>
                Usuario no encontrado
            </div>
        </div>
    )
}

export default NotFoundUser
