import React, { useRef, useState } from "react";
import SadBox from "../../../components/Commons/SadBox/SadBox";
import { useModalCancel } from "./hooks";
import IFCondition from "../../../components/Commons/IFCondition/IFCondition";

const ModalCancel = ({user}) => {
  const pass = useRef(HTMLInputElement);
  const [error, setError] = useState(false);
  const { submitCancel, errorMessage, data, isLoading, submit, isUnsubscribed, from, fromLoading } = useModalCancel();
  const validPass = () => {
    const val = pass.current.value;
    if(val) {
      setError(false);
      return submitCancel(val)
    }
    setError(true);
  }

  return (
    <>
    <div
      className="modal fade"
      id="cancel-s"
      role="dialog"
      aria-labelledby="detalle-promoLabel"
      aria-hidden="true" >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <IFCondition cond={isUnsubscribed} >
            <div className="modal-body ">
              <p className="alert" style={{ backgroundColor: '#D87A9A'}}>
                <h2 style={{ wordBreak: "break-all", color: '#fff', textAlign: 'justify', fontSize: '25px'}}>
                  {data && data.error}
                </h2>
              </p>
            </div>
          </IFCondition>
          <IFCondition cond={!isUnsubscribed} >
            <div className="modal-header ">
              <div className="media text-danger">
                <i className="fa fa-exclamation-circle fa-3x align-self-center mr-3"></i>
                <div className="media-body lead mt-0">
                  ¿Quieres cancelar tu suscripción​?
                </div>
              </div>
            </div>
            <div className="modal-body">
              <p className="lead mt-0">
                Si cancelas tu suscripción a Wong Prime perderás tus beneficios y
                volverás a pagar precio completo por cada envio que realices.
              </p>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">
                  Si deseas cancelar tu suscripción, ingresa tu contraseña:
                </label>
                <input
                  type="password"
                  className="form-control"
                  ref={pass}
                  placeholder="Ingresa tu contraseña"
                />
              </div>
              <IFCondition cond={errorMessage && from === "cancel"} >
                <div className="alert alert-warning">
                  {errorMessage}
                </div>
              </IFCondition>
              <IFCondition cond={error} >
                <div className="alert alert-warning">
                  La clave es requerida
                </div>
              </IFCondition>
              <div className="alert alert-danger">
                  La devolución de dinero será efectiva de acuerdo con los términos y condiciones que podras consultar&nbsp;
                  <a href={"/terminos-y-condiciones"} target="_blank" rel="noreferrer" >aquí</a>.
                  <br/>
                  En caso de estar en el periodo de prueba, la cancelación anulará la renovación automática.
              </div>
            </div>
            <div className="modal-footer">
              <button data-dismiss="modal" className="btn btn-link">Cerrar</button>
              <button onClick={validPass} className="btn btn-danger">Desuscribir</button>
            </div>
          </IFCondition>
        </div>
      </div>
    </div>
    <SadBox visible={isLoading && submit && fromLoading === "cancel"} />
    </>
  );
};
export default ModalCancel;
