import React from 'react'

const FaQProfile = () => {
  return (
    <>
      <div className="container-fluid mt-4">
        <div className="col-12  text-muted pt-5">
          <h2 className="display-4">PREGUNTAS FRECUENTES</h2>
          <div id="temas_generales" className="seccion-pregunta">
            <div className="titulo_faq">
              <h3>Información general</h3>
            </div>
            <div className="titulo_faq">
              <h4>¿Qué es Wong Prime? </h4>
            </div>
            <p className="text_faq">
              Es la membresía de Wong que te brinda beneficios exclusivos como:
            </p>
            <ul style={{ listStyle: 'none' }}>
              <li className="margen-bottom align_li_to_icon">
                <span>
                  Despachos gratis e ilimitados en compras de supermercado*, por compras desde S/
                  79.90{' '}
                </span>
              </li>
              <li className="margen-bottom align_li_to_icon">
                <span>Ofertas exclusivas en Wong.pe y la App Wong.</span>
              </li>
              <li className="margen-bottom align_li_to_icon">
                <span>Duplicación de puntos Bonus por cada compra en Wong.pe y la App Wong.</span>
              </li>
              <li className="margen-bottom align_li_to_icon">
                <span>
                  Dos despachos al mes gratuitos en electro, tecnología, muebles y colchones, por
                  compras mayores a 299 soles.
                </span>
              </li>
              <li className="margen-bottom align_li_to_icon">
                <span>Opción exclusiva de Wong Prime en nuestros canales de atención.</span>
              </li>
              <li className="margen-bottom align_li_to_icon">
                <span>Descuento en combustible en Primax.</span>
              </li>
              <li className="margen-bottom align_li_to_icon">
                <span>Ofertas exclusivas a Usuarios de Wong Prime.</span>
              </li>
            </ul>
            <div className="titulo_faq">
              <h4>¿Quiénes pueden ser parte de la membresía Wong Prime?</h4>
            </div>
            <p className="text_faq">
              Todas las personas naturales, mayores de edad con Documento de identidad (DNI) o Carné
              de Extranjería.
            </p>
            <div className="titulo_faq">
              <h4>Términos y condiciones </h4>
            </div>
            <p className="text_faq">
              <a href="https://wongprime.pe/terminos-y-condiciones">
                https://wongprime.pe/terminos-y-condiciones
              </a>
            </p>
            <div className="titulo_faq">
              <h4>¿Existen zonas de cobertura para los envíos?</h4>
            </div>
            <p className="text_faq">
              Suscribiéndote a Wong Prime vas a contar con toda la cobertura Wong.pe (Hacemos envíos a todo Lima Metropolitana, Chosica, Chaclacayo, Cieneguilla, Pachacamac, Trujillo y Playas en temporada (desde Ancón hasta Cerro Azul).
            </p>
          </div>

          <div id="beneficios" className="seccion-pregunta">
            <div className="titulo_faq">
              <h3>Beneficios</h3>
            </div>
            <div className="titulo_faq">
              <h4>¿Qué beneficios obtengo?</h4>
            </div>
            <p className="text_faq">
              <ul>
                <li>
                  Tendrás envíos gratis en tus compras de supermercado* por el monto mínimo de s./ 79.90, adicional recibes dos despachos al mes gratuitos en electro, tecnología, muebles y colchones, por compras mayores a 299 soles.
                </li>
                <li>
                  Contarás con una doble acumulación de Puntos Bonus en tus compras realizadas a
                  través del App y sitio web de Wong.
                </li>
                <li>Y disfrutarás de ofertas y descuentos exclusivos.</li>
                <li>Además de descuentos en combustible Primax.</li>
              </ul>
            </p>
            <div className="titulo_faq">
              <h4>¿Cuáles son los requisitos para usar mis beneficios?</h4>
            </div>
            <p className="text_faq">
              <ul>
                <li>
                  Para aplicar tus beneficios de envío gratis y doble acumulación de Puntos Bonus, debes iniciar sesión con el mismo documento de identidad y correo electrónico que tienes registrado en Wong Prime antes de realizar tu compra en www.wong.pe (mismo documento de identidad o mismo correo que se usa en Wong.pe
                </li>
                <li>
                  Los envíos gratis aplican en compras de supermercado por el monto mínimo de s./ 79.90 y en las otras categorías es un envío gratis al mes. 
                </li>
                <li>
                  En caso de no estar inscrito en www.wong.pe con el mismo correo registrado en Wong Prime, los beneficios de envíos gratis y doble acumulación no podrán ser aplicados. Te recomendamos crear tu cuenta para tener una experiencia completa con Wong Prime en 
                  <a href="www.wongprime.pe" target="_blank">www.wongprime.pe</a> opción "Regístrate".
                </li>
              </ul>
            </p>

            <div className="titulo_faq">
              <h4>¿Cómo puedo ver los beneficios que he utilizado?</h4>
            </div>
            <p className="text_faq">
              Ingresa a <a href="https://wongprime.pe/">https://wongprime.pe/</a> e inicia sesión.
              En la parte superior de tu portal de usuario Prime podrás visualizar el número de
              envíos que has utilizado y tu doble acumulación de puntos.
            </p>
          </div>

          <div id="atencion" className="seccion-pregunta">
            <div className="titulo_faq">
              <h3>Atención y servicio al cliente. </h3>
            </div>
            <div className="titulo_faq">
              <h4>¿Qué canales de comunicación tengo?</h4>
            </div>
            <p className="text_faq">
              Para cualquier consulta o solicitud puedes enviar un correo electrónico a
              Wongprime@cencosud.com.pe y un asesor te atenderá gustosamente.
            </p>
            <div className="titulo_faq">
              <h4>¿Cómo se contactarán conmigo el asesor de servicio ? </h4>
            </div>
            <p className="text_faq">
              Dependiendo del tipo de solicitud o reclamo, te contactaremos mediante los datos que
              tengas registrados en Wong Prime.
            </p>
          </div>

          <div id="inscripcion" className="seccion-pregunta">
            <div className="titulo_faq">
              <h3> Inscripción y el pago</h3>
            </div>
            <div className="titulo_faq">
              <h4>
                ¿Cómo es el proceso de pago para adquirir mi membresía y ser parte de Wong Prime?
              </h4>
            </div>
            <p className="text_faq">
              El proceso es muy sencillo, se realizará mediante un cargo a la tarjeta de crédito y/o débito que ingresaste en el formulario de inscripción.
            </p>
            <div className="titulo_faq">
              <h4>¿Qué medios de pago puedo utilizar?</h4>
            </div>
            <p className="text_faq">Puedes utilizar tarjeta de crédito y/o débito.</p>
            <div className="titulo_faq">
              <h4>¿Cómo actualizo mi método de pago?</h4>
            </div>
            <p className="text_faq">
              Ingresa a wongprime.pe y en la sección “ingresar”, ingresas tu correo y contraseña, luego ingresa a la sección de “mis cuenta” y en la sección “tu suscripción” vas a poner actualizar el medio de pago. Para que tu suscripción siempre esté operativa debes tener una Tarjeta de crédito válida ingresada.
            </p>
          </div>

          <div id="cancelar" className="seccion-pregunta">
            <div className="titulo_faq">
              <h3>Cancelación y devoluciones </h3>
            </div>
            <div className="titulo_faq">
              <h4>¿Puedo cancelar mi membresía Wong Prime?</h4>
            </div>
            <p className="text_faq">
              Sí, puedes cancelar tu suscripción al Programa Wong Prime en cualquier momento por el
              sitio web: www.wongprime.pe o comunicándote a través del Call Center 6138888 opción
              1-1 o. En un plazo máximo de 48 horas se habrá generado la cancelación de tu
              suscripción.
            </p>
            <div className="titulo_faq">
              <h4>¿Puede Wong cancelar mi membresía Wong Prime? </h4>
            </div>
            <p className="text_faq">
              Sí, puedes cancelar tu suscripción al Programa Wong Prime en cualquier momento, solo debes seguir los siguientes pasos:
            </p>
            <ol>
                <li>Ingresar a Wongprime.pe </li>
                <li>Ingresar a “Ingresar”</li>
                <li>Colocar correo electrónico y contraseña</li>
                <li>Ingresar a “Mi cuenta”</li>
                <li>Dale clic en “Cancelar mi suscripción”</li>
                <li>Confirmación la cancelación ingresando nuevamente la contraseña</li>
                <li>Confirmar dando clic en el botón “desuscribir”</li>
            </ol>
            <p>
                          En caso no pudieras desuscribirte, puedes llamar al 6138888 opción 1 y un asesor podrá guiarte en los pasos. Toma en cuenta que por motivos de seguridad debes realizarlo directamente.   
                        </p>
                        <p>
                          Una vez completados los pasos de la solicitud, se habrá generado la cancelación de tu suscripción en un plazo máximo de 72 horas. Al correo suscrito llegará un mail de confirmación de la cancelación de Wong Prime. 
                        </p>
           
                        <div className="titulo_faq">
              <h4>
              ¿Puede Wong cancelar mi membresía Wong Prime?{' '}
              </h4>
            </div>
            <p className="text_faq">
              Wong se reserva el derecho de rechazar o cancelar una membresía en cualquier momento en caso de que detecte un incumplimiento en los términos y condiciones generales de esta. Recomendamos leer los términos y condiciones del programa.
            </p>
           
            <div className="titulo_faq">
              <h4>
                ¿Puedo cancelar mi suscripción porque no me gustó el servicio y me devuelven mi
                dinero?{' '}
              </h4>
            </div>
            <p className="text_faq">
              Si tu renuncia al Programa Wong Prime es realizada durante los primeros 10 días desde el pago o renovación de la suscripción, y no has utilizado alguno de los beneficios ofrecidos por el programa durante dicho período, entonces se te devolverá el 100% del costo de la membresía. La renuncia en cualquier otro momento durante la vigencia de la membresía o habiendo utilizado alguno de los beneficios no dará derecho a devolución o restitución del dinero; sin embargo, los beneficios seguirán vigentes hasta el término del período de suscripción respectivo. En caso hayas cancelado tu suscripción o renunciado al Programa Wong Prime y luego desees reingresar, no tendrás “periodo gratuito”.
            </p>
          </div>

          <div id="cuenta" className="seccion-pregunta">
            <div className="titulo_faq">
              <h3>Mi Cuenta Wong Prime</h3>
            </div>
            <div className="titulo_faq">
              <h4>¿ Cómo ingreso a mi cuenta Wong Prime?</h4>
            </div>
            <p className="text_faq">
              Para ingresar a tu cuenta sigue estos pasos: 1. Ve a wongprime.pe 2. Haz clic en el
              botón ""Login"" 3. Escribe tu correo electrónico 4. Ingresa tu clave personal y haz
              clic en el botón ""Continuar"" 5. Apenas ingreses a tu portal Wong Prime, en la
              esquina superior izquierda de la pantalla verás tu nombre y el menú de opciones. Listo
              ya ingresaste a tu cuenta. Ahí podrás actualizar tus datos, conocer tus beneficios y
              más.
            </p>
            <div className="titulo_faq">
              <h4>No puedo ingresar a mi cuenta </h4>
            </div>
            <p className="text_faq">
              Prueba recuperando tu contraseña, ingresa a tu correo y sigue los pasos del mensaje
              que te llegará. No te olvides de revisar en la sección de correo no deseado o SPAM.
            </p>
            <div className="titulo_faq">
              <h4>¿Cómo recupero mi clave de acceso a Wong Prime en caso de olvidarla?</h4>
            </div>
            <p className="text_faq">
              Ve a wongprime.pe y haz clic en "Ingresa". Cuando te pedimos tu clave, haz clic en
              "¿Olvidaste tu contraseña?"
            </p>
            <div className="titulo_faq">
              <h4>¿Cómo puedo cambiar el correo asociado a mi cuenta Wong Prime?</h4>
            </div>
            <p className="text_faq">
              En el portal de usuario Prime encontrarás el Buzón de Consultas y Sugerencias. Ingresa
              a la opción Wong Prime y envía tu solicitud, que un asesor gestionará el cambio de tu
              correo electrónico.
            </p>
            <div className="titulo_faq">
              <h3>Descuento PRIMAX</h3>
            </div>
            <div className="titulo_faq">
              <h4>¿En qué consiste el beneficio?</h4>
            </div>
            <p className="text_faq">
              Al suscribirte a Wong Prime, accederás a un descuento en grifos PRIMAX, el cual
              aplicará dependiendo del tipo de combustible y la región del país donde te encuentres.
            </p>
            <div className="titulo_faq">
              <h4>¿Cómo hago efectivo el descuento?</h4>
            </div>
            <p className="text_faq">
              Luego de suscribirte a Wong Prime, deberás esperar 72 hs, luego deberás ingresar a  https://primax.com.pe/convenios/ y registrarte con el mismo DNI y correo electrónico que en Wong Prime.
            </p>
            <p>*Descuento Aplica por galón, según tipo de combustible.</p>
            {/* <table className="table table-borderless table-striped table-sm pl-2 text-right">
              <thead>
                <tr>
                  <th scope="col" style={{ float: 'left;' }}>
                    ZONAS
                  </th>
                  <th scope="col" className="text-center">
                    CIUDADES
                  </th>
                  <th scope="col" className="text-center">
                    DIESEL
                  </th>
                  <th scope="col" className="text-center">
                    90
                  </th>
                  <th scope="col" className="text-center">
                    95
                  </th>
                  <th scope="col" className="text-center">
                    97
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-left pl-3 f16">CHIMBOTE I, II, ANCASH</td>
                  <td className="text-left pl-3 f16">ANCASH</td>
                  <td className="text-left pl-3 f16">0.40</td>
                  <td className="text-left pl-3 f16">0.00</td>
                  <td className="text-left pl-3 f16">0.40</td>
                  <td className="text-left pl-3 f16">0.60</td>
                </tr>
                <tr>
                  <td className="text-left pl-3 f16">AREQUIPA CAYLLOMA</td>
                  <td className="text-left pl-3 f16">AREQUIPA</td>
                  <td className="text-left pl-3 f16">0.00</td>
                  <td className="text-left pl-3 f16">0.20</td>
                  <td className="text-left pl-3 f16">0.70</td>
                  <td className="text-left pl-3 f16">0.60</td>
                </tr>
                <tr>
                  <td className="text-left pl-3 f16">CAJAMARCA</td>
                  <td className="text-left pl-3 f16">CAJAMARCA</td>
                  <td className="text-left pl-3 f16">0.40</td>
                  <td className="text-left pl-3 f16">0.00</td>
                  <td className="text-left pl-3 f16">0.00</td>
                  <td className="text-left pl-3 f16">0.00</td>
                </tr>
                <tr>
                  <td className="text-left pl-3 f16">
                    LIMA I, II, HUAURA, HUAURAL, BARRANCA I, II, CAÑETE I, II, HUAROCHIRI
                  </td>
                  <td className="text-left pl-3 f16">LIMA-CALLAO</td>
                  <td className="text-left pl-3 f16">0.30</td>
                  <td className="text-left pl-3 f16">0.30</td>
                  <td className="text-left pl-3 f16">0.70</td>
                  <td className="text-left pl-3 f16">1.00</td>
                </tr>
                <tr>
                  <td className="text-left pl-3 f16">CUSCO</td>
                  <td className="text-left pl-3 f16">CUSCO</td>
                  <td className="text-left pl-3 f16">0.00</td>
                  <td className="text-left pl-3 f16">0.00</td>
                  <td className="text-left pl-3 f16">0.40</td>
                  <td className="text-left pl-3 f16">0.00</td>
                </tr>
                <tr>
                  <td className="text-left pl-3 f16">NAZCA, ICA I, II, CHINCHA, PISCO</td>
                  <td className="text-left pl-3 f16">ICA</td>
                  <td className="text-left pl-3 f16">0.20</td>
                  <td className="text-left pl-3 f16">0.20</td>
                  <td className="text-left pl-3 f16">0.40</td>
                  <td className="text-left pl-3 f16">0.60</td>
                </tr>
                <tr>
                  <td className="text-left pl-3 f16">JUNIN</td>
                  <td className="text-left pl-3 f16">JUNIN</td>
                  <td className="text-left pl-3 f16">0.30</td>
                  <td className="text-left pl-3 f16">0.20</td>
                  <td className="text-left pl-3 f16">0.40</td>
                  <td className="text-left pl-3 f16">0.60</td>
                </tr>
                <tr>
                  <td className="text-left pl-3 f16">TRUJILLO I, II, III, CHEPEN</td>
                  <td className="text-left pl-3 f16">LA LIBERTAD</td>
                  <td className="text-left pl-3 f16">0.20</td>
                  <td className="text-left pl-3 f16">0.20</td>
                  <td className="text-left pl-3 f16">0.40</td>
                  <td className="text-left pl-3 f16">0.80</td>
                </tr>
                <tr>
                  <td className="text-left pl-3 f16">
                    LAMBAYEQUE I, II, PACASMAYO, CHICLAYO I, II
                  </td>
                  <td className="text-left pl-3 f16">LAMBAYEQUE</td>
                  <td className="text-left pl-3 f16">0.30</td>
                  <td className="text-left pl-3 f16">0.20</td>
                  <td className="text-left pl-3 f16">0.40</td>
                  <td className="text-left pl-3 f16">0.80</td>
                </tr>
                <tr>
                  <td className="text-left pl-3 f16">PIURA, I, II, III, IV, VI, VII, TALARA</td>
                  <td className="text-left pl-3 f16">PIURA</td>
                  <td className="text-left pl-3 f16">0.40</td>
                  <td className="text-left pl-3 f16">0.30</td>
                  <td className="text-left pl-3 f16">0.70</td>
                  <td className="text-left pl-3 f16">0.80</td>
                </tr>
                <tr>
                  <td className="text-left pl-3 f16">TACNA</td>
                  <td className="text-left pl-3 f16">TACNA</td>
                  <td className="text-left pl-3 f16">0.00</td>
                  <td className="text-left pl-3 f16">0.30</td>
                  <td className="text-left pl-3 f16">0.40</td>
                  <td className="text-left pl-3 f16">0.60</td>
                </tr>
              </tbody>
            </table> */}
             <div className='table-primax-desktop'>
                <img src={require('../../../assets/img/MAILING-WONGPRIME_PRIMAX-DESKTOP-2.png')}/>
            </div>
            <div className='table-primax-mobile'>
              <img src={require('../../../assets/img/MAILING-WONGPRIME_PRIMAX-MOBILE-3.png')}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FaQProfile
