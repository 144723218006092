import React from 'react';
import FormRegister from './FormRegister';
import HeroImageForms from '../../../components/Commons/Forms/Layout/HeroImageForms';
import FooterSuscription from '../../../components/Commons/Forms/Layout/FooterSuscription';
import HeadersForm from '../../../components/Commons/Forms/HeaderForm';
import ModalInfoValidate from './ModalInfoValidate';
import { Helmet } from "react-helmet";


const Register = () => {

    const [userData, setUserData] = React.useState({});
    
    function registerUser(_userData, submit = false) {
        const data = {...userData, ..._userData, submit};
        setUserData(data);
        if(submit){
            window.$('#validate-info').modal('show');
        }
    }
    return <>
    <Helmet>
      <title>Suscríbete</title>
    </Helmet>
    <div className="home">
        <HeroImageForms>
            <HeadersForm>
                <FormRegister registerUser={registerUser} />
            </HeadersForm>
            <FooterSuscription />
        </HeroImageForms>
        <ModalInfoValidate userData={userData}/>
    </div>
    </>
}

export default Register
