const config = {
    api_url: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'x-header-location': 'bG9jYXRpb24tcGU=',
        'apiKey': `${process.env.REACT_APP_API_KEY}`
    }
};

export default config
