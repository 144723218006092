import { apiLogin } from '../../services/Login'
import {
  apiRegister,
  apiUserCards,
  apiUpdateCard,
  apiCreateCard,
  apiUpdate,
  apiUnsubscribe,
  apiDeleteCard,
  apiRegisterPremium,
} from '../../services/Register'
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  REGISTER_LOADING,
  USER_CARDS_LOADING,
  USER_CARDS_SUCCESS,
  USER_CARDS_FAIL,
  UPDATE_CARD_FAIL,
  UPDATE_CARD_SUCCESS,
  CREATE_CARD_FAIL,
  CREATE_CARD_LOADING,
  CREATE_CARD_SUCCESS,
  REGISTER_END_LOADING,
  USER_UNSUBSCRIBE_SUCCESS,
  USER_UNSUBSCRIBE_FAILED,
  UPDATE_SUCCESS,
  REMOVE_REGISTER_DATA,
} from '../reducers/RegisterReducers'
import { updateUserInfo } from './LoginActions'

export const _REGISTER_START_LOADING = (from = 'register') => ({
  type: REGISTER_LOADING,
  payload: { from },
})

export const _REGISTER_END_LOADING = () => ({
  type: REGISTER_END_LOADING,
  payload: {},
})

const _REGISTER_SUCCESS = (data) => ({
  type: REGISTER_SUCCESS,
  payload: {
    data,
  },
})

const _UPDATE_SUCCESS = (data) => ({
  type: UPDATE_SUCCESS,
  payload: {
    data,
  },
})

const _USER_UNSUBSCRIBE_SUCCESS = (data) => ({
  type: USER_UNSUBSCRIBE_SUCCESS,
  payload: {
    data,
  },
})

const _REGISTER_FAIL = (error, from = 'register') => ({
  type: REGISTER_FAIL,
  payload: {
    error,
    from,
  },
})

const _USER_UNSUBSCRIBE_FAILED = (error, from = '') => ({
  type: USER_UNSUBSCRIBE_FAILED,
  payload: {
    error,
    from,
  },
})

const _USER_CARDS_LOADING = (from = 'register') => ({
  type: USER_CARDS_LOADING,
  payload: { from },
})

const _USER_CARDS_SUCCESS = (data) => ({
  type: USER_CARDS_SUCCESS,
  payload: {
    data,
  },
})

const _USER_CARDS_FAIL = (error, from = '') => ({
  type: USER_CARDS_FAIL,
  payload: {
    error,
    from,
  },
})

const _UPDATE_CARD_SUCCESS = (data) => ({
  type: UPDATE_CARD_SUCCESS,
  payload: {
    data,
  },
})

const _UPDATE_CARD_FAIL = (error, from = '') => ({
  type: UPDATE_CARD_FAIL,
  payload: {
    error,
    from,
  },
})

const _CREATE_CARD_LOADING = (from = 'register') => ({
  type: CREATE_CARD_LOADING,
  payload: { from },
})

const _CREATE_CARD_SUCCESS = (data) => ({
  type: CREATE_CARD_SUCCESS,
  payload: {
    data,
  },
})

const _CREATE_CARD_FAIL = (error, from = '') => ({
  type: CREATE_CARD_FAIL,
  payload: {
    error,
    from,
  },
})

const _REMOVE_REGISTER_DATA = () => ({
  type: REMOVE_REGISTER_DATA,
  payload: {},
})

export const registerAction = (registerForm) => (dispatch) => {
  dispatch(_REGISTER_START_LOADING('register'))
  apiRegister(registerForm)
    .then((res) => {
      if (!res.data.success) {
        return dispatch(_REGISTER_FAIL(res.data.error, 'register'))
      }
      dispatch(_REGISTER_SUCCESS(res.data))
    })
    .catch((error) => {
      if (error.response.status === 500) {
        return dispatch(_REGISTER_FAIL(error?.response?.data?.error, 'register'))
      }
      if (error.response.data.statusCode === 403) {
        return dispatch(_REGISTER_FAIL(error?.response?.data?.message, 'register'))
      }
      dispatch(_REGISTER_FAIL(error?.response?.data?.error, 'register'))
    })
}

export const registerPremiumAction = (registerForm) => (dispatch) => {
  let error = true
  dispatch(_REGISTER_START_LOADING('register'))
  //función register premium
  apiRegisterPremium(registerForm)
    .then((res) => {
      if (!res.data.success) {
        return dispatch(_REGISTER_FAIL(res.data.error.user_message, 'register'))
      }
      dispatch(_REGISTER_SUCCESS(res.data))
    })
    .catch((error) => {
      if (error.response.status === 500 || error.response.status === 404) {
        return dispatch(_REGISTER_FAIL('Error interno', 'register'))
      }
      dispatch(_REGISTER_FAIL(error.response.data.error.user_message, 'register'))
    })
}

export const updateAction = (updateForm, bday) => (dispatch) => {
  dispatch(_REGISTER_START_LOADING('update'))
  apiUpdate(updateForm)
    .then((res) => {
      if (!res.data.success) {
        return dispatch(_REGISTER_FAIL(res.data.error.user_message, 'update'))
      }
      dispatch(_UPDATE_SUCCESS())
      dispatch(updateUserInfo({ ...updateForm, bday: bday }))
    })
    .catch((error) => {
      if (error.response.status === 500) {
        return dispatch(_REGISTER_FAIL('Error interno', 'update'))
      }
      dispatch(_REGISTER_FAIL(error.response.data.error.user_message, 'update'))
    })
}

export const unsubscribeAction = (pass) => (dispatch) => {
  dispatch(_REGISTER_START_LOADING('cancel'))
  apiUnsubscribe()
    .then((res) => {
      if (!res.data.success) {
        return dispatch(_USER_UNSUBSCRIBE_FAILED(res.data.error.user_message, 'cancel'))
      }
      dispatch(_USER_UNSUBSCRIBE_SUCCESS(res.data))
    })
    .catch((error) => {
      if (error.response.status === 500) {
        return dispatch(_USER_UNSUBSCRIBE_FAILED('Error interno', 'cancel'))
      }
      dispatch(_USER_UNSUBSCRIBE_FAILED(error.response.data.error.user_message, 'cancel'))
    })
}

export const userCardsAction = () => (dispatch) => {
  dispatch(_USER_CARDS_LOADING('cards'))
  apiUserCards()
    .then((res) => {
      if (!res.data.success) {
        return dispatch(_USER_CARDS_FAIL(res.data.error.user_message, 'cards'))
      }
      dispatch(_USER_CARDS_SUCCESS(res.data))
    })
    .catch((error) => {
      if (error.response.status === 500) {
        return dispatch(_USER_CARDS_FAIL('Error interno', 'cards'))
      }
      dispatch(_USER_CARDS_FAIL(error.response.data.error.user_message, 'cards'))
    })
}

export const updateCardAction = (registerForm) => (dispatch) => {
  dispatch(_USER_CARDS_LOADING('update-card'))
  apiUpdateCard(registerForm)
    .then((res) => {
      if (!res.data.success) {
        return dispatch(_UPDATE_CARD_FAIL(res.data.error.user_message, 'update-card'))
      }
      dispatch(_UPDATE_CARD_SUCCESS(res.data))
    })
    .catch((error) => {
      if (error.response.status === 500) {
        return dispatch(_UPDATE_CARD_FAIL('Error interno', 'update-card'))
      }
      dispatch(_UPDATE_CARD_FAIL(error.response.data.error.user_message, 'update-card'))
    })
}

export const deleteCardAction = (cardId) => (dispatch) => {
  dispatch(_USER_CARDS_LOADING('delete-card'))
  apiDeleteCard(cardId)
    .then((res) => {
      if (!res.data.success) {
        return dispatch(_UPDATE_CARD_FAIL(res.data.error.user_message, 'delete-card'))
      }
      dispatch(_UPDATE_CARD_SUCCESS(res.data))
    })
    .catch((error) => {
      if (error.response.status === 500) {
        return dispatch(_UPDATE_CARD_FAIL('Error interno', 'delete-card'))
      }
      dispatch(_UPDATE_CARD_FAIL(error.response.data.error.user_message, 'delete-card'))
    })
}

export const createCardAction = (registerForm) => (dispatch) => {
  dispatch(_CREATE_CARD_LOADING('save-card'))
  apiCreateCard(registerForm)
    .then((res) => {
      if (!res.data.success) {
        return dispatch(_CREATE_CARD_FAIL(res.data.error.user_message, 'save-card'))
      }
      dispatch(_CREATE_CARD_SUCCESS(res.data))
    })
    .catch((error) => {
      if (error.response.status === 500) {
        return dispatch(_CREATE_CARD_FAIL('Error interno', 'save-card'))
      }
      dispatch(_CREATE_CARD_FAIL(error.response.data.error.user_message, 'save-card'))
    })
}

export const removeRegisterData = () => (dispatch) => dispatch(_REMOVE_REGISTER_DATA())
