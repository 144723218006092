import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


const SkeletonComponent = () => {
    return (
        <SkeletonTheme height={70}>
            <Skeleton count={4} />
        </SkeletonTheme>
    )
}

export default SkeletonComponent
