import React from 'react'
import { NavLink, Link, Outlet, useLocation } from "react-router-dom";
import HeroHelp from '../../views/Sac/HeroHelp';
import ProfileNavbar from "./ProfileNavbar/ProfileNavbar";

const SidebarSac = () => {
    let location = useLocation();
    return (
        <div className="profile">
            <nav className="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white" id="sidenav-main">
                <div className="scrollbar-inner">
                    {/* Brand */}
                    <div className="sidenav-header  align-items-center">
                        <Link className="navbar-brand" to="inicio">
                            <img src={require("../../assets/img/WongPrime1.1.png")} className="navbar-brand-img" alt="cencoprime" />
                        </Link>
                    </div>
                    <div className="navbar-inner">
                        {/* Collapse */}
                        <div className="collapse navbar-collapse" id="sidenav-collapse-main">
                            {/* Nav items */}
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="inicio">
                                        <i className={`fa fa-home text-primary ${location.pathname === '/sac/inicio'}`}></i>
                                        <span className="nav-link-text">Inicio</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="datos-cobros">
                                        <i className={`fa fa-shopping-bag ${location.pathname === '/sac/datos-cobros'}`}></i>
                                        <span className="nav-link-text">Datos de cobros</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="historial-compras">
                                        <i className={`fa fa-user ${location.pathname === '/sac/historial-compras'}`}></i>
                                        <span className="nav-link-text">Historial de compras</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="historial-inscripcion">
                                        <i className={`fa fa-bookmark ${location.pathname === '/sac/historial-inscripcion'}`}></i>
                                        <span className="nav-link-text">Historial de inscripcion</span>
                                    </NavLink>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="main-content" id="panel">
                <ProfileNavbar sac={true} />
                <HeroHelp />
                <Outlet />
            </div>
        </div>
    )
}

export default SidebarSac
