import React from 'react'
import QuestionTree from './questionTree'

const HelpPrime = () => {
  return (
    <>
      <div className="header pb-6">
        <div className="container-fluid">
          <div className="header-body">
            <div className="row align-items-center py-4">
              <div className="col text-center">
                <h1 className="h2 text-white d-inline-block mb-0">
                  <i className="fa fa-headset"></i> Ayuda Prime
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt--5">
        <div className="row justify-content-center ">
          <div className="col-10">
            <div className="card mt-3">
              <div className="card-header">
                <h2 className="mb-0">¿En qué podemos ayudarte?</h2>
              </div>
              <div className="card-body">
                <QuestionTree parentId={0} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HelpPrime
