import React from 'react'
import { Navigate } from 'react-router-dom'
import useLogin from '../../redux/hooks/useLogin'

const Public = ({ children }) => {
  const { user, token, sessionType } = useLogin()
  if (user && [0, 7, 6].includes(user.status) && sessionType === 'USER')
    return <Navigate to="/auth/register-save" state={{ data: user }} />
  else if (token && sessionType === 'USER') return <Navigate to="/perfil/inicio" />

  return children
}

export default Public
