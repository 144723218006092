import React from 'react'
import { Navigate } from 'react-router-dom'
import useLogin from '../../redux/hooks/useLogin'

const PublicSac = ({ children }) => {
  const { token, sessionType } = useLogin()
  if (token && sessionType === 'SAC') return <Navigate to="/sac/inicio" />

  return children
}

export default PublicSac
