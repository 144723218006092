import axios from 'axios'
import { logoutAction } from '../../redux/actions/LoginActions'
import { AppDispatch } from '../../redux/utils/AppDispatch'
import config from './config'

const API = axios.create({
  baseURL: config.api_url,
  headers: config.headers,
  timeout: 1500000,
})

export const setAxiosToken = (token, sessionType) => {
  localStorage.setItem('auth_token', token)
  localStorage.setItem('session_type', sessionType)
}

export const removeAxiosToken = () => {
  localStorage.removeItem('auth_token')
  localStorage.removeItem('session_type')
}

// For all requests
API.interceptors.request.use((req) => {
  const localToken = localStorage.getItem('auth_token')
  if (localToken) {
    req.headers.common.Authorization = `Bearer ${localToken}`
  }
  return req
})

// For all responses
API.interceptors.response.use(
  (res) => {
    console.log('interceptor response', res.config.url, res.data)
    return res
  },
  (err) => {
    console.log('interceptor error', err.response)
    if (err.response.status === 401) {
      AppDispatch(logoutAction())
    }
    return Promise.reject(err)
  }
)

export default API
