import React, { useState } from 'react'

import { useRegister } from './hooks'
import Loading from '../../../components/Commons/Loading/Loading'
import Spinner from '../../../components/Commons/Spinner/Spinner'

import PersonalInfoStep from './PersonalInfoStep'
import AddressStep from './AddressStep'
import SecurityStep from './SecurityStep'
import HeaderStep from './HeaderStep'
import StepWizard from 'react-step-wizard'
import { useEffect } from 'react'
import { hotjar, maze } from '../../../utils/hotjar'

const FormRegister = (props) => {
  const { registerUser } = props
  const { isLoading, oldValues } = useRegister()
  const [step, setStep] = useState(1)

  useEffect(() => {
    console.log("Hotjaar");
    hotjar();
    maze();
  }, [])

  return (
    <>
      <HeaderStep step={step}></HeaderStep>
      <StepWizard>
        <PersonalInfoStep setStep={setStep} registerUser={registerUser} />
        <AddressStep setStep={setStep} registerUser={registerUser} />
        <SecurityStep setStep={setStep} registerUser={registerUser} />
      </StepWizard>

      <Spinner visible={isLoading && oldValues.active} />
      <Loading visible={isLoading && !oldValues.active} />
    </>
  )
}

export default React.memo(FormRegister)
