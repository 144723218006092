import React, { useRef } from "react";
import IFCondition from "../../../components/Commons/IFCondition/IFCondition";
import Loading from "../../../components/Commons/Loading/Loading";
import {
  daysDate,
  monthsDate,
  valideDayAndMonth,
  yearsDate,
} from "../../../utils/dateForm";
import { useModalChange } from "./hooks";

const ModalChangeProfile = () => {
  const btnModal = useRef();
  const { formik, errorMessage, isLoading, from, fromLoading } = useModalChange(btnModal);
  return (
    <>
      <div
        className="modal fade"
        id="change-info"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true" >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Cambiar mis datos
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close" >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-body">
                <div className="row">
                  <IFCondition cond={errorMessage && !isLoading && from === "update"}>
                    <div className="col-md-12">
                      <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert" >
                        <span className="alert-icon">
                          <i className="fa fa-check-circle "></i>
                        </span>
                        <span className="alert-text">{errorMessage}</span>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close" >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                    </div>
                  </IFCondition>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="">Número de Identificación</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        id="dni"
                        disabled
                        name="dni"
                        onChange={formik.handleChange}
                        value={formik.values.dni}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Nombre</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        id="first_name"
                        name="first_name"
                        onChange={formik.handleChange}
                        value={formik.values.first_name}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Apellido</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        id="last_name"
                        name="last_name"
                        onChange={formik.handleChange}
                        value={formik.values.last_name}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        disabled
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Teléfono</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-text">+51</div>
                        </div>
                        <input
                          className="form-control string tel optional"
                          placeholder="Ejemplo: 311 311 3131"
                          type="tel"
                          id="phone"
                          name="phone"
                          onChange={formik.handleChange}
                          value={formik.values.phone}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="">Dirección</label>
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        name="address"
                        onChange={formik.handleChange}
                        value={formik.values.address}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="">Fecha de nacimiento</label>
                      <div className="d-flex flex-row justify-content-between align-items-center">
                        <select
                          id="day"
                          name="bday.day"
                          onChange={formik.handleChange}
                          value={formik.values.bday.day}
                          className="form-control mx-1 date optional" >
                          <option value="">-</option>
                          {daysDate().map((e, key) => {
                            return (
                              <option key={key} value={e}>
                                {e}
                              </option>
                            );
                          })}
                        </select>
                        <select
                          id="month"
                          name="bday.month"
                          onChange={formik.handleChange}
                          value={formik.values.bday.month}
                          className="form-control mx-1 date optional" >
                          <option value="">-</option>
                          {monthsDate().map((e, key) => {
                            return (
                              <option key={key} value={valideDayAndMonth(key+1)}>
                                {e}
                              </option>
                            );
                          })}
                        </select>
                        <select
                          id="year"
                          name="bday.year"
                          onChange={formik.handleChange}
                          value={formik.values.bday.year}
                          className="form-control mx-1 date optional"  >
                          <option value="">-</option>
                          {yearsDate().map((e, key) => {
                            return (
                              <option key={key} value={e}>
                                {e}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="" className="d-block">
                        Género
                      </label>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="gender"
                          onChange={formik.handleChange}
                          checked={formik.values.gender === "1"}
                          value="1"
                          id="inlineRadio1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                        >
                          Femenino
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="gender"
                          onChange={formik.handleChange}
                          checked={formik.values.gender === "2"}
                          value="2"
                          id="inlineRadio2"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio2" >
                          Masculino
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="gender"
                          onChange={formik.handleChange}
                          checked={formik.values.gender === "0"}
                          value="0"
                          id="inlineRadio3"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio3" >
                          Otro
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  ref={btnModal}
                  data-dismiss="modal" >
                  Cancelar
                </button>
                <button type="submit" className="btn btn-primary">
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Loading visible={isLoading && fromLoading === "update"} />
    </>
  );
};

export default ModalChangeProfile;
