export const REGISTER_NIUBIZ_LOADING = 'register/REGISTER_NIUBIZ_LOADING'
export const REGISTER_NIUBIZ_SUCCESS = 'register/REGISTER_NIUBIZ_SUCCESS'
/*export const REGISTER_PAYU_WARNING = "register/REGISTER_PAYU_WARNING";
export const REGISTER_PAYU_BLOCKED = "register/REGISTER_PAYU_BLOCKED";
export const REGISTER_PAYU_UNBLOCK = "register/REGISTER_PAYU_UNBLOCK";*/
export const REGISTER_NIUBIZ_FAIL = 'register/REGISTER_NIUBIZ_FAIL'
//export const REGISTER_NIUBIZ_ENABLEU_USER = "register/REGISTER_NIUBIZ_ENABLEU_USER";

const RegisterPayUReducers = (state = {}, action) => {
  switch (action.type) {
    case REGISTER_NIUBIZ_LOADING:
      return {
        isLoading: true,
      }
    case REGISTER_NIUBIZ_SUCCESS:
      return {
        isLoading: false,
        blocked: false,
        errorMessage: null,
        payData: action.payload.data,
      }
    case REGISTER_NIUBIZ_FAIL:
      return {
        ...state,
        isLoading: false,
        blocked: false,
        errorMessage: action.payload.error,
      }
    default:
      return state
  }
}

export default RegisterPayUReducers
