import React from 'react'

const DatosClientes = ({ data: info, sacUser }) => {
  const { data } = info
  const { user } = data.user_detail

  return (
    <>
      <div className="col-lg-12">
        <div className="card mb-5 shadow-sm">
          <div className="card-header">
            <p className="float-left display-5 mb-0">
              <i className="fa fa-user-circle"></i> Datos Cliente
            </p>
          </div>
          <table className="table">
            <tbody>
              <tr>
                <th>Nombre</th>
                <td>{user?.fullname}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>
                  {user?.email}{' '}
                  {sacUser?.permissions?.update_email ? (
                    <a href="/" className="f14" data-toggle="modal" data-target="#change-email">
                      <i className="fa fa-edit edit-icon-sac"></i>
                    </a>
                  ) : (
                    ''
                  )}
                </td>
              </tr>

              <tr>
                <th>Teléfono</th>
                <td colSpan="2">{user?.phone}</td>
              </tr>
              <tr>
                <th>Tipo de documento</th>
                <td colSpan="2">
                  {user?.dniType}{' '}
                  {sacUser?.permissions?.update_dni_type ? (
                    <a href="/" className="f14" data-toggle="modal" data-target="#change-dni-type">
                      <i className="fa fa-edit edit-icon-sac"></i>
                    </a>
                  ) : (
                    ''
                  )}
                </td>
              </tr>
              <tr>
                <th>Número de documento</th>
                <td colSpan="2">
                  {user?.dni}{' '}
                  {sacUser?.permissions?.update_document_number ? (
                    <a href="/" className="f14" data-toggle="modal" data-target="#change-document">
                      <i className="fa fa-edit edit-icon-sac"></i>
                    </a>
                  ) : (
                    ''
                  )}
                </td>
              </tr>
              <tr>
                <th>Estado</th>
                <td colSpan="2">
                  <ul className="list-unstyled mb-0">
                    <li>
                      <span className="badge badge-dot mr-4">
                        <i className={user?.statusBadge}></i>
                        <span className="status">{user?.statusDescription}</span>
                      </span>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th>Fecha de inscripción</th>
                <td>{user?.subscriptionDate}</td>
              </tr>
              <tr>
                <th>Fecha inicio beneficio</th>
                <td>{user?.benefitStartDate}</td>
              </tr>
              {user?.observation && (
                <tr>
                  <th>Observaciones</th>
                  <td colSpan="2">
                    <span className="text-y-prime">{user?.observation}</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default DatosClientes
