import React, { useEffect, useRef, useState } from "react";
import IFCondition from "../../components/Commons/IFCondition/IFCondition";
import { GOOGLE_CAPTCHA_KEY } from "../../utils/constants";
import ReCAPTCHA from "react-google-recaptcha";
import {toast} from 'react-toastify'
import { Formik, Field, Form } from "formik";
import { initialDocumentTypeValues, handleDocumentTypeValidation } from "./constants";
import { useGetParameters, useSacSensitive } from "./hooks";
import Loading from '../../components/Commons/Loading/Loading'
import Spinner from '../../components/Commons/Spinner/Spinner'

const ModalChangeDocumentType = ({data}) => {
  const {handleChangeData, loading} = useSacSensitive();
  const recaptchaRef = React.createRef()
  const [oldDocumentValue, setOldDocumentValue] = useState('')
  const [documentValue, setDocumentValue] = useState('')
  const [oldDocumentType, setOldDocumentType] = useState('')
  const { user } = data.data.user_detail
  const btnModal = useRef();
  const { parameters } = useGetParameters()

  const handleChangeDocumentType = (value)=>{
    handleChangeData(value); 
    window.$('#change-dni-type').modal('hide');
  }

  const getDocumentType = (dniType)=>{
    if(parameters.dniTypes.length){
      const documentType = parameters.dniTypes.find(dni => dni.description === dniType)
      return documentType?.code;
    }
  }

  useEffect(()=>{
    if(user){
      const value = getDocumentType(user.dniType);
      setDocumentValue(user.dni)
      setOldDocumentValue(user.dni)
      setOldDocumentType(value)
    }
  }, [user, parameters])
  return (
    <>
      <div
      className="modal fade"
      id="change-dni-type"
      tabIndex="-1"
      role="dialog"
      aria-labelledby=""
      aria-hidden="true" >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
              <h2>
              Modificación de tipo de documento
            </h2>
          </div>
          <Formik
            className="mt-4"
            initialValues={initialDocumentTypeValues}
            validationSchema={handleDocumentTypeValidation}
            onSubmit={handleChangeDocumentType} >
              {({ values, errors, touched, isValid, dirty, setFieldValue }) => (
                <Form className="form">
            <div className="modal-body">
              <div className="form-group">
                <label>Documento actual: </label>{" "}
                <label><strong>{user?.dniType}</strong></label>
              </div>
              
              <div className="form-group">
              <label htmlFor="name" className="control-label">
                Tipo identificación
              </label>
                <div className="input-group">
                <Field
                  as='select' 
                  name="newValue" 
                  id="newValue" 
                  onChange={(e) => {
                    setFieldValue('newValue', e.target.value)
                    setFieldValue('oldValue', oldDocumentType)
                    setFieldValue('old_document', oldDocumentValue)
                    setFieldValue('dni', documentValue)
                    setFieldValue('updateType', 3)
                  }}
                  onFocus={() => {
                    setFieldValue('old_document', oldDocumentValue)
                    setFieldValue('oldValue', oldDocumentType)
                    setFieldValue('dni', documentValue)
                    setFieldValue('updateType', 3)
                  }}
                  className="form-control mx-1"
                  >
                  {parameters.dniTypes.map((dniType, index) => {
                    return (
                        <option key={index} value={dniType.code}>{dniType.description}</option>
                    )
                  })}
                </Field>
                <Field
                  type="hidden"
                  id="old_document"
                  name="old_document"
                  value={oldDocumentValue}
                  onSubmit={() => {
                    setFieldValue('old_document', oldDocumentValue)
                  }}
                />
                <Field
                  type="hidden"
                  id="oldValue"
                  name="oldValue"
                  value={oldDocumentType}
                  onSubmit={() => {
                    setFieldValue('oldValue', oldDocumentType)
                  }}
                />
                </div>
              </div>
              <div className="form-group">
                {/* <label htmlFor="dni">Número de documento</label> */}
                <Field
                  type="hidden"
                  className={`form-control ${
                    errors.dni && touched.dni ? 'is-invalid' : ''
                  }`}
                  id="dni"
                  name="dni"
                  required
                  value={documentValue}
                  onChange={(e) => {
                    setFieldValue('dni', e.target.value)
                    setDocumentValue(e.target.value)
                  }}
                  onFocus={()=>{
                    setFieldValue('old_document', oldDocumentValue)
                  }}
                />
                {errors.dni && touched.dni ? (
                  <div className="text-danger">{errors.dni}</div>
                ) : null}
              </div>
              <div className="form-group">
                <Field
                as='textarea'
                  rows="6"
                  className="form-control"
                  id="observation"
                  name="observation"
                  required
                  placeholder="Dejanos saber los motivos por los cuales solicitas esta modificación."
                  // onChange={formik.handleChange}
                  // value={formik.values.confirmPassword}
                />
                {errors.observation && touched.observation ? (
                  <div className="text-danger">{errors.observation}</div>
                ) : null}
              </div>
              <IFCondition cond={process.env.REACT_APP_ENV !== 'local'}>
                <ReCAPTCHA
                sitekey={GOOGLE_CAPTCHA_KEY}
                ref={recaptchaRef}
                name="reCaptcha"
                onChange={(value) => {
                  setFieldValue('reCaptcha', value)
                }}
              /> 
            </IFCondition>
             <IFCondition cond={errors.reCaptcha && touched.reCaptcha}>
              <div className="text-danger">{errors.reCaptcha}</div>
            </IFCondition>
            </div>
            {/* <IFCondition cond={errorMessage}>
              <div className="alert alert-warning">{errorMessage}</div>
            </IFCondition> */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-secondary"
                ref={btnModal}
                data-dismiss="modal">
                Cancelar
              </button>
              
              <button
                type="submit"
                className={`btn btn-primary`}
                disabled={!(isValid && dirty)}
                >
                Continuar
              </button>
            </div>
          </Form>
              )}
          </Formik>
        </div>
      </div>
    </div>
    <Spinner visible={loading} />
    <Loading visible={loading} />
    </>
  );
};

export default ModalChangeDocumentType;
