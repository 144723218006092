import React, { useRef, useEffect, useState } from "react";
import IFCondition from "../../components/Commons/IFCondition/IFCondition";
import { GOOGLE_CAPTCHA_KEY } from "../../utils/constants";
import ReCAPTCHA from "react-google-recaptcha";
import { Formik, Field, Form } from "formik";
import { initialEmailValues, handleEmailChangeValidation } from "./constants";
import {useSacSensitive} from './hooks'
import Loading from '../../components/Commons/Loading/Loading'
import Spinner from '../../components/Commons/Spinner/Spinner'

const ModalChangeEmail = ({data}) => {
  const {handleChangeData, loading} = useSacSensitive();
  const { user } = data.data.user_detail
  const [oldEmail, setOldEmail] = useState('')
  const btnModal = useRef();
  const recaptchaRef = React.createRef()

  const handleChangeDocumentType = (value)=>{
    handleChangeData(value); 
    window.$('#change-email').modal('hide');
  }

  useEffect(()=>{
    if(user){
      setOldEmail(user.email)
    }
  }, [user])

  return (
    <>
    <div
      className="modal fade"
      id="change-email"
      tabIndex="-1"
      role="dialog"
      aria-labelledby=""
      aria-hidden="true" >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2>
              Modificación de email
            </h2>
          </div>
          <Formik
            className="mt-4"
            initialValues={initialEmailValues}
            validationSchema={handleEmailChangeValidation}
            onSubmit={handleChangeDocumentType} >
              {({ values, errors, touched, isValid, dirty, setFieldValue }) => (
          <Form className="form">
            <div className="modal-body">
              <div className="form-group">
                <label>Email actual: </label>{" "}
                <label><strong>{user?.email}</strong></label>
              </div>

              <Field
                  type="hidden"
                  id="oldValue"
                  name="oldValue"
                  value={oldEmail}
                  onSubmit={() => {
                    setFieldValue('oldValue', oldEmail)
                  }}
                />
                <Field
                  type="hidden"
                  id="updateType"
                  name="updateType"
                  value={2}
                  onSubmit={() => {
                    setFieldValue('updateType', 1)
                  }}
                />

              <div className="form-group">
                <label htmlFor="dni">Nuevo email</label>
                <Field
                  type="text"
                  className={`form-control ${
                    errors.dni && touched.dni ? 'is-invalid' : ''
                  }`}
                  id="newValue"
                  name="newValue"
                  required
                  onFocus={() => {
                    setFieldValue('oldValue', oldEmail)
                    setFieldValue('updateType', 1)
                  }}
                />
                {errors.newValue && touched.newValue ? (
                  <div className="text-danger">{errors.newValue}</div>
                ) : null}
              </div>
              <div className="form-group">
                <Field
                as='textarea'
                  rows="6"
                  className="form-control"
                  id="observation"
                  name="observation"
                  required
                  placeholder="Dejanos saber los motivos por los cuales solicitas esta modificación."
                  // onChange={formik.handleChange}
                  // value={formik.values.confirmPassword}
                />
                {errors.observation && touched.observation ? (
                  <div className="text-danger">{errors.observation}</div>
                ) : null}
              </div>
            <IFCondition cond={process.env.REACT_APP_ENV !== 'local'}>
                <ReCAPTCHA
                sitekey={GOOGLE_CAPTCHA_KEY}
                ref={recaptchaRef}
                name="reCaptcha"
                onChange={(value) => {
                  setFieldValue('reCaptcha', value)
                }}
              /> 
            </IFCondition>
             <IFCondition cond={errors.reCaptcha && touched.reCaptcha}>
              <div className="text-danger">{errors.reCaptcha}</div>
            </IFCondition>
            </div>
            {/* <IFCondition cond={errorMessage}>
              <div className="alert alert-warning">{errorMessage}</div>
            </IFCondition> */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-secondary"
                ref={btnModal}
                data-dismiss="modal">
                Cancelar
              </button>
             
              <button
                type="submit"
                className={`btn btn-primary`}
                disabled={!(isValid && dirty)}
                >
                Continuar
              </button>
            </div>
          </Form>
              )}
          </Formik>
        </div>
      </div>
    </div>
    <Spinner visible={loading} />
    <Loading visible={loading} />
    </>
  );
};

export default ModalChangeEmail;
