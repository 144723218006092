import React from 'react'

const Benefits = () => {
  return (
    <>
      <div className="header pb-6">
        <div className="container-fluid">
          <div className="header-body">
            <div className="row align-items-center py-4">
              <div className="col text-center">
                <h1 className="display-3 text-white d-inline-block mb-0"> Mis beneficios</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt--5">
        <div className="row justify-content-md-center">
          <div className=" col-lg-10 col-xl-8">
            <div className="card no-shadow border">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <div className="media h-100">
                    <i
                      className="fa fa-bookmark align-self-center mr-4 text-primary d-block"
                      style={{ fontSize: '30px' }}></i>
                    <div className="media-body">
                      <h4 className="mt-0">
                        <span className="badge badge-primary float-right">Activo</span>
                      </h4>

                      <p>Delivery gratis e ilimitados en compras desde S/ 79.90 en supermercado.</p>
                    </div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="media h-100">
                    <i
                      className="fa fa-bookmark align-self-center mr-4 text-primary d-block"
                      style={{ fontSize: '30px' }}></i>
                    <div className="media-body">
                      <h4 className="mt-0">
                        <span className="badge badge-primary float-right">Activo</span>
                      </h4>

                      <p>Ofertas exclusivas en Wong.pe y la App Wong.</p>
                    </div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="media h-100">
                    <i
                      className="fa fa-bookmark align-self-center mr-4 text-primary d-block"
                      style={{ fontSize: '30px' }}></i>
                    <div className="media-body">
                      <h4 className="mt-0">
                        <span className="badge badge-primary float-right">Activo</span>
                      </h4>

                      <p>
                        Dos deliverys gratis por mes en electro, tecnología, muebles y colchones por
                        compras desde S/ 299
                      </p>
                    </div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="media h-100">
                    <i
                      className="fa fa-bookmark align-self-center mr-4 text-primary d-block"
                      style={{ fontSize: '30px' }}></i>
                    <div className="media-body">
                      <h4 className="mt-0">
                        <span className="badge badge-primary float-right">Activo</span>
                      </h4>

                      <p>Descuentos en combustibles en Primax</p>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="media h-100">
                    <i
                      className="fa fa-bookmark align-self-center mr-4 text-primary d-block"
                      style={{ fontSize: '30px' }}></i>
                    <div className="media-body">
                      <h4 className="mt-0">
                        <span className="badge badge-primary float-right">Activo</span>
                      </h4>

                      <p>Doble acumulación de puntos Bonus en Wong.pe y la App Wong.</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Benefits
