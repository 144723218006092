import React, { useEffect, useState } from 'react'
import { Formik, Field, Form } from 'formik'
import { Link } from 'react-router-dom'
import { initialValues, handleValidation } from './constants'
import ReCAPTCHA from 'react-google-recaptcha'
import { GOOGLE_CAPTCHA_KEY } from '../../utils/constants'
import { useRegisterPromotion } from './hooks'
import Spinner from '../../components/Commons/Spinner/Spinner'
import Loading from '../../components/Commons/Loading/Loading'

import IFCondition from '../../components/Commons/IFCondition/IFCondition'

const PromotionForm = ({ registerUser }) => {
  const [touchAddress, setTouchAddress] = useState(false)
  const [showPass, setShowPass] = useState({
    pass: false,
    confirmPass: false,
  })
  const recaptchaRef = React.createRef()
  const { isLoading, errorMessage, parameters, searchCities, searchDistricts } =
    useRegisterPromotion()

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={handleValidation}
        onSubmit={registerUser}>
        {({ errors, touched, isValid, dirty, values, setFieldValue }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="name" className="control-label">
                Tipo identificación
              </label>
              <div className="doc_type" style={{ display: 'flex', justifyContent: 'start' }}>
                {parameters.dniTypes.map((dniType, index) => {
                  return (
                    <div key={index} style={{ marginLeft: '15px' }}>
                      <Field type="radio" name="dni_type" value={dniType.code} />
                      <label style={{ marginLeft: '5px' }}>{dniType.description}</label>
                    </div>
                  )
                })}
              </div>
            </div>


            <div className="form-group">
              <label htmlFor="dni" className="control-label">
                Documento
              </label>
              <Field type="text" className="form-control" name="dni" placeholder="Ingrese su dni" />
              {errors.dni && touched.dni ? <div className="text-danger">{errors.dni}</div> : null}
            </div>

            
            <div className="form-group">
              <label htmlFor="mail" className="control-label">
                E-mail
              </label>
              <Field
                type="text"
                className="form-control"
                name="email"
                placeholder="Ingrese su mail"
              />
              {errors.email && touched.email ? (
                <div className="text-danger">{errors.email}</div>
              ) : null}
            </div>

            <div className="form-group">
              <div className="form-group optional">
                <label className="date optional" htmlFor="gender">
                  Género
                </label>
                <Field as="select" id="gender" name="gender" className="form-control mx-1">
                  <option value="">-</option>
                  {parameters.genders.map((gender, index) => {
                    return (
                      <option key={index} value={gender.code}>
                        {gender.description}
                      </option>
                    )
                  })}
                </Field>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="address" className="control-label">
                Dirección
              </label>
              <Field
                name="address"
                className="form-control"
                onBlur={(e) =>
                  e.target.value.length !== 0 ? setTouchAddress(false) : setTouchAddress(true)
                }></Field>
              {errors.address && touchAddress ? (
                <div className="text-danger">{errors.address}</div>
              ) : null}
            </div>

            <div className="form-group">
              <label className="string optional" htmlFor="department">
                Departamento
              </label>
              <div className="input-group mb-2">
                <Field
                  as="select"
                  id="department"
                  name="department"
                  onChange={(e) => {
                    setFieldValue('department', e.target.value)
                    searchCities(e.target.value)
                  }}
                  className="form-control mx-1">
                  <option value="">-</option>
                  {parameters.departments.map((department, index) => {
                    return (
                      <option key={index} value={department.code}>
                        {department.description}
                      </option>
                    )
                  })}
                </Field>
                <br />
              </div>
              {errors.department && touched.department ? (
                <div className="text-danger">{errors.department}</div>
              ) : null}
            </div>

            <div className="form-group">
              <label className="string optional" htmlFor="city">
                Provincia
              </label>
              <div className="input-group mb-2">
                <Field
                  as="select"
                  id="city"
                  name="city"
                  onChange={(e) => {
                    setFieldValue('city', e.target.value)
                    searchDistricts(e.target.value)
                  }}
                  className="form-control mx-1">
                  <option value="">-</option>
                  {parameters.cities.map((city, index) => {
                    return (
                      <option key={index} value={city.code}>
                        {city.description}
                      </option>
                    )
                  })}
                </Field>
                <br />
              </div>
              {errors.city && touched.city ? (
                <div className="text-danger">{errors.city}</div>
              ) : null}
            </div>

            <div className="form-group">
              <label className="string optional" htmlFor="district">
                Distritos
              </label>
              <div className="input-group mb-2">
                <Field as="select" id="district" name="district" className="form-control mx-1">
                  <option value="">-</option>
                  {parameters.districts.map((district, index) => {
                    return (
                      <option key={index} value={district.code}>
                        {district.description}
                      </option>
                    )
                  })}
                </Field>
                <br />
              </div>
              {errors.district && touched.district ? (
                <div className="text-danger">{errors.district}</div>
              ) : null}
            </div>

            <div className="form-group">
              <label className="password optional" htmlFor="promotion_password">
                <i className="fa fa-key"></i> Contraseña
              </label>
              <div className="input-group mb-2">
                <Field
                  className="form-control"
                  type={!showPass.pass ? 'password' : 'text'}
                  name="password"
                  id="promotion_password"
                  autoComplete="false"
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-outline-secondary"
                    onClick={() => setShowPass({ ...showPass, pass: !showPass.pass })}
                    type="button">
                    {!showPass.pass ? (
                      <i className="fa fa-eye" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-eye-slash" aria-hidden="true"></i>
                    )}
                  </button>
                </div>
              </div>
              {errors.password && touched.password ? (
                <div className="text-danger">{errors.password}</div>
              ) : null}
              <small className="form-text text-muted">
                Tu contraseña debe tener entre 8 y 12 caracteres
              </small>
            </div>

            <div className="form-group">
              <div className="form-group password optional promotion_password_confirmation">
                <label className="password optional" htmlFor="promotion_password_confirmation">
                  <i className="fa fa-key"></i> Confirmar contraseña
                </label>
                <div className="input-group mb-2">
                  <Field
                    className="form-control"
                    type={!showPass.confirmPass ? 'password' : 'text'}
                    name="password_confirmation"
                    id="promotion_password_confirmation"
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      onClick={() =>
                        setShowPass({
                          ...showPass,
                          confirmPass: !showPass.confirmPass,
                        })
                      }
                      type="button">
                      {!showPass.confirmPass ? (
                        <i className="fa fa-eye" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-eye-slash" aria-hidden="true"></i>
                      )}
                    </button>
                  </div>
                </div>
                <small className="form-text text-muted"></small>
              </div>
              {errors.password_confirmation && touched.password_confirmation ? (
                <div className="text-danger">{errors.password_confirmation}</div>
              ) : null}
            </div>

            <div className="form-check text-dark mb-2 pl-0">
              <div className="form-group boolean optional user_accepts">
                <div className="form-check">
                  <Field
                    className={`form-check-input boolean`}
                    type="checkbox"
                    name="accepts"
                    id="user_accepts"
                  />

                  <label className="form-check-label boolean optional" htmlFor="user_accepts">
                    Declaro haber leído y aceptar los{' '}
                    <a href="/terminos-y-condiciones" target="_blank" rel="noopener noreferrer">
                      Términos y Condiciones
                    </a>{' '}
                    y las
                    <a href="/politicas-y-privacidad" target="_blank" rel="noopener noreferrer">
                      {' '}
                      Políticas de Privacidad y Datos Personales
                    </a>{' '}
                    del programa Wong Prime
                  </label>
                  {errors.accepts && touched.accepts ? (
                    <div className="text-danger">{errors.accepts}</div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="form-check text-dark mb-2 pl-0">
              <div className="form-group boolean optional user_accepts_policies">
                <div className="form-check">
                  <Field
                    className={`form-check-input boolean`}
                    type="checkbox"
                    name="accepts_policies"
                    id="user_accepts_policies"
                  />

                  <label
                    className="form-check-label boolean optional"
                    htmlFor="user_accepts_policies">
                    Acepto el tratamiento de mis datos para
                    <a
                      href="https://www.wong.pe/institucional/tratamientos-para-fines-adicionales"
                      target="_blank"
                      rel="noopener noreferrer">
                      {' '}
                      Finalidades Adicionales
                    </a>
                  </label>
                  {errors.accepts_policies && touched.accepts_policies ? (
                    <div className="text-danger">{errors.accepts_policies}</div>
                  ) : null}
                </div>
              </div>
            </div>

            <IFCondition cond={process.env.NODE_ENV === 'production'}>
              <hr />
              <ReCAPTCHA
                sitekey={GOOGLE_CAPTCHA_KEY}
                ref={recaptchaRef}
                name="reCaptcha"
                onChange={(value) => {
                  setFieldValue('reCaptcha', value)
                }}
              />
              <IFCondition cond={errors.reCaptcha && touched.reCaptcha}>
                <div className="text-danger">{errors.reCaptcha}</div>
              </IFCondition>
            </IFCondition>
            <IFCondition cond={errorMessage}>
              <div className="alert alert-danger alert-dismissible fade show mt-4" role="alert">
                <strong>
                  <i className="fa fa-exclamation-circle"></i> Lo sentimos
                </strong>{' '}
                <br />
                {errorMessage}
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </IFCondition>
            <div className="botones text-right">
              <Link to="/" className="btn btn-link">
                Cancelar
              </Link>
              <button type="submit" className={`btn btn-primary`} disabled={!(isValid && dirty)}>
                Continuar
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <Spinner visible={isLoading} />
      <Loading visible={isLoading} />
    </>
  )
}

export default PromotionForm
