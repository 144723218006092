import React from 'react'
import { fullDate } from '../../../utils/dateForm'

export const CardShopping = ({ purchases }) => {
  return (
    <>
      {purchases.map((purchase, key) => {
        return (
          <div className="card card-compra" key={key}>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <div className="media">
                    <span className="img-compra">
                      <img
                        className="img-fluid"
                        alt=""
                        src={require('../../../assets/img/logos/wong.png')}
                      />
                    </span>
                    <div className="media-body">
                      <ul className="list-unstyled f14">
                        <li>
                          <strong>Número de orden:&nbsp;</strong>
                          {purchase.purchases_transaction_store}
                        </li>
                        <li>
                          <strong>Total:</strong> S/{purchase.purchases_price}
                        </li>
                        <li>
                          <strong>Unidades:</strong> {purchase.purchases_unit}
                        </li>
                        <li>
                          <strong>Comprado en:&nbsp;</strong>
                          {purchase.store_name}
                        </li>
                        <li>
                          <strong>Fecha de compra:&nbsp;</strong>
                          {fullDate(new Date(purchase.purchases_purchase_date))}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                      <span className="name mb-0 text-sm">Puntos extra acumulados:</span>
                      <ul className="list-unstyled f14">
                        {/* <li><strike>{purchase.base_points} pts</strike> </li> */}
                        {/* <li className="f18 text-primary">{purchase.extra_points} pts</li> */}
                        <li className="f18 text-primary">{purchase.purchases_base_points} pts</li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <span className="name mb-0 text-sm">Beneficios utilizados:</span>
                      <ul className="list-unstyled f14">
                        <li>
                          {purchase.purchases_shipping_use && (
                            <>
                              <span className="badge badge-default" style={{ marginRight: '5px' }}>
                                Envio gratis
                              </span>
                              <span className="badge badge-default" style={{ marginRight: '5px' }}>
                                Ahorraste S/9,90
                              </span>
                            </>
                          )}
                          {purchase.purchases_prime_discounts && (
                            <>
                              <span className="badge badge-default" style={{ marginRight: '5px' }}>
                                Ahorraste S/{Math.abs(purchase.purchases_prime_discounts)} por
                                ofertas
                              </span>
                              <span className="badge badge-default">Productos exclusivos</span>
                            </>
                          )}
                        </li>
                        {purchase.extra_points > 0 && (
                          <li>
                            <span className="badge badge-default">Puntos x2</span>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}
