import React from 'react'
import { Link } from 'react-router-dom'
import { clickGTM } from '../../services/Gtm/gtmInteractions'
const Faq = () => {
  const { handleClick } = clickGTM()
  return (
    <div className="secction-5 section_padding_200 content-frutas" id="faq-section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-heading text-center">
              <h2>Preguntas frecuentes</h2>
              <div className="line-shape"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="tab-content" id="faq-tab-content">
              <div
                className="tab-pane show active"
                id="tab1"
                role="tabpanel"
                aria-labelledby="tab1">
                <div
                  className="accordion"
                  id="accordion-tab-1"
                  onClick={(e) => {
                    handleClick(e, 'Home - Preguntas frecuentes')
                  }}>
                  <div className="card">
                    <div className="card-header" id="accordion-tab-1-heading-1">
                      <a
                        href={'/'}
                        data-toggle="collapse"
                        data-target="#accordion-tab-1-content-1"
                        aria-expanded="false"
                        aria-controls="accordion-tab-1-content-1">
                        ¿Qué es Wong Prime?
                      </a>
                    </div>
                    <div
                      className="collapse show"
                      id="accordion-tab-1-content-1"
                      aria-labelledby="accordion-tab-1-heading-1"
                      data-parent="#accordion-tab-1">
                      <div className="card-body">
                        <p>Es la membresía de Wong que te brinda beneficios exclusivos como: </p>
                        <ul style={{ listStyle: 'none' }}>
                          <li className="margen-bottom align_li_to_icon">
                            <img src={require('../../assets/img/boton3.png')} width="60" alt="" />
                            &nbsp;{' '}
                            <span>
                              Despachos gratis e ilimitados en compras de supermercado*, por compras
                              desde S/ 79.90{' '}
                            </span>
                          </li>
                          <li className="margen-bottom align_li_to_icon">
                            <img src={require('../../assets/img/boton2.png')} width="60" alt="" />
                            &nbsp; <span>Ofertas exclusivas en Wong.pe y la App Wong.</span>
                          </li>
                          <li className="margen-bottom align_li_to_icon">
                            <img src={require('../../assets/img/boton1.png')} width="60" alt="" />
                            &nbsp;{' '}
                            <span>
                              Duplicación de puntos Bonus por cada compra en Wong.pe y la App Wong.
                            </span>
                          </li>
                          <li className="margen-bottom align_li_to_icon">
                            <img src={require('../../assets/img/boton6.png')} width="60" alt="" />
                            &nbsp;{' '}
                            <span>
                              Dos despachos al mes gratuitos en electro, tecnología, muebles y
                              colchones, por compras mayores a 299 soles.
                            </span>
                          </li>
                          <li className="margen-bottom align_li_to_icon">
                            <img src={require('../../assets/img/boton5.png')} width="60" alt="" />
                            &nbsp; <span>Descuento en combustible en Primax.</span>
                            <img
                              src={require('../../assets/img/primax-transparente.png')}
                              width="150"
                              alt=""
                            />
                          </li>
                          <li className="margen-bottom align_li_to_icon">
                            <img src={require('../../assets/img/boton4.png')} width="60" alt="" />
                            &nbsp;{' '}
                            <span>
                              Opción exclusiva de Wong Prime en nuestros canales de atención.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="accordion-tab-1-heading-2">
                      <a
                        href={'/'}
                        type="button"
                        data-toggle="collapse"
                        data-target="#accordion-tab-1-content-2"
                        aria-expanded="false"
                        aria-controls="accordion-tab-1-content-2">
                        ¿Quiénes pueden ser parte de la membresía Wong Prime?
                      </a>
                    </div>

                    <div
                      className="collapse"
                      id="accordion-tab-1-content-2"
                      aria-labelledby="accordion-tab-1-heading-2"
                      data-parent="#accordion-tab-1">
                      <div className="card-body">
                        <p>
                          Todas las personas naturales, mayores de edad con Documento de identidad
                          (DNI) o Carné de Extranjería.{' '}
                          <Link className="submit" to="terminos-y-condiciones">
                            {' '}
                            Términos y condiciones.
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="accordion-tab-1-heading-4">
                      <a
                        href={'/'}
                        data-toggle="collapse"
                        data-target="#accordion-tab-1-content-4"
                        aria-expanded="false"
                        aria-controls="accordion-tab-1-content-4">
                        ¿Existen zonas de cobertura para los envíos?
                      </a>
                    </div>
                    <div
                      className="collapse"
                      id="accordion-tab-1-content-4"
                      aria-labelledby="accordion-tab-1-heading-4"
                      data-parent="#accordion-tab-1">
                      <div className="card-body">
                        <p>
                          Suscribiéndote a Wong Prime vas a contar con toda la cobertura Wong.pe
                          (Hacemos envíos a todo Lima Metropolitana, Chosica, Chaclacayo,
                          Cieneguilla, Pachacamac, Trujillo y Playas en temporada (desde Ancón hasta
                          Cerro Azul).
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="accordion-tab-1-heading-5">
                      <a
                        href={'/'}
                        data-toggle="collapse"
                        data-target="#accordion-tab-1-content-5"
                        aria-expanded="false"
                        aria-controls="accordion-tab-1-content-5">
                        ¿Qué beneficios obtengo?
                      </a>
                    </div>
                    <div
                      className="collapse"
                      id="accordion-tab-1-content-5"
                      aria-labelledby="accordion-tab-1-heading-5"
                      data-parent="#accordion-tab-1">
                      <div className="card-body">
                        <p>
                          Tendrás envíos gratis en tus compras de supermercado* por el monto mínimo
                          de s./ 79.90, adicional recibes dos despachos al mes gratuitos en electro,
                          tecnología, muebles y colchones, por compras mayores a 299 soles. Contarás
                          con una doble acumulación de Puntos Bonus en tus compras realizadas a
                          través del App y sitio web de Wong. Y disfrutarás de ofertas y descuentos
                          exclusivos. Además de descuentos en combustible Primax.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="accordion-tab-1-heading-6">
                      <a
                        href={'/'}
                        data-toggle="collapse"
                        data-target="#accordion-tab-1-content-6"
                        aria-expanded="false"
                        aria-controls="accordion-tab-1-content-6">
                        ¿Cuáles son los requisitos para usar mis beneficios?
                      </a>
                    </div>
                    <div
                      className="collapse"
                      id="accordion-tab-1-content-6"
                      aria-labelledby="accordion-tab-1-heading-6"
                      data-parent="#accordion-tab-1">
                      <div className="card-body">
                        <ul>
                          <li>
                            Para aplicar tus beneficios de envío gratis y doble acumulación de
                            Puntos Bonus, debes iniciar sesión con el mismo documento de identidad y
                            correo electrónico que tienes registrado en Wong Prime antes de realizar
                            tu compra en www.wong.pe (mismo documento de identidad o mismo correo
                            que se usa en Wong.pe){' '}
                          </li>
                          <li>
                            Los envíos gratis aplican en compras de supermercado por el monto mínimo
                            de s./ 79.90 y en las otras categorías es un envío gratis al mes.
                          </li>
                          <li>
                            En caso de no estar inscrito en www.wong.pe con el mismo correo
                            registrado en Wong Prime, los beneficios de envíos gratis no podrán ser
                            aplicados. Te recomendamos crear tu cuenta para tener una experiencia
                            completa con Wong Prime en www.wongprime.pe opción "Regístrate".
                          </li>
                          <li>
                            En caso de no estar inscrito en www.wong.pe con el mismo correo
                            registrado en Wong Prime, los beneficios de envíos gratis y doble
                            acumulación no podrán ser aplicados. Te recomendamos crear tu cuenta
                            para tener una experiencia completa con Wong Prime en{' '}
                            <a href="https://www.wongprime.pe" target="_blank" rel="noreferrer">
                              {' '}
                              www.wongprime.pe{' '}
                            </a>{' '}
                            opción "Regístrate".
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="accordion-tab-1-heading-7">
                      <a
                        href={'/'}
                        data-toggle="collapse"
                        data-target="#accordion-tab-1-content-7"
                        aria-expanded="false"
                        aria-controls="accordion-tab-1-content-7">
                        ¿Cómo puedo ver los beneficios que he utilizado?
                      </a>
                    </div>
                    <div
                      className="collapse"
                      id="accordion-tab-1-content-7"
                      aria-labelledby="accordion-tab-1-heading-7"
                      data-parent="#accordion-tab-1">
                      <div className="card-body">
                        <p>
                          Ingresa a 
                          <a href="https://wongprime.pe/" target="_blank" rel="noreferrer">
                            Wong Prime
                          </a>{' '}
                          e inicia sesión. En la parte superior de tu portal de usuario Prime podrás
                          visualizar el número de envíos que has utilizado y tu doble acumulación de
                          puntos.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="accordion-tab-1-heading-8">
                      <a
                        href={'/'}
                        data-toggle="collapse"
                        data-target="#accordion-tab-1-content-8"
                        aria-expanded="false"
                        aria-controls="accordion-tab-1-content-8">
                        ¿Qué canales de comunicación tengo?
                      </a>
                    </div>
                    <div
                      className="collapse"
                      id="accordion-tab-1-content-8"
                      aria-labelledby="accordion-tab-1-heading-8"
                      data-parent="#accordion-tab-1">
                      <div className="card-body">
                        <p>
                          Para cualquier consulta o solicitud puedes enviar un correo electrónico a
                          Wongprime@cencosud.com.pe y un asesor te atenderá gustosamente.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="accordion-tab-1-heading-9">
                      <a
                        href={'/'}
                        data-toggle="collapse"
                        data-target="#accordion-tab-1-content-9"
                        aria-expanded="false"
                        aria-controls="accordion-tab-1-content-9">
                        ¿Cómo se contactará conmigo el asesor de servicio?
                      </a>
                    </div>
                    <div
                      className="collapse"
                      id="accordion-tab-1-content-9"
                      aria-labelledby="accordion-tab-1-heading-9"
                      data-parent="#accordion-tab-1">
                      <div className="card-body">
                        <p>
                          Dependiendo del tipo de solicitud o reclamo, te podremos contactar
                          mediante los datos que tengas registrados en Wong Prime.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header" id="accordion-tab-1-heading-9b">
                      <a
                        href={'/'}
                        data-toggle="collapse"
                        data-target="#accordion-tab-1-content-9b"
                        aria-expanded="false"
                        aria-controls="accordion-tab-1-content-9v">
                        ¿Cómo es el proceso de pago para adquirir mi membresía y ser parte de Wong
                        Prime?
                      </a>
                    </div>
                    <div
                      className="collapse"
                      id="accordion-tab-1-content-9b"
                      aria-labelledby="accordion-tab-1-heading-9b"
                      data-parent="#accordion-tab-1">
                      <div className="card-body">
                        <p>
                          El proceso es muy sencillo, se realizará mediante un cargo a la tarjeta de
                          crédito y/o débito que ingresaste en el formulario de inscripción.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="accordion-tab-1-heading-9c">
                      <a
                        href={'/'}
                        data-toggle="collapse"
                        data-target="#accordion-tab-1-content-9c"
                        aria-expanded="false"
                        aria-controls="accordion-tab-1-content-9c">
                        ¿Qué medios de pago puedo utilizar?
                      </a>
                    </div>
                    <div
                      className="collapse"
                      id="accordion-tab-1-content-9c"
                      aria-labelledby="accordion-tab-1-heading-9c"
                      data-parent="#accordion-tab-1">
                      <div className="card-body">
                        <p>Puedes utilizar tarjeta de crédito y/o débito.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="accordion-tab-1-heading-9d">
                      <a
                        href={'/'}
                        data-toggle="collapse"
                        data-target="#accordion-tab-1-content-9d"
                        aria-expanded="false"
                        aria-controls="accordion-tab-1-content-9d">
                        ¿Cómo actualizo mi método de pago?
                      </a>
                    </div>
                    <div
                      className="collapse"
                      id="accordion-tab-1-content-9d"
                      aria-labelledby="accordion-tab-1-heading-9d"
                      data-parent="#accordion-tab-1">
                      <div className="card-body">
                        <p>
                          Ingresa a wongprime.pe y en la sección “ingresar”, ingresas tu correo y
                          contraseña, luego ingresa a la sección de “mis cuenta” y en la sección “tu
                          suscripción” vas a poner actualizar el medio de pago. Para que tu
                          suscripción siempre esté operativa debes tener una Tarjeta de crédito
                          válida ingresada.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header" id="accordion-tab-1-heading-10">
                      <a
                        href={'/'}
                        data-toggle="collapse"
                        data-target="#accordion-tab-1-content-10"
                        aria-expanded="false"
                        aria-controls="accordion-tab-1-content-10">
                        ¿Puedo cancelar mi membresía Wong Prime?
                      </a>
                    </div>
                    <div
                      className="collapse"
                      id="accordion-tab-1-content-10"
                      aria-labelledby="accordion-tab-1-heading-10"
                      data-parent="#accordion-tab-1">
                      <div className="card-body">
                        <p>
                          Sí, puedes cancelar tu suscripción al Programa Wong Prime en cualquier
                          momento, solo debes seguir los siguientes pasos:{' '}
                        </p>
                        <ol>
                          <li>Ingresar a Wongprime.pe </li>
                          <li>Ingresar a “Ingresar”</li>
                          <li>Colocar correo electrónico y contraseña</li>
                          <li>Ingresar a “Mi cuenta”</li>
                          <li>Dale clic en “Cancelar mi suscripción”</li>
                          <li>Confirmación la cancelación ingresando nuevamente la contraseña</li>
                          <li>Confirmar dando clic en el botón “desuscribir”</li>
                        </ol>
                        <p>
                          En caso no pudieras desuscribirte, puedes llamar al 6138888 opción 1 y un
                          asesor podrá guiarte en los pasos. Toma en cuenta que por motivos de
                          seguridad debes realizarlo directamente.
                        </p>
                        <p>
                          Una vez completados los pasos de la solicitud, se habrá generado la
                          cancelación de tu suscripción en un plazo máximo de 72 horas. Al correo
                          suscrito llegará un mail de confirmación de la cancelación de Wong Prime.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="accordion-tab-1-heading-11a">
                      <a
                        href={'/'}
                        data-toggle="collapse"
                        data-target="#accordion-tab-1-content-11a"
                        aria-expanded="false"
                        aria-controls="accordion-tab-1-content-11a">
                        ¿Puede Wong cancelar mi membresía Wong Prime?
                      </a>
                    </div>
                    <div
                      className="collapse"
                      id="accordion-tab-1-content-11a"
                      aria-labelledby="accordion-tab-1-heading-11a"
                      data-parent="#accordion-tab-1">
                      <div className="card-body">
                        <p>
                          Wong se reserva el derecho de rechazar o cancelar una membresía en
                          cualquier momento en caso de que detecte un incumplimiento en los términos
                          y condiciones generales de esta. Recomendamos leer los términos y
                          condiciones del programa.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="accordion-tab-1-heading-11">
                      <a
                        href={'/'}
                        data-toggle="collapse"
                        data-target="#accordion-tab-1-content-11"
                        aria-expanded="false"
                        aria-controls="accordion-tab-1-content-11">
                        ¿Puedo cancelar mi suscripción porque no me gustó el servicio y me devuelven
                        mi dinero?
                      </a>
                    </div>
                    <div
                      className="collapse"
                      id="accordion-tab-1-content-11"
                      aria-labelledby="accordion-tab-1-heading-11"
                      data-parent="#accordion-tab-1">
                      <div className="card-body">
                        <p>
                          Si tu renuncia al Programa Wong Prime es realizada durante los primeros 10
                          días desde el pago o renovación de la suscripción, y no has utilizado
                          alguno de los beneficios ofrecidos por el programa durante dicho período,
                          entonces se te devolverá el 100% del costo de la membresía. La renuncia en
                          cualquier otro momento durante la vigencia de la membresía o habiendo
                          utilizado alguno de los beneficios no dará derecho a devolución o
                          restitución del dinero; sin embargo, los beneficios seguirán vigentes
                          hasta el término del período de suscripción respectivo.
                        </p>
                        <p>
                          En caso hayas cancelado tu suscripción o renunciado al Programa Wong Prime
                          y luego desees reingresar, no tendrás “periodo gratuito”.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="card">
                    <div className="card-header" id="accordion-tab-1-heading-12">
                      <a
                        href={'/'}
                        data-toggle="collapse"
                        data-target="#accordion-tab-1-content-12"
                        aria-expanded="false"
                        aria-controls="accordion-tab-1-content-12">
                        ¿Cómo ingreso a mi cuenta Wong Prime?
                      </a>
                    </div>
                    <div
                      className="collapse"
                      id="accordion-tab-1-content-12"
                      aria-labelledby="accordion-tab-1-heading-12"
                      data-parent="#accordion-tab-1">
                      <div className="card-body">
                        <p>Para ingresar a tu cuenta sigue estos pasos: </p>
                        <ul>
                          <li>Ve a wongprime.pe</li>
                          <li>Haz clic en el botón "Ingresar"</li>
                          <li> Escribe tu correo electrónico</li>
                          <li>Ingresa tu clave personal y haz clic en el botón "Continuar" </li>
                          <li>
                            Apenas ingreses a tu portal Wong Prime, en la esquina superior izquierda
                            de la pantalla verás tu nombre y el menú de opciones
                          </li>
                          <p>
                            Listo ya ingresaste a tu cuenta. Ahí podrás actualizar tus datos,
                            conocer tus beneficios y más.
                          </p>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="accordion-tab-1-heading-13">
                      <a
                        href={'/'}
                        data-toggle="collapse"
                        data-target="#accordion-tab-1-content-13"
                        aria-expanded="false"
                        aria-controls="accordion-tab-1-content-13">
                        No puedo ingresar a mi cuenta
                      </a>
                    </div>
                    <div
                      className="collapse"
                      id="accordion-tab-1-content-13"
                      aria-labelledby="accordion-tab-1-heading-13"
                      data-parent="#accordion-tab-1">
                      <div className="card-body">
                        <p>
                          Prueba recuperando tu contraseña, ingresa a tu correo y sigue los pasos del mensaje que te llegará. No te olvides de revisar en la sección de correo no deseado o SPAM.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="accordion-tab-1-heading-14">
                      <a
                        href={'/'}
                        data-toggle="collapse"
                        data-target="#accordion-tab-1-content-14"
                        aria-expanded="false"
                        aria-controls="accordion-tab-1-content-14">
                        ¿Cómo recupero mi clave de acceso a Wong Prime en caso de olvidarla?
                      </a>
                    </div>
                    <div
                      className="collapse"
                      id="accordion-tab-1-content-14"
                      aria-labelledby="accordion-tab-1-heading-14"
                      data-parent="#accordion-tab-1">
                      <div className="card-body">
                        <p>
                          Ve a wongprime.pe y haz clic en "Ingresa". Cuando te pedimos tu clave, haz
                          clic en "¿Olvidaste tu contraseña?"
                        </p>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="card">
                    <div className="card-header" id="accordion-tab-1-heading-15">
                      <a
                        href={'/'}
                        data-toggle="collapse"
                        data-target="#accordion-tab-1-content-15"
                        aria-expanded="false"
                        aria-controls="accordion-tab-1-content-15">
                        ¿Cómo puedo cambiar el correo asociado a mi cuenta Wong Prime?
                      </a>
                    </div>
                    <div
                      className="collapse"
                      id="accordion-tab-1-content-15"
                      aria-labelledby="accordion-tab-1-heading-15"
                      data-parent="#accordion-tab-1">
                      <div className="card-body">
                        <p>
                          En el portal de usuario Prime encontrarás el Buzón de Consultas y
                          Sugerencias. Ingresa a la opción Wong Prime y envía tu solicitud, que un
                          asesor gestionará el cambio de tu correo electrónico.
                        </p>
                      </div>
                    </div>
                  </div> */}
                  <div className="card">
                    <div className="card-header" id="accordion-tab-1-heading-16">
                      <h4 style={{ color: 'white' }}>Descuento en PRIMAX</h4>
                      <a
                        href={'/'}
                        data-toggle="collapse"
                        data-target="#accordion-tab-1-content-16"
                        aria-expanded="false"
                        aria-controls="accordion-tab-1-content-16">
                        ¿En qué consiste el beneficio?
                      </a>
                    </div>
                    <div
                      className="collapse"
                      id="accordion-tab-1-content-16"
                      aria-labelledby="accordion-tab-1-heading-16"
                      data-parent="#accordion-tab-1">
                      <div className="card-body">
                        <p>
                          Al suscribirte a Wong Prime, accederás a un descuento en grifos PRIMAX, el
                          cual aplicará dependiendo del tipo de combustible y la región del país
                          donde te encuentres.{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="accordion-tab-1-heading-17">
                      <a
                        href={'/'}
                        data-toggle="collapse"
                        data-target="#accordion-tab-1-content-17"
                        aria-expanded="false"
                        aria-controls="accordion-tab-1-content-17">
                        ¿Cómo hago efectivo el descuento?
                      </a>
                    </div>
                    <div
                      className="collapse"
                      id="accordion-tab-1-content-17"
                      aria-labelledby="accordion-tab-1-heading-17"
                      data-parent="#accordion-tab-1">
                      <div className="card-body">
                        <p>
                          Luego de suscribirse a Wong Prime, deberás esperar 24 hs, ingresar a{' '}
                          <a
                            href="https://convenios.primax.com.pe/"
                            target="_blank"
                            rel="noreferrer">
                            {' '}
                            PRIMAX{' '}
                          </a>
                          donde llenarás los datos y autorizaciones con el mismo DNI y correo
                          electrónico que en Wong Prime. En el campo empresa deberás colocar Wong
                          Prime.
                        </p>
                        <p>
                          Al hacer el envío de esta información se deberá esperar 5 minutos e
                          ingresar al correo que se registró en donde encontrara un código QR que
                          deberá ser presentado en las estaciones Primax Coesti para que se aplique
                          el descuento. El QR se debe presentar antes de abastecer.
                        </p>
                        <p>Si se desease ingresar un segundo vehículo se repite el proceso.</p>
                        <p>Tabla de descuentos:</p>
                        <p>*Descuento Aplica por galón, según tipo de combustible.</p>
                        <div className="table-scroll">
                          {/* <table className="table table-borderless table-striped table-sm pl-2 text-right">
                          <thead>
                            <tr>
                              <th scope="col" style={{ float: 'left' }}>
                                ZONAS
                              </th>
                              <th scope="col" className="text-center">
                                CIUDADES
                              </th>
                              <th scope="col" className="text-center">
                                DIESEL
                              </th>
                              <th scope="col" className="text-center">
                                90
                              </th>
                              <th scope="col" className="text-center">
                                95
                              </th>
                              <th scope="col" className="text-center">
                                97
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-left pl-3 f16">CHIMBOTE I, II, ANCASH</td>
                              <td className="text-left pl-3 f16">ANCASH</td>
                              <td className="text-left pl-3 f16">0.40</td>
                              <td className="text-left pl-3 f16">0.00</td>
                              <td className="text-left pl-3 f16">0.40</td>
                              <td className="text-left pl-3 f16">0.60</td>
                            </tr>
                            <tr>
                              <td className="text-left pl-3 f16">AREQUIPA CAYLLOMA</td>
                              <td className="text-left pl-3 f16">AREQUIPA</td>
                              <td className="text-left pl-3 f16">0.00</td>
                              <td className="text-left pl-3 f16">0.20</td>
                              <td className="text-left pl-3 f16">0.70</td>
                              <td className="text-left pl-3 f16">0.60</td>
                            </tr>
                            <tr>
                              <td className="text-left pl-3 f16">CAJAMARCA</td>
                              <td className="text-left pl-3 f16">CAJAMARCA</td>
                              <td className="text-left pl-3 f16">0.40</td>
                              <td className="text-left pl-3 f16">0.00</td>
                              <td className="text-left pl-3 f16">0.00</td>
                              <td className="text-left pl-3 f16">0.00</td>
                            </tr>
                            <tr>
                              <td className="text-left pl-3 f16">
                                LIMA I, II, HUAURA, HUAURAL, BARRANCA I, II, CAÑETE I, II,
                                HUAROCHIRI
                              </td>
                              <td className="text-left pl-3 f16">LIMA-CALLAO</td>
                              <td className="text-left pl-3 f16">0.30</td>
                              <td className="text-left pl-3 f16">0.30</td>
                              <td className="text-left pl-3 f16">0.70</td>
                              <td className="text-left pl-3 f16">1.00</td>
                            </tr>
                            <tr>
                              <td className="text-left pl-3 f16">CUSCO</td>
                              <td className="text-left pl-3 f16">CUSCO</td>
                              <td className="text-left pl-3 f16">0.00</td>
                              <td className="text-left pl-3 f16">0.00</td>
                              <td className="text-left pl-3 f16">0.40</td>
                              <td className="text-left pl-3 f16">0.00</td>
                            </tr>
                            <tr>
                              <td className="text-left pl-3 f16">
                                NAZCA, ICA I, II, CHINCHA, PISCO
                              </td>
                              <td className="text-left pl-3 f16">ICA</td>
                              <td className="text-left pl-3 f16">0.20</td>
                              <td className="text-left pl-3 f16">0.20</td>
                              <td className="text-left pl-3 f16">0.40</td>
                              <td className="text-left pl-3 f16">0.60</td>
                            </tr>
                            <tr>
                              <td className="text-left pl-3 f16">JUNIN</td>
                              <td className="text-left pl-3 f16">JUNIN</td>
                              <td className="text-left pl-3 f16">0.30</td>
                              <td className="text-left pl-3 f16">0.20</td>
                              <td className="text-left pl-3 f16">0.40</td>
                              <td className="text-left pl-3 f16">0.60</td>
                            </tr>
                            <tr>
                              <td className="text-left pl-3 f16">TRUJILLO I, II, III, CHEPEN</td>
                              <td className="text-left pl-3 f16">LA LIBERTAD</td>
                              <td className="text-left pl-3 f16">0.20</td>
                              <td className="text-left pl-3 f16">0.20</td>
                              <td className="text-left pl-3 f16">0.40</td>
                              <td className="text-left pl-3 f16">0.80</td>
                            </tr>
                            <tr>
                              <td className="text-left pl-3 f16">
                                LAMBAYEQUE I, II, PACASMAYO, CHICLAYO I, II
                              </td>
                              <td className="text-left pl-3 f16">LAMBAYEQUE</td>
                              <td className="text-left pl-3 f16">0.30</td>
                              <td className="text-left pl-3 f16">0.20</td>
                              <td className="text-left pl-3 f16">0.40</td>
                              <td className="text-left pl-3 f16">0.80</td>
                            </tr>
                            <tr>
                              <td className="text-left pl-3 f16">
                                PIURA, I, II, III, IV, VI, VII, TALARA
                              </td>
                              <td className="text-left pl-3 f16">PIURA</td>
                              <td className="text-left pl-3 f16">0.40</td>
                              <td className="text-left pl-3 f16">0.30</td>
                              <td className="text-left pl-3 f16">0.70</td>
                              <td className="text-left pl-3 f16">0.80</td>
                            </tr>
                            <tr>
                              <td className="text-left pl-3 f16">TACNA</td>
                              <td className="text-left pl-3 f16">TACNA</td>
                              <td className="text-left pl-3 f16">0.00</td>
                              <td className="text-left pl-3 f16">0.30</td>
                              <td className="text-left pl-3 f16">0.40</td>
                              <td className="text-left pl-3 f16">0.60</td>
                            </tr>
                          </tbody>
                        </table> */}
                          <div className="table-primax-desktop">
                            <img
                              src={require('../../assets/img/MAILING-WONGPRIME_PRIMAX-DESKTOP-2.png')}
                            />
                          </div>
                          <div className="table-primax-mobile">
                            <img
                              src={require('../../assets/img/MAILING-WONGPRIME_PRIMAX-MOBILE-3.png')}
                            />
                          </div>
                        </div>
                        <p>
                          Puedes hacer valido el descuento mostrando tu DNI y el QR que corresponde
                          al vehículo que has inscrito al beneficio. Adicional, puedes descargar la
                          App Primax Go para poder tener más detalle de tus consumos, descuentos,
                          etc.
                        </p>
                        {/* <p>
                          *Aplica solo en las estaciones operadas directamente por Primax. Revisa el listado <a href="https://primax.com.pe/encuentranos/">aquí</a>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a href="faq#faq" className="btn btn-link btn-block btn-lg mt-3">
              <i className="fa fa-info-circle"></i> Ver todas las preguntas frecuentes
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq
