import React from 'react'
import { Navigate } from 'react-router-dom'
import useLogin from '../../redux/hooks/useLogin'

const PrivateSac = ({ children }) => {
  const { user, token, sessionType } = useLogin()
  if ((!user && !token) || sessionType !== 'SAC') return <Navigate to="/auth/login/sac" />

  return children
}

export default PrivateSac
