import React from 'react'
import Navbar from '../../components/Commons/Navbar'
import Footer from '../../components/Layouts/Footer'

const TermsPromotions = () => {
  return (
    // terms
    <div className="home">
      <Navbar />
      <div className="page_area clearfix" id="terminos">
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-content-md-center">
            <div className="col-12 col-md-6">
              <div className="page-heading text-uppercase">
                <h2>CONDICIONES PROMOCIONALES</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="container py-5">
          <div className="row justify-content-md-center">
            <div className="col-12 col-lg-10 text-muted">
              <h3>Prime Week</h3>
              <p>Vigencia del 12/02/24 al 18/02/24</p>
              <p className="f14" align="justify">
                Las condiciones condiciones de las promociones ofrecidas durante la Prime Week del
                12/2/2024 al 18/2/2024 son las siguientes:
                <ul className="f14 font-weight-light mb-5" align="justify" type="A">
                  <li className="mb-2">
                    KIPLING: Promoción corresponde al 20% de descuento sobre precio regular en los
                    productos de las tiendas Kipling y kipling.com.pe. Válido del 12 al 29 de
                    febrero 2024. Promoción exclusiva para clientes Wong Prime. Para acceder a la
                    promoción en tienda el cliente deberá presentar el app Wong donde figura que es
                    parte de Wong Prime. El descuento no es acumulable con otras promociones y/o
                    descuentos. Promoción válida de lunes a domingo. Stock mínimo: Sujeto a
                    disponibilidad del establecimiento. Para las compras en la web el cliente deberá
                    ingresar el cupón comunicado por Wong Prime. Stock mínimo: 50 promociones.
                  </li>
                  <li className="mb-2">
                    ROXANA PARDO: Promoción corresponde al 20% en los productos seleccionados de las
                    tiendas Roxana Pardo ubicadas en CC Real Plaza, Showroom: Calle. José Del Llano
                    Zapata 331 of.807 Miraflores y web https://pe.roxanapardo.com/. Válido del 12 de
                    febrero al 31 de marzo del 2024. Promoción exclusiva para clientes Wong Prime.
                    Para acceder a la promoción en tiendas el cliente deberá presentar el app Wong
                    donde figura que es parte de Wong Prime. El descuento no es acumulable con otras
                    promociones y/o descuentos. No aplica para tocados/tiaras, aretes bride ni
                    productos pre-order. No válido para colección AMARA ni nuevas colecciones.
                    Promoción válida de lunes a domingo. Stock mínimo: Sujeto a disponibilidad del
                    establecimiento. Para las compras en la web de Roxana Pardo el cliente deberá
                    ingresar el código cupón comunicado por Wong Prime. El código deberá ingresarse
                    todo junto y en mayúsculas. Stock mínimo: 50 promociones.
                  </li>
                  <li className="mb-2">
                    PÁNGUCHE: Promoción válida del 12 al 29 de febrero del 2024. Para acceder a la
                    promoción el cliente deberá presentar el app Wong donde figura que es parte de
                    Wong Prime y/o la comunicación de la promoción. Promoción solo aplica para
                    consumo en tienda o recojo en tienda (take out). La promoción no es acumulable
                    con otros descuentos y/o promociones. Pánguche está ubicado en Manuel Bañon 234
                    San Isidro. El horario de Pánguche es de martes a sábado de 12 a 10pm y domingos
                    de 12 a 9pm, el consumo es solo en el local o para llevar, la promoción no es
                    válida para delivery. Stock mínimo: 50 promociones.
                  </li>
                  <li className="mb-2">
                    ROSSA PIZZA APPASSIONATA: Promoción corresponde al 20% de dcto en la carta.
                    Válida del 12 al 29 de febrero del 2024. Para acceder a la promoción el cliente
                    deberá presentar el app Wong donde figura que es parte de Wong Prime. Válido
                    solo para el horario de almuerzo que es de 12:45 a 5:00pm. La promoción no es
                    acumulable con otras promociones o descuentos. Válido de lunes a domingo. Stock
                    mínimo: 50 promociones.
                  </li>
                  <li className="mb-2">
                    LA CABRERA: Promoción corresponde al 25% dcto en la carta. Válida del 12 al 29
                    de febrero del 2024. Para acceder a la promoción el cliente deberá presentar el
                    app Wong donde figura que es parte de Wong Prime. Válida exclusivamente para el
                    local de El Polo. La promoción no es acumulable con otras promociones o
                    descuentos. Stock mínimo: 50 promociones.
                  </li>
                  <li className="mb-2">
                    UBER BLACK: Código promocional vigente entre las 00:00 AM del 12 de febrero de
                    2024 y hasta las 11:55 PM del 29 de febrero de 2024 o hasta agotar stock, lo que
                    suceda primero ("Vigencia"). Stock mínimo disponible 500 promociones. El código
                    promocional PRIMEWEEK35 otorga un descuento proporcional del 35% de descuento
                    con tope hasta S/15 PEN sobre el precio de los siguientes dos (2) viajes a
                    través de la Aplicación Uber dentro de la Vigencia. El código promocional será
                    válido en un máximo de dos (2) viajes por usuario y únicamente para viajes en
                    Lima Metropolitana (no incluye balnearios). La promoción solo será válida para
                    la opción de Uber Black. El código promocional no es acumulable con otras
                    promociones, ni redimible por dinero en efectivo. En el evento en que el precio
                    aplicable al viaje sea superior al descuento otorgado por el código promocional,
                    el usuario pagará el excedente. En el evento en que el precio del viaje sea
                    inferior al descuento del código promocional, el saldo no será acumulable para
                    viajes posteriores. Aplica para usuarios nuevos y existentes de la aplicación de
                    Uber. En el evento en que el código promocional no sea ejecutable, Uber se
                    reserva el derecho a su entera discreción de generar un nuevo código promocional
                    de las mismas características o si resulta aplicable, acreditar directamente el
                    valor del descuento otorgado por el código promocional a la cuenta del usuario.
                    En el caso que la cuenta del usuario tenga alguna limitación o restricción para
                    aplicar el código promocional, este no será aplicable. El código promocional
                    deberá ser usado de conformidad con los presentes términos y condiciones y a los
                    Términos y Condiciones de uso de la aplicación de Uber.
                  </li>
                  <li className="mb-2">
                    MEGALABS: Promoción válida para compras del 12 al 18 de febrero del 2024 en los
                    productos Megalabs seleccionados para esta promoción. El mail con el código
                    único de gift card será enviado a cada cliente como máximo el 29 de febrero. El
                    código es válido para una manicure en cualquier sede de Montalvo. Promoción no
                    es acumulable con otras promociones o descuentos. Stock mínimo: 200 promociones.
                    Un solo código por cliente. Solo se considerarán válidas las compras de clientes
                    Wong Prime durante la vigencia mencionada.
                  </li>
                  <li className="mb-2">
                    SORTEO PRIME WEEK FEBRERO 2024: Participación del 12 al 18 de febrero del 2024.
                    Participan en el sorteo clientes que hayan hecho compras desde su cuenta Prime
                    en Wong.pe y App Wong entre esas fechas. El sorteo se realizará como máximo el
                    día 23 de febrero del 2024. Los premios no son intercambiables por dinero ni
                    tampoco son transferibles por su titular. Por cada compra realizada entre el 12
                    y 18 de febrero se acumula una opción para ganar en el sorteo. No aplican
                    trabajadores del grupo Cencosud. Los ganadores serán previamente contactados.
                    Habrá 10 ganadores. Los premios consisten en el abono 5,000 Puntos Bonus a cada
                    ganador. El abono del premio se verá reflejado como máximo el 12 de marzo del
                    2024. Para el abono el cliente debe estar previamente registrado en Puntos
                    Bonus.
                  </li>
                </ul>
              </p>
              <h3>Alado</h3>
              <p className="f14" align="justify">
                Promoción válida del 1 al 29 de febrero del 2024. Para acceder a la promoción el
                cliente deberá presentar el app Wong donde figura que es parte de Wong Prime. La
                promoción no es acumulable con otros descuentos y/o promociones. Stock mínimo: 50
                promociones. Consumo mínimo por mesa S/100. Válido solo para consumo en el local.
                Válido solo una promoción por mesa. La promoción consiste en el descorche de solo
                una botella de vino. Las imágenes de la publicidad son referenciales.
              </p>
              <p>
                Promoción válida del 1 al 29 de febrero del 2024. Para acceder a la promoción el
                cliente deberá presentar el app Wong donde figura que es parte de Wong Prime y/o la
                comunicación de la promoción. La promoción no es acumulable con otros descuentos y/o
                promociones. Stock mínimo: 50 promociones. Consumo mínimo por mesa S/150. Válido
                solo para consumo en el local. Válido solo una promoción por mesa. La promoción
                consiste en el descorche de solo una botella de vino y la invitación de un postre.
                El beneficio es solo para clientes top Prime que les haya llegado el mail
                comunicando la promoción. El postre podrá ser Canelón o Alfajor. Las imágenes de la
                publicidad son referenciales.
              </p>
              <h3>Prime Week</h3>
              <p>Vigencia del 4/09/23 al 10/09/23</p>
              <p className="f14" align="justify">
                Las condiciones de las promociones ofrecidas durante la Prime Week del 4/9/2023 al
                10/9/2023 son las siguiente:
                <ul className="f14 font-weight-light mb-5" align="justify" type="A">
                  <li className="mb-2">
                    La Cuadra de Salvador: Promoción válida del 4 de setiembre al 4 de octubre del
                    2023. Para acceder a la promoción el cliente deberá presentar el app Wong donde
                    figura que es parte de Wong Prime y/o la comunicación de la promoción. El
                    descuento no es acumulable con otros descuentos/promociones y solo es válido 1
                    descuento por día. Promoción no válida en feriados. El horario de almuerzo es de
                    12:45 a 5:00pm. Stock mínimo: 5 promociones; sujeto a disponibilidad del
                    establecimiento.
                  </li>
                  <li className="mb-2">
                    La Cabrera: Promoción válida del 4 de setiembre al 4 de octubre del 2023. Para
                    acceder a la promoción el cliente deberá presentar el app Wong donde figura que
                    es parte de Wong Prime y/o la comunicación de la promoción. No incluye bebidas
                    alcohólicas, ni postres ni descorche. Máximo descuento S/100. Válido para los
                    locales de Miraflores y El Polo. La promoción no es acumulable con otras
                    promociones o descuentos. Stock mínimo: 5 promociones; sujeto a disponibilidad
                    del establecimiento
                  </li>
                  <li className="mb-2">
                    Rossa Pizza Appassionata: Promoción válida del 4 de setiembre al 4 de octubre
                    del 2023. Para acceder a la promoción el cliente deberá presentar el app Wong
                    donde figura que es parte de Wong Prime y/o la comunicación de la promoción.
                    Monto máximo de descuento S/100 por mesa. La promoción no es acumulable con
                    otros descuentos y/o promociones. Stock mínimo: 5 promociones; sujeto a
                    disponibilidad del establecimiento
                  </li>
                  <li className="mb-2">
                    Tanta: Promoción válida del 04 de setiembre al 04 de octubre del 2023 o hasta
                    agotar stock. Válido para: 25% de descuento en toda la carta. Descuento máximo
                    por cliente de S/100. Aplica para pagos con tarjetas de crédito débito y/o
                    efectivo Válido 01 promoción por mesa, Valido una promoción por día. Válido solo
                    para consumo en salón. Válido para todos los locales de Tanta a excepción de los
                    locales del Callao y provincia de Arequipa. El cliente deberá brindar su
                    documento de identidad físico (DNI o CE), mostrar correo de comunicación o app
                    acreditando que posee el beneficio al momento de realizar el pedido, de lo
                    contrario el descuento no podrá ser aplicable. No válido para delivery. No
                    acumulable con otras promociones y/o descuentos. Descuento no aplicable sobre
                    productos de vitrina. Sujeto a modificación sin previo aviso de acuerdo con las
                    disposiciones del gobierno. Imágenes referenciales. Stock mínimo: 5 promociones;
                    sujeto a disponibilidad del establecimiento.
                  </li>
                  <li className="mb-2">
                    Pánguche: Promoción válida del 4 de setiembre al 4 de octubre del 2023. Para
                    acceder a la promoción el cliente deberá presentar el app Wong donde figura que
                    es parte de Wong Prime y/o la comunicación de la promoción. Promoción solo
                    aplica para consumo en tienda o recojo en tienda Pánguche (take out). El Burger
                    Pack Prime contiene 1 Doble Panburger+1 Champion Cheese+1 papas+1 Gaseosa al
                    precio regular de S/ 75 y como promoción Wong Prime S/50. La promoción no es
                    acumulable con otros descuentos y/o promociones. Pánguche está ubicado en Manuel
                    Bañon 234 San Isidro. Stock mínimo: 5 promociones; sujeto a disponibilidad del
                    establecimiento.
                  </li>
                  <li className="mb-2">
                    Isolina: Promoción válida del 4 de setiembre al 4 de octubre del 2023. Para
                    acceder a la promoción el cliente deberá presentar el app Wong donde figura que
                    es parte de Wong Prime y/o la comunicación de la promoción. El horario de la
                    cena es de 7pm a 12am. Válido de lunes a domingo. Sujeto a disponibilidad de
                    espacio y aforo. Promoción exclusiva para el local de Isolina Surco (Av. El Polo
                    605). Stock mínimo: 5 promociones; sujeto a disponibilidad del establecimiento
                  </li>
                  <li className="mb-2">
                    Aranwa: Promoción válida del 4 de setiembre al 4 de octubre del 2023. Para
                    acceder a la promoción el cliente deberá generar su reserva en
                    www.aranwahotels.com ingresando el código de cupón (comunicado por Wong Prime).
                    La promoción aplica un 30% de descuento para el destino Cusco Ciudad y 25% de
                    descuento para los destinos de Paracas, Vichayito, Colca y Valle Sagrado. No
                    aplica para fechas festivas ni feriados. No válido con otras promociones y/o
                    descuentos. Válido para reservas del 04 de setiembre al 4 de octubre del 2023,
                    para estadías hasta el 31 de octubre de 2023.Cambios de fecha debe ser
                    solicitado con hasta siete (07) días antes de la fecha de ingreso llamando al
                    (01) 2070440 o escribiendo al correo reservas@aranwahotels.com. Tarifa no
                    reembolsable, aplica 100% de gastos de cancelación. No show, se cargará el 100%
                    de la estadía. Stock mínimo: 5 promociones; sujeto a disponibilidad del
                    establecimiento
                  </li>
                  <li className="mb-2">
                    Tori: Promoción válida del 4 de setiembre al 4 de octubre del 2023. Para acceder
                    a la promoción el cliente deberá presentar el app Wong donde figura que es parte
                    de Wong Prime y/o la comunicación de la promoción. Indispensable presentar DNI
                    en caso se solicite. Válido solo para consumo en salón. Aplica para un descuento
                    máximo de S/100 por mesa. Descuento no acumulable ni válido con otras
                    promociones. Válido para solo 1 consumo en la vigencia de la promoción. Aplican
                    restricciones según disposiciones del gobierno. Stock mínimo: 5 promociones;
                    sujeto a disponibilidad del establecimiento
                  </li>
                  <li className="mb-2">
                    Rutina Café: Promoción válida del 4 de setiembre al 4 de octubre del 2023. Para
                    acceder a la promoción el cliente deberá presentar el app Wong donde figura que
                    es parte de Wong Prime y/o la comunicación de la promoción. La promoción es
                    válida para consumo en local o para delivery. Stock mínimo: 5 promociones;
                    sujeto a disponibilidad del establecimiento.
                  </li>
                  <li className="mb-2">
                    Hoteles Costa del Sol: Promoción válida del 4 al 10 de setiembre del 2023 para
                    alojamientos hasta el 31 de octubre del 2023 (Tener en cuenta que el servicio de
                    alojamiento es sujeto a disponibilidad). Para acceder a la promoción el cliente
                    deberá enviar un correo a corporativo@costadelsolperu.com con copia a
                    isanchez@costadelsolperu.com colocando en el asunto: CLIENTE WONG PRIME. En caso
                    se solicite identificar presentar la app Wong donde figura que es parte de Wong
                    Prime y/o la comunicación de la promoción. La promoción no es acumulable con
                    otros descuentos y/o promociones. Tarifa neta, no incluye impuesto y servicios.
                    Tarifa incluye traslados previa reserva de 48 horas, sujeto a disponibilidad por
                    ser un servicio compartido, aplica solo para las sedes de Arequipa y Pucallpa.
                    Stock mínimo: 5 promociones; sujeto a disponibilidad del establecimiento".
                  </li>
                  <li className="mb-2">
                    Restaurante Paprika: Promoción válida del 4 de setiembre al 4 de octubre del
                    2023. Para acceder a la promoción el cliente deberá presentar la app Wong donde
                    figura que es parte de Wong Prime y/o la comunicación de la promoción. La
                    promoción no es acumulable con otros descuentos y/o promociones. El restaurante
                    se encuentra en Av. Gral. Salaverry 3060 dentro del Hotel Costa del Sol Wyndham
                    Lima City. Stock mínimo: 5 promociones; sujeto a disponibilidad del
                    establecimiento.
                  </li>
                  <li className="mb-2">
                    Walak Bar: Promoción válida del 4 de setiembre al 4 de octubre del 2023. Para
                    acceder a la promoción el cliente deberá presentar el app Wong donde figura que
                    es parte de Wong Prime y/o la comunicación de la promoción. La promoción no es
                    acumulable con otros descuentos y/o promociones. El bar se encuentra en Av.
                    Gral. Salaverry 3060 dentro del Hotel Costa del Sol Wyndham Lima City. Stock
                    mínimo: 5 promociones; sujeto a disponibilidad del establecimiento
                  </li>
                  <li className="mb-2">
                    Pucu Sana: Promoción válida del 4 de setiembre al 4 de octubre del 2023. Para
                    acceder a la promoción el cliente deberá presentar el app Wong donde figura que
                    es parte de Wong Prime y/o la comunicación de la promoción. Es necesario que el
                    cliente presente su DNI en caso sea solicitado. La promoción no es acumulable
                    con otros descuentos y/o promociones. Pucu Sana se encuentra ubicado dentro de
                    Larcomar. Stock mínimo: 5 promociones; sujeto a disponibilidad del
                    establecimiento
                  </li>
                  <li className="mb-2">
                    Isola Bar: Promoción válida del 4 de setiembre al 4 de octubre del 2023. Para
                    acceder a la promoción el cliente deberá presentar el app Wong donde figura que
                    es parte de Wong Prime y/o la comunicación de la promoción. Es necesario que el
                    cliente presente su DNI en caso sea solicitado. La promoción no es acumulable
                    con otros descuentos y/o promociones. Isola Bar se encuentra ubicado dentro de
                    Larcomar. Stock mínimo: 5 promociones; sujeto a disponibilidad del
                    establecimiento.
                  </li>
                  <li className="mb-2">
                    La Fina Endorfina: Promoción válida del 4 de setiembre al 4 de octubre del 2023.
                    Para acceder a la promoción el cliente deberá presentar el app Wong donde figura
                    que es parte de Wong Prime y/o la comunicación de la promoción. Las opciones de
                    jars son carrot cake, terremotito, cabeza de indio, tortita choco, crumble de
                    manzana y Limon Pai. Válido solo para consumo en cafetería. Promoción solo se
                    puede utilizar 1 vez, no aplica en feriados ni es acumulable con otras
                    promociones. Stock mínimo: 5 promociones; sujeto a disponibilidad del
                    establecimiento.
                  </li>
                  <li className="mb-2">
                    Burn50: Promoción válida del 4 de setiembre al 4 de octubre del 2023. Para
                    acceder a la promoción el cliente deberá presentar el app Wong donde figura que
                    es parte de Wong Prime y/o la comunicación de la promoción. Stock sujeto a
                    aforo. Stock mínimo: 5 promociones; sujeto a disponibilidad del establecimiento.
                  </li>
                  <li className="mb-2">
                    Photolife: Promoción válida del 4 de setiembre al 4 de octubre del 2023. Para
                    acceder a la promoción el cliente deberá presentar el app Wong donde figura que
                    es parte de Wong Prime y/o la comunicación de la promoción. Stock sujeto a
                    disponibilidad. El paquete fotográfico está valorizado en S/320.00 e incluye
                    hasta el 70% de descuento por lo que el cliente solo realizará el pago de
                    S/96.00. La sesión incluye 1 hora de atención aproximadamente (entre sesión de
                    fotografía artística y visualización de fotos), 1 fotografía impresa en lienzo
                    de 15x20 cm,1 fotografía digital retocada en alta resolución que será enviada al
                    correo del cliente mediante Google Photos o WeTransfer (La fotografía digital
                    corresponde a la fotografía impresa). La sesión fotográfica es válida en los
                    estudios Photolife de Lima, Huancayo y Arequipa. Para agendar la cita, el
                    cliente deberá comunicarse con Photolife Studios vía WhatsApp +51 16449028 o
                    http://wa.me/5116449028 o llamando al (01) 224 4029. Para más información
                    comunicarse con Photolife Studios vía redes sociales. Las citas están sujetas a
                    disponibilidad de horarios. La promoción aplica para personas mayores de edad,
                    en caso contrario, deberán asistir con un adulto responsable o presentando una
                    constancia de permiso por parte de sus padres o apoderados. Se podrá participar
                    en la sesión con un máximo de 8 personas (familia, pareja, mascotas, niños y
                    bebés mayores a 3 meses) para evitar aglomeraciones. Este Pack Promocional
                    incluye 1 foto digital y 1 impresa en lienzo (Ambas fotografías son de la misma
                    imagen. Esta promoción NO incluye todas las fotos de la sesión. NO incluye
                    marco). La fotografía digital se entrega en una resolución de 72ppp y la
                    fotografía impresa en lienzo se entrega en una resolución de 300ppp. Ver
                    estudios en: https://www.photolifestudios.com/#nuestros-estudios. Válido hasta
                    agotar stock. No acumulable con otras promociones y/o descuentos. Stock mínimo:
                    5 promociones; sujeto a disponibilidad del establecimiento".
                  </li>
                </ul>
              </p>

              <h3>Cyber Wow</h3>
              <p>Vigencia del 22/10/23 al 29/10/23</p>
              <p className="f14" align="justify">
                El periodo gratuito de prueba para Wong Prime extendido a 30 días es válido
                únicamente para usuarios que se hayan inscrito durante la vigencia de la campaña:
                del 22 de octubre del 2023 al mediodía al 29 de octubre del 2023. El Periodo
                gratuito de prueba se extiende por un plazo de 30 días desde la fecha de
                suscripción, contabilizada desde el día posterior en el que realizó y aceptó la
                suscripción por parte de Cencosud. Para utilizar los beneficios ofrecidos en el
                Programa Wong Prime, el Usuario debe estar inscrito en los registros de la Web Wong,
                App Wong y las plataformas de los otros socios comerciales que otorguen beneficios,
                y el correo electrónico y datos de identificación del Usuario deben coincidir con
                aquellos registrados en sus respectivos sitios web. Para mayor detalle ingresar a{' '}
                <a href="terminos-y-condiciones/#terminos">
                  https://wongprime.pe/terminos-y-condiciones/#terminos
                </a>
              </p>

              <p className="f14" align="justify">
                Precios y ofertas válidas exclusivas para clientes Prime de Wong que tengan la
                membresía activa al momento de la compra. Precios y ofertas válidas del 22 octubre
                al medio día hasta las 22:59 del mismo día y/o hasta agotar stock, para compras
                exclusivas en Wong.pe y Wong App. Stock mínimo disponible 5 und./Packs/kg. Máximo 3
                promociones por producto por cliente y/o por tarjeta. No acumulable con otras
                promociones y/o descuentos. No válido para compras mayoristas. No acumulable con
                otras promociones. Los precios publicados en Wong.pe y Wong App ya incluyen el
                descuento. Precios sugeridos y surtido de acuerdo con disponibilidad de nuestras
                tiendas y almacén de reparto. No aplica cambios ni devoluciones en los productos
                marcados como "Productos de Liquidación".
              </p>

              <h3>Cyber Days</h3>
              <p className="f14" align="justify">
                <strong>
                  Delivery gratis desde S/99 en productos de Electro con promoción Prime.
                </strong>
                <br />
                Exclusivo WONG PRIME. La promoción es válida del 13 al 19 de noviembre del 2023,
                durante la campaña de Cyber Days. El Cliente debe realizar compras a partir de S/99
                desde su cuenta Prime en productos de Electro y Tecno que tengan alguna promoción
                Prime. Válido máximo en 1 compra por cliente. No aplica para compras mayoristas.
                Válido solo para compras en Wong.pe y Wong App. Wong se reserva el derecho de
                validar la identificación de los usuarios y de no atender los pedidos que incumplan
                las condiciones descritas. Capacidad sujeta a disponibilidad. Promoción válida hasta
                agotar stock o fondo promocional. Válido solo para Lima Metropolitana. Precios
                sugeridos y surtido de acuerdo con disponibilidad de nuestras tiendas.
              </p>
              <p className="f14" align="justify">
                <strong>
                  Triple acumulación de puntos bonus en Wong.pe y la App Wong para compras Wong
                  Prime.
                </strong>
                <br />
                La promoción es válida desde el 13 al 19 de noviembre del 2023, durante la campaña
                de Cyber Days. El beneficio será otorgado por el socio comercial Loyalty Perú S.A.C.
                y consiste en triplicar el bono de puntos por cada compra que el cliente suscrito a
                Wong Prime realice en Web Wong o App Wong. El abono total de puntos se verá
                reflejado para el cliente máximo el día 19 de diciembre del 2023. Se tomará como
                referencia para la aplicación de este beneficio lo establecido en los Términos y
                Condiciones del programa Puntos Bonus.
              </p>

              <h3>Delivery gratis por compras en supermercados desde S. 79.90</h3>
              <p className="f14" align="justify">
                Desde el 01 de noviembre del 2023 los clientes Wong Prime podrán acceder al delivery
                gratis por compras desde 79.90. No aplica para compras mayoristas. Wong se reserva
                el derecho de no atender los pedidos que incumplan las condiciones descritas.
                Capacidad sujeta a disponibilidad. No acumulable con otras promociones. No aplica
                para productos Electro, Tecno, Muebles, Colchones y Máquinas de Deportes o productos
                con despacho directo de almacén o despacho desde proveedor. Válido solo para compras
                de supermercado en Wong.pe y Wong App. Aplica misma cobertura que Wong delivery.
              </p>

              <h3>TOUS</h3>
              <p className="f14" align="justify">
                Promoción corresponde al 15% en toda la tienda Tous de CC Jockey Plaza, CC San
                Miguel, CC La Rambla san Borja y CC El bosque San Isidro. Válido del 20 de noviembre
                al 20 de diciembre 2023. Promoción exclusiva para clientes Wong Prime. Para acceder
                a la promoción el cliente deberá presentar el app Wong donde figura que es parte de
                Wong Prime. El descuento no es acumulable con otras promociones y/o descuentos.
                Promoción válida inclusive en días feriados.Stock mínimo: Sujeto a disponibilidad
                del establecimiento. No aplica a joyas atelier ni fragancias. Solo válido en
                establecimientos físicos de Tous.
              </p>

              <h3>BIMBA Y LOLA</h3>
              <p className="f14" align="justify">
                Promoción corresponde al 20% en los productos de las tiendas Bimba y Lola ubicadas
                en CC Jockey Plaza y CC El bosque San Isidro. Válido del 20 de noviembre al 20 de
                diciembre 2023. Promoción exclusiva para clientes Wong Prime. Para acceder a la
                promoción el cliente deberá presentar el app Wong donde figura que es parte de Wong
                Prime. El descuento no es acumulable con otras promociones y/o descuentos. Promoción
                válida inclusive en días feriados. Stock mínimo: Sujeto a disponibilidad del
                establecimiento. Solo válido en establecimientos físicos de Bimba y Lola.
              </p>

              <h3>ROXANA PARDO</h3>
              <p className="f14" align="justify">
                Promoción corresponde al 15% en los productos de las tiendas Roxana Pardo ubicadas
                en CC Real Plaza y Showroom: Calle. José Del Llano Zapata 331 of.807
                Miraflores.Válido del 17 de noviembre al 17 de diciembre 2023. Promoción exclusiva
                para clientes Wong Prime. Para acceder a la promoción el cliente deberá presentar el
                app Wong donde figura que es parte de Wong Prime. El descuento no es acumulable con
                otras promociones y/o descuentos. No aplica para tocados/tiaras, aretes bride ni
                productos pre-order ni colección AMARA. Promoción válida inclusive en días
                feriados.Stock mínimo: Sujeto a disponibilidad del establecimiento.Monto mínimo de
                compra S/120. Solo válido en establecimientos físicos de Roxana Pardo.
              </p>

              <h3>SORTEO ANIVERSARIO WONG PRIME</h3>
              <p className="f14" align="justify">
                Participación del 4 al 10 de diciembre del 2023. Participan en el sorteo clientes
                que hayan hecho compras desde su cuenta Prime en Wong.pe y App Wong entre esas
                fechas. El sorteo se realizará el día 12 de diciembre del 2023. Los premios no son
                intercambiables por dinero ni tampoco son transferibles por su titular.
              </p>

              <p className="f14" align="justify">
                Por cada compra realizada entre el 4 y 10 de diciembre se acumula una opción para
                ganar en el sorteo. No aplican trabajadores del grupo Cencosud ni compras
                mayoristas. El sorteo solo aplica a clientes en Lima.
              </p>

              <p className="f14" align="justify">
                En el caso de la cena la fecha de entrega es entre el 22 al 24 de diciembre. Los
                ganadores serán previamente contactados para la coordinación de la entrega de los
                premios, para el recojo de premios los ganadores deberán acercarse de forma
                presencial a las oficinas de Cencosud ubicada en Calle Augusto Angulo 130,
                urbanización San Antonio, distrito de Miraflores. En el caso de la cena la fecha de
                entrega es entre el 22 al 24 de diciembre y solo aplica a repartos en Lima
                Metropolitana; no incluye reparto a playas.
              </p>

              <p className="f14" align="justify">
                Habrá 8 ganadores. Los premios consisten en: Whisky Chivas Regal Gold Signature 18
                Años Botella 700ml + 2 panetones D ́onofrio Panetoncito Caja 90 g + 2 Espumante Dulce
                Riccadonna Asti Botella 200ml (4 ganadores), Twopack Whisky Johnnie Walker Black
                Label 12 Años Botella 750ml + 2 panetones D ́onofrio Panetoncito Caja 90 g + 2
                Espumante Dulce Riccadonna Asti Botella 200ml (3 ganadores), Cena Tradición 6
                Personas + 4 Espumante Dulce Riccadonna Asti Botella 200ml + 4 panetones D ́onofrio
                Panetoncito Caja 90 g (1 ganador). No aplican trabajadores del grupo Cencosud.
              </p>

              <h3>Promoción Cupón Diciembre 2023</h3>
              <p className="f14" align="justify">
                20% de descuento en productos de supermercado. Promoción válida del 14 al 31 de
                diciembre del 2023, aplica únicamente para los clientes que demuestren la
                comunicación por tratarse de una invitación exclusiva. Tope promocional de descuento
                de hasta S/ 50 por cliente por compras mayores a S/ 120. Aplica solo para
                supermercado. Válido para compras en Web y App de Wong. Válido para una compra por
                cliente durante la vigencia de la promoción. El beneficio aplica para la primera
                compra que cumpla las condiciones de la campaña, no es acumulable con otras
                promociones y es válido respetando el mínimo de compra y tope de descuento
                establecido.
              </p>

              <h3>Campaña 30 Días Periodo de Prueba Gratis</h3>
              <p className="f14" align="justify">
                El periodo gratuito de prueba para Wong Prime extendido a 30 días es válido
                únicamente para usuarios que hayan recibido la comunicación de la campaña y se hayan
                inscrito en el plazo de 1 mes desde que recibieron dicha comunicación. El Periodo
                gratuito de prueba se extiende por un plazo de 30 días desde la fecha de
                suscripción, contabilizada desde el día posterior en el que realizó y aceptó la
                suscripción por parte de Cencosud. Para utilizar los beneficios ofrecidos en el
                Programa Wong Prime, el Usuario debe estar inscrito en los registros de la Web Wong,
                App Wong y las plataformas de los otros socios comerciales que otorguen beneficios,
                y el correo electrónico y datos de identificación del Usuario deben coincidir con
                aquellos registrados en sus respectivos sitios web. Para mayor detalle ingresar a{' '}
                <a href="terminos-y-condiciones/#terminos">
                  https://wongprime.pe/terminos-y-condiciones/#terminos
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default TermsPromotions
