import { combineReducers } from "redux";
import LoginReducers from "./LoginReducers";
import { RegisterReducers } from "./RegisterReducers";
import SacReducers from "./SacReducers";
import CouponReducers from "./CouponReducers";
import RegisterPayUReducers from "./RegisterPayUReducers";

const RootReducer = combineReducers({
  auth: LoginReducers,
  register: RegisterReducers,
  sac: SacReducers,
  payu: RegisterPayUReducers,
  coupon: CouponReducers,
});

export default RootReducer;
