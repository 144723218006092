import React from 'react'
import { Formik, Field, Form } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import { handleSequeiryValidation, initialSecurityValues } from './constans'
import { Link } from 'react-router-dom'
import { useRegister } from './hooks'
import IFCondition from '../../../components/Commons/IFCondition/IFCondition'
import { GOOGLE_CAPTCHA_KEY } from '../../../utils/constants'
import { useErrorsGTM } from '../../../services/Gtm/gtmInteractions'

const SecurityStep = (props) => {
  const { registerUser, setStep } = props
  const recaptchaRef = React.createRef()
  const { isLoading, showPass, setShowPass, errorMessage } = useRegister()
  useErrorsGTM(errorMessage)
  console.log(errorMessage)
  return (
    <>
      <Formik
        className="mt-4"
        initialValues={initialSecurityValues}
        validationSchema={handleSequeiryValidation}
        onSubmit={registerUser}>
        {({ values, errors, touched, isValid, dirty, setFieldValue }) => (
          <Form>
            <hr />
            <div className="form-group">
              <div className="form-group password optional user_password">
                <label className="password optional" htmlFor="user_password">
                  <i className="fa fa-key"></i> Contraseña
                </label>
                <div className="input-group mb-2">
                  <Field
                    className={`form-control ${
                      errors.password && touched.password ? 'is-invalid' : ''
                    }`}
                    type={!showPass.pass ? 'password' : 'text'}
                    name="password"
                    id="user_password"
                    autoComplete="false"
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      onClick={() => setShowPass({ ...showPass, pass: !showPass.pass })}
                      type="button">
                      {!showPass.pass ? (
                        <i className="fa fa-eye" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-eye-slash" aria-hidden="true"></i>
                      )}
                    </button>
                  </div>
                </div>
                {errors.password && touched.password_confirmation ? (
                  <div className="text-danger">{errors.password}</div>
                ) : null}
                <small className="form-text text-muted">
                  Tu contraseña debe tener entre 8 y 12 caracteres
                </small>
              </div>
            </div>

            <div className="form-group">
              <div className="form-group password optional user_password_confirmation">
                <label className="password optional" htmlFor="user_password_confirmation">
                  <i className="fa fa-key"></i> Repite tu contraseña
                </label>
                <div className="input-group mb-2">
                  <Field
                    className={`form-control ${
                      errors.password_confirmation && touched.password_confirmation
                        ? 'is-invalid'
                        : ''
                    }`}
                    type={!showPass.confirmPass ? 'password' : 'text'}
                    name="password_confirmation"
                    id="user_password_confirmation"
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      onClick={() =>
                        setShowPass({ ...showPass, confirmPass: !showPass.confirmPass })
                      }
                      type="button">
                      {!showPass.confirmPass ? (
                        <i className="fa fa-eye" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-eye-slash" aria-hidden="true"></i>
                      )}
                    </button>
                  </div>
                </div>
                {errors.password_confirmation && touched.password_confirmation ? (
                  <div className="text-danger">{errors.password_confirmation}</div>
                ) : null}
                <small className="form-text text-muted"></small>
              </div>
            </div>

            <div className="form-group">
              <label className="control-label">
                Código Promocional <small className="text-muted">(Opcional)</small>
              </label>

              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-tag"></i>
                  </span>
                </div>
                <Field
                  type="text"
                  className="form-control"
                  placeholder="Ingresar código promocional"
                  name="coupon_code"
                  aria-label=""
                  aria-describedby=""
                />
              </div>
            </div>

            <hr />
            <div className="form-check text-dark mb-2 pl-0">
              <div className="form-group boolean optional user_accepts">
                <div className="form-check">
                  <Field
                    className={`form-check-input boolean`}
                    type="checkbox"
                    name="accepts"
                    id="user_accepts"
                  />

                  <label className="form-check-label boolean optional" htmlFor="user_accepts">
                    Declaro haber leído y aceptar los{' '}
                    <a href="/terminos-y-condiciones" target="_blank">
                      Términos y Condiciones
                    </a>{' '}
                    y las
                    <a href="/politicas-y-privacidad" target="_blank">
                      {' '}
                      Políticas de Privacidad y Datos Personales
                    </a>{' '}
                    del programa Wong Prime
                  </label>
                  {errors.accepts && touched.accepts ? (
                    <div className="text-danger">{errors.accepts}</div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="form-check text-dark mb-2 pl-0">
              <div className="form-group boolean optional user_accepts_policies">
                <div className="form-check">
                  <Field
                    className={`form-check-input boolean`}
                    type="checkbox"
                    name="accepts_policies"
                    id="user_accepts_policies"
                  />

                  <label
                    className="form-check-label boolean optional"
                    htmlFor="user_accepts_policies">
                    Acepto el tratamiento de mis datos para
                    <a
                      href="https://www.wong.pe/institucional/tratamientos-para-fines-adicionales"
                      target="_blank"
                      rel="noopener noreferrer">
                      {' '}
                      Finalidades Adicionales
                    </a>
                  </label>
                  {errors.accepts_policies && touched.accepts_policies ? (
                    <div className="text-danger">{errors.accepts_policies}</div>
                  ) : null}
                </div>
              </div>
            </div>

            <hr />
            <ReCAPTCHA
              sitekey={GOOGLE_CAPTCHA_KEY}
              ref={recaptchaRef}
              name="reCaptcha"
              onChange={(value) => {
                setFieldValue('reCaptcha', value)
              }}
            />
            <IFCondition cond={errors.reCaptcha && touched.reCaptcha}>
              <div className="text-danger">{errors.reCaptcha}</div>
            </IFCondition>

            <hr />
            <div className="botones text-right">
              <Link to="/" className="btn btn-link">
                Cancelar
              </Link>
              <button
                type="button"
                className={`btn btn-secundary`}
                onClick={() => {
                  props.previousStep()
                  registerUser(values)
                  setStep(2)
                }}>
                Volver
              </button>
              <button
                type="button"
                onClick={() => {
                  registerUser(values, true)
                }}
                className={`btn btn-primary`}
                disabled={!(isValid && dirty) || isLoading}>
                Continuar
              </button>
            </div>

            <IFCondition cond={errorMessage}>
              <div
                className={`alert alert-${
                  errorMessage?.internal_message?.level || 'danger'
                } alert-dismissible fade show mt-4`}
                role="alert">
                <strong>
                  <i className="fa fa-exclamation-circle"></i>{' '}
                  {errorMessage?.internal_message?.title || 'Lo sentimos'}
                </strong>{' '}
                <br />
                {errorMessage?.user_message ? errorMessage?.user_message : errorMessage}
                {errorMessage?.internal_message?.link ? (
                  <Link to={errorMessage?.internal_message?.link}>
                    {errorMessage?.internal_message?.linkName}
                  </Link>
                ) : (
                  ''
                )}
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </IFCondition>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default SecurityStep
