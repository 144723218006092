import React from 'react'
import { Formik, Field, Form } from 'formik'
import { handleAddressValidation, initialAddressValues } from './constans'
import { Link } from 'react-router-dom'
import { useRegister, useParametersAddress } from './hooks'

const AddressStep = (props) => {
  const { registerUser, setStep } = props
  const { isLoading } = useRegister()
  const { parameters, searchCities, searchDistricts } = useParametersAddress()

  return (
    <>
      <Formik
        className="mt-4"
        initialValues={initialAddressValues}
        validationSchema={handleAddressValidation}
        onSubmit={registerUser}>
        {({ errors, touched, isValid, dirty, values, setFieldValue }) => (
          <Form>
            <div className="form-group">
              <label className="string optional" htmlFor="user_Número de celular">
                Número de celular
              </label>
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <div className="input-group-text">+51</div>
                </div>
                <Field
                  className={`form-control string tel optional ${
                    errors.phone && touched.phone ? 'is-invalid' : ''
                  }`}
                  type="number"
                  name="phone"
                  id="user_phone"
                />
                <br />
              </div>
              {errors.phone && touched.phone ? (
                <div className="text-danger">{errors.phone}</div>
              ) : null}
            </div>
            <div className="form-group">
              <label htmlFor="name" className="control-label">
                Correo Electrónico
              </label>
              <Field
                type="text"
                className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                name="email"
                /*disabled={oldValues.active}*/
                id="email"
                placeholder="Ingrese su Email"
              />

              {errors.email && touched.email ? (
                <div className="text-danger">{errors.email}</div>
              ) : null}
            </div>
            <div className="form-group">
              <div className="form-group email optional user_email_confirmation">
                <label className="email optional" forhtml="user_email_confirmation">
                  Repite tu correo electrónico
                </label>
                <Field
                  className={`form-control ${
                    errors.email_confirmation && touched.email_confirmation ? 'is-invalid' : ''
                  }`}
                  placeholder="ejemplo@email.com"
                  type="email"
                  name="email_confirmation"
                  id="user_email_confirmation"
                />

                {errors.email_confirmation && touched.email_confirmation ? (
                  <div className="text-danger">{errors.email_confirmation}</div>
                ) : null}
              </div>
            </div>
            <div className="form-group">
              <label className="string optional" htmlFor="address">
                Dirección
              </label>
              <div className="input-group mb-2">
                <Field
                  className={`form-control string tel optional ${
                    errors.address && touched.address ? 'is-invalid' : ''
                  }`}
                  placeholder="Av. Princial"
                  type="text"
                  name="address"
                  id="address"
                />
                <br />
              </div>
              {errors.address && touched.address ? (
                <div className="text-danger">{errors.address}</div>
              ) : null}
            </div>

            <div className="">
              {/* <label className="string optional" htmlFor="country">
                País
              </label> */}
              <div className="">
                <Field
                  className={`string tel optional ${
                    errors.country && touched.country ? 'is-invalid' : ''
                  }`}
                  disabled={true}
                  hidden={true}
                  type="text"
                  name="country"
                  id="country"
                />
                <br />
              </div>
              {errors.country && touched.country ? (
                <div className="text-danger">{errors.country}</div>
              ) : null}
            </div>

            <div className="form-group">
              <label className="string optional" htmlFor="department">
                Departamento
              </label>
              <div className="input-group mb-2">
                <Field
                  as="select"
                  id="department"
                  name="department"
                  onChange={(e) => {
                    setFieldValue('department', e.target.value)
                    searchCities(e.target.value)
                  }}
                  className="form-control mx-1">
                  <option value="">-</option>
                  {parameters.departments.map((department, index) => {
                    return (
                      <option key={index} value={department.code}>
                        {department.description}
                      </option>
                    )
                  })}
                </Field>
                <br />
              </div>
              {errors.department && touched.department ? (
                <div className="text-danger">{errors.department}</div>
              ) : null}
            </div>

            <div className="form-group">
              <label className="string optional" htmlFor="city">
                Provincia
              </label>
              <div className="input-group mb-2">
                <Field
                  as="select"
                  id="city"
                  name="city"
                  onChange={(e) => {
                    setFieldValue('city', e.target.value)
                    searchDistricts(e.target.value)
                  }}
                  className="form-control mx-1">
                  <option value="">-</option>
                  {parameters.cities.map((city, index) => {
                    return (
                      <option key={index} value={city.code}>
                        {city.description}
                      </option>
                    )
                  })}
                </Field>
                <br />
              </div>
              {errors.city && touched.city ? (
                <div className="text-danger">{errors.city}</div>
              ) : null}
            </div>

            <div className="form-group">
              <label className="string optional" htmlFor="district">
                Distritos
              </label>
              <div className="input-group mb-2">
                <Field as="select" id="district" name="district" className="form-control mx-1">
                  <option value="">-</option>
                  {parameters.districts.map((district, index) => {
                    return (
                      <option key={index} value={district.code}>
                        {district.description}
                      </option>
                    )
                  })}
                </Field>
                <br />
              </div>
              {errors.district && touched.district ? (
                <div className="text-danger">{errors.district}</div>
              ) : null}
            </div>

            <hr />
            <div className="botones text-right">
              <Link to="/" className="btn btn-link btn-left">
                Cancelar
              </Link>
              <button
                type="button"
                className={`btn btn-secundary`}
                onClick={() => {
                  props.previousStep()
                  registerUser(values)
                  setStep(1)
                }}>
                Volver
              </button>
              <button
                type="button"
                className={`btn btn-primary block`}
                onClick={() => {
                  props.nextStep()
                  registerUser(values)
                  setStep(3)
                }}
                disabled={!(isValid && dirty) || isLoading}>
                Continuar
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default AddressStep
