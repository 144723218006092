import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { setAuthData } from '../../redux/actions/LoginActions'
import { AppDispatch } from '../../redux/utils/AppDispatch'
import { getParameters } from '../../services/Register'

export const useRegisterPromotion = (setEndTrial) => {
  const [parameters, setParameters] = useState({
    dniTypes: [],
    genders: [],
    departments: [],
    cities: [],
    districts: [],
  })
  const [showPass, setShowPass] = useState({
    pass: false,
    confirmPass: false,
  })

  const searchCities = (city) => {
    //console.log("cities", `reference=${city}`);
    if (city) {
      getParameters('cities', `reference=${city}`).then((res) => {
        setParameters({ ...parameters, cities: res.data.data.data })
      })
    }
  }

  const searchDistricts = (districts) => {
    if (districts) {
      getParameters('district', `reference=${districts}`).then((res) => {
        setParameters({ ...parameters, districts: res.data.data.data })
      })
    }
  }

  useEffect(() => {
    Promise.all([
      getParameters('dniTypes'),
      getParameters('genders'),
      getParameters('departments'),
      getParameters('cities', `reference=15`),
      getParameters('districts', `reference=26`),
    ]).then(([dni, gender, department, cities, districts]) => {
      setParameters({
        dniTypes: dni.data.data.data,
        genders: gender.data.data.data,
        departments: department.data.data.data,
        cities: cities.data.data.data,
        districts: districts.data.data.data,
      })
    })
  }, [])

  const navigate = useNavigate()
  const [oldValues, setOldValues] = useState({
    oldDni: null,
    oldEmail: null,
    validDni: true,
    validEmail: true,
    active: false,
  })

  const { errorMessage, data, isLoading, from } = useSelector((state) => state.register)

  useEffect(() => {
    if (data && data.data.needCard) {
      AppDispatch(setAuthData(data))
      navigate('/auth/register-premium-save', { state: { data: data.data } })
    } else if (data) {
      navigate('/auth/register-premium-success', { state: { data: data.data } })
    }
  }, [data])

  return {
    isLoading,
    errorMessage,
    parameters,
    searchCities,
    searchDistricts,
  }
}
