export const maxDate = () => {
    const d = new Date();
    const year = d.getFullYear();
    d.setFullYear(year - 18);
    return d;
}

export const yearsDate = () => {
    const max = new Date().getFullYear() - 18
    const min = max - 100
    const years = []

    for (let i = max; i >= min; i--) {
        years.push(i)
    }
    return years
}

export const monthsDate = () => {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    return months
}

export const daysDate = () => {
    const days = [];
    for (let i = 1; i <= 31; i++) {
        days.push(valideDayAndMonth(i));
    }
    return days;
}

export const fullDate = (date) => {
    return `${valideDayAndMonth(date.getDate())}/${valideDayAndMonth(date.getMonth()+1)}/${date.getFullYear()}`
}

export const addDay = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return `${valideDayAndMonth(result.getDate())}/${valideDayAndMonth(result.getMonth()+1)}/${result.getFullYear()}`
}

export const quitDay = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return `${valideDayAndMonth(result.getDate())}/${valideDayAndMonth(result.getMonth()+1)}/${result.getFullYear()}`
}

export function getYear() {
    return new Date().getFullYear()
}

export const valideDayAndMonth = (dateOrMonth) => {
    return dateOrMonth < 10 ? `0${dateOrMonth}` : dateOrMonth
}

export const convertDate = (dateString) => {
    const date = new Date(dateString);
    return {
        day: valideDayAndMonth(date.getDate()),
        month: valideDayAndMonth(date.getMonth()),
        year: date.getFullYear()
    }
}