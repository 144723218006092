import React from 'react'
import { Formik, Field, Form } from 'formik'
import { yearsDate, monthsDate, daysDate, valideDayAndMonth } from '../../../utils/dateForm'
import { handlePerfonalInfoValidation, initialPersonalValues } from './constans'
import { useRegister, usePersonalInfo } from './hooks'
import { Link } from 'react-router-dom'

const PersonalInfoStep = (props) => {
  //Falta revisar hooks e imports utilizados. Ya fue revisado el cuerpo del componente

  const { registerUser, setStep } = props
  const { isLoading, oldValues } = useRegister()
  const { parameters } = usePersonalInfo()

  return (
    <>
      <Formik
        className="mt-4"
        initialValues={initialPersonalValues}
        validationSchema={handlePerfonalInfoValidation}
        onSubmit={registerUser}>
        {({ errors, touched, isValid, dirty, values, setFieldValue }) => (
          <Form>
            <hr />
            {/* inicio parte copiada             */}
            <div className="form-group">
              <label htmlFor="name" className="control-label">
                Tipo identificación
              </label>
              <div className="doc_type" style={{ display: 'flex', justifyContent: 'start' }}>
                {parameters.dniTypes.map((dniType, index) => {
                  return (
                    <div key={index} style={{ marginLeft: '15px' }}>
                      <Field type="radio" name="dni_type" value={dniType.code} />
                      <label style={{ marginLeft: '5px' }}>{dniType.description}</label>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="dni" className="control-label">
                Documento de identidad
              </label>
              <Field
                type="text"
                className={`form-control ${errors.dni ? 'is-invalid' : ''}`}
                name="dni"
                id="dni"
              />
              {errors.dni ? <div className="text-danger">{errors.dni}</div> : null}
            </div>
            <div className="form-group">
              <label htmlFor="name" className="control-label">
                Nombre
              </label>
              <Field
                type="text"
                className={`form-control ${
                  errors.first_name && touched.first_name ? 'is-invalid' : ''
                }`}
                name="first_name"
                id="name"
                placeholder="Ingresa tu nombre"
              />

              {errors.first_name && touched.first_name ? (
                <div className="text-danger">{errors.first_name}</div>
              ) : null}
            </div>
            <div className="form-group">
              <label htmlFor="name" className="control-label">
                Primer Apellido
              </label>
              <Field
                type="text"
                className={`form-control ${
                  errors.last_name && touched.last_name ? 'is-invalid' : ''
                }`}
                name="last_name"
                id="last_name"
                placeholder="Ingresa tu primer apellido"
              />

              {errors.last_name && touched.last_name ? (
                <div className="text-danger">{errors.last_name}</div>
              ) : null}
            </div>
            <div className="form-group">
              <label htmlFor="name" className="control-label">
                Segundo Apellido
              </label>
              <Field
                type="text"
                className="form-control"
                name="last_name_2"
                id="last_name_2"
                placeholder="Ingresa tu segundo apellido"
              />
              {errors.last_name_2 && touched.last_name_2 ? (
                <div className="text-danger">{errors.last_name_2}</div>
              ) : null}
            </div>

            <div className="form-group">
              <div className="form-group date optional user_bday">
                <label className="date optional" htmlFor="day">
                  Fecha de nacimiento
                </label>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <Field
                    as="select"
                    id="day"
                    name="bday.day"
                    className="form-control mx-1 date optional">
                    <option value="">-</option>
                    {daysDate().map((e, key) => {
                      return (
                        <option key={key} value={e}>
                          {e}
                        </option>
                      )
                    })}
                  </Field>
                  <Field as="select" name="bday.month" className="form-control mx-1 date optional">
                    <option value="">-</option>
                    {monthsDate().map((e, key) => {
                      return (
                        <option key={key} value={valideDayAndMonth(key)}>
                          {e}
                        </option>
                      )
                    })}
                  </Field>
                  <Field as="select" name="bday.year" className="form-control mx-1 date optional">
                    <option value="">-</option>
                    {yearsDate().map((e, key) => {
                      return (
                        <option key={key} value={e}>
                          {e}
                        </option>
                      )
                    })}
                  </Field>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="form-group optional">
                <label className="date optional" htmlFor="gender">
                  Género
                </label>
                <Field as="select" id="gender" name="gender" className="form-control mx-1">
                  <option value="">-</option>
                  {parameters.genders.map((gender, index) => {
                    return (
                      <option key={index} value={gender.code}>
                        {gender.description}
                      </option>
                    )
                  })}
                </Field>
              </div>
            </div>
            {/* fin parte copiada */}

            <div className="botones text-right">
              <Link to="/" className="btn btn-link btn-left">
                Cancelar
              </Link>
              <button
                type="button"
                className={`btn btn-primary block`}
                onClick={() => {
                  props.nextStep()
                  registerUser(values)
                  setStep(2)
                }}
                disabled={!(isValid && dirty) || isLoading || !oldValues.validEmail}>
                Continuar
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default PersonalInfoStep
