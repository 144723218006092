import { removeAxiosToken, setAxiosToken } from '../../services/Api'

export const LOADING = 'login/LOADING'
export const SET_TOKEN = 'login/SET_TOKEN'
export const LOGIN_SUCCESS = 'login/LOGIN_SUCCESS'
export const LOGIN_FAILED = 'login/LOGIN_FAILED'
export const CLOSE_SESION_FAILED = 'login/CLOSE_SESION_FAILED'
export const LOGIN_PASSWORD_CHANGED = 'login/LOGIN_PASSWORD_CHANGED'
export const LOGOUT = 'login/LOGOUT'
export const ENABLE_USER = 'login/ENABLE_USER'
export const UPDATE_USER = 'login/UPDATE_USER'
export const INFO_BENEFITS_SUCCESS = 'login/INFO_BENEFITS_SUCCESS'
export const INFO_BENEFITS_FAIL = 'login/INFO_BENEFITS_FAIL'

const hydrateToken = () => {
  const token = localStorage.getItem('auth_token')
  return token || null
}

const hydrateSessionType = () => {
  const s_type = localStorage.getItem('session_type')
  return s_type || null
}

const initialState = {
  user: null,
  passChanged: false,
  errorMessage: null,
  isLoading: false,
  token: hydrateToken(),
  sessionType: hydrateSessionType(),
}

const LoginReducers = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case SET_TOKEN:
      setAxiosToken(action.payload.data, action.payload.sessionType)
      return {
        ...state,
        token: action.payload.data,
        sessionType: action.payload.sessionType,
      }
    case LOGIN_PASSWORD_CHANGED:
      return {
        ...state,
        isLoading: false,
        passChanged: true,
      }
    case UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          first_name: action.payload.data.first_name,
          last_name: action.payload.data.last_name,
          fullname: action.payload.data.first_name + ' ' + action.payload.data.last_name,
          phone: action.payload.data.phone,
          bday: action.payload.data.bday,
          gender:
            action.payload.data.gender === '0'
              ? 'Otro'
              : action.payload.data.gender === '1'
              ? 'Femenino'
              : 'Masculino',
          address: action.payload.data.address,
        },
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        user: action.payload.data.data,
      }
    case INFO_BENEFITS_SUCCESS:
      return {
        ...state,
        purchases: action.payload.data.data.purchases,
        benefitsSummary: action.payload.data.data.summary,
      }
    case LOGOUT:
      console.log('cierra sesion')
      removeAxiosToken()
      return {
        isLoading: false,
        errorMessage: null,
        passChanged: false,
        user: null,
        token: null,
      }
    case ENABLE_USER:
      const userEnabled = { ...state.user, status: 2 }
      return {
        ...state,
        user: userEnabled,
      }
    case LOGIN_FAILED:
    case INFO_BENEFITS_FAIL:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.error,
      }
    case CLOSE_SESION_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.error,
      }
    default:
      return state
  }
}

export default LoginReducers
