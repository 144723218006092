import React from "react";
import Detail from "./Detail";
import ModalChangeCard from "./ModalChangeCard";
import ModalChangePassword from "./ModalChangePassword";
import ModalCancel from "./ModalCancel";
import ModalChangeProfile from "./ModalChangeProfile";
import useLogin from "../../../redux/hooks/useLogin";

const Account = () => {
  const { user } = useLogin();
  return (
    <>
      <Detail user={user} />
      <ModalChangeProfile />
      <ModalChangeCard user={user}/>
      <ModalChangePassword />
      <ModalCancel user={user} />
      {/* <ModalChangePayU user={user} /> */}
    </>
  );
};

export default Account;
