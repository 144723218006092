import React from "react";
import Navbar from "../../components/Commons/Navbar";
import Footer from "../../components/Layouts/Footer";
import Header from "./Header";
import FaqContent from "./FaqContent";
const Faq = () => {
  return (
    <div className="home">
      <Navbar />
      <Header />
      <FaqContent />
      <Footer />
    </div>
  );
};

export default Faq;
