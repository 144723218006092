import React from 'react'
import { style1 } from '../../../views/Profile/Index/styles'
import icon from '../../../assets/img/icon-profile-header.svg'
import useLogin from '../../../redux/hooks/useLogin'
import logo from '../../../assets/img/logo-header-profile.svg'

export const ProfileSubHeader = () => {
  const { user, benefitsSummary } = useLogin()
  return (
    <>
      <div className="header pb-6">
        <div className="container-fluid">
          <div className="header-body">
            {/* Card stats */}
            <div className="pt-4">
              <div className="container-header-profile">
                <div className="col-lg-2 container-image-profile col-md-3 col-sm-4 col-6">
                  <img src={logo} alt="wongprime" class="image-logo-profile" />
                </div>
                <div className="col-xl-3 text-sm-left pr-1 title-names-profile">
                  <h1 className="text-capitalize f40" style={{ lineHeight: '1' }}>
                    {user?.first_name} {user?.last_name}
                  </h1>
                  <h2 className="mt-0 mb-3 f20">Bienvenido a tu portal de usuario Prime.</h2>
                </div>
                <div className="col">
                  <div className="cards-saving-container">
                    <h4>Tus ahorros</h4>
                    <p>
                      Esto has acumulado desde el <strong>{user?.benefit_start_date}</strong>
                    </p>
                    <div className="cards-saving-cards">
                      <div className="d-flex" style={{ borderBottom: '1px solid #E2E2E2' }}>
                        <div className="cards-saving-mobile">
                          <div className="cards-saving-first">
                            <div className="card-saving card-saving_one">
                              <h5 className=" text-capitalize font-weight-normal mb-0">Envíos </h5>
                              <span className="d-flex mb-0 header-card-content">
                                {benefitsSummary?.shipped}
                                <img src={icon} alt="icon" />
                              </span>
                            </div>

                            <div className=" card-saving card-saving_two">
                              <h5 className=" text-capitalize font-weight-normal mb-0">
                                Ahorro en envíos{' '}
                              </h5>
                              <span className="d-flex mb-0 header-card-content">
                                {benefitsSummary?.discounted}
                                <img src={icon} alt="icon" />
                              </span>
                            </div>
                          </div>

                          <div className="cards-saving-second">
                            <div className=" card-saving card-saving_three">
                              <h5 className=" text-capitalize font-weight-normal mb-0">
                                Ahorro en ofertas exclusivas{' '}
                              </h5>
                              <span className="d-flex mb-0 header-card-content">
                                S/{Math.abs(benefitsSummary?.prime_discounts)}
                                <img src={icon} alt="icon" />
                              </span>
                            </div>

                            <div className=" card-saving card-saving_four">
                              <h5 className=" text-capitalize font-weight-normal mb-0">
                                Puntos acumulados{' '}
                              </h5>
                              <span
                                className="d-flex mb-0 header-card-content"
                                style={{ alignItems: 'center' }}>
                                {benefitsSummary?.points}
                                <small> pts.</small>
                                <img src={icon} alt="icon" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h6 className="mb-0">
                        * Ahorro de envíos estimado en base a tarifa estándar de delivery para
                        pedidos de supermercado.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
