import React, { useRef, useState, useEffect } from "react";
import IFCondition from "../../components/Commons/IFCondition/IFCondition";
import { GOOGLE_CAPTCHA_KEY } from "../../utils/constants";
import ReCAPTCHA from "react-google-recaptcha";
import {toast} from 'react-toastify'
import { Formik, Field, Form } from "formik";
import { initialDocumentValues, handleDocumentChangeValidation } from "./constants";
import { useGetParameters, useSacSensitive } from "./hooks";
import Loading from '../../components/Commons/Loading/Loading'
import Spinner from '../../components/Commons/Spinner/Spinner'

const ModalChangeDocument = ({data}) => {
  const {handleChangeData, loading} = useSacSensitive();
  const recaptchaRef = React.createRef()
  const { parameters } = useGetParameters()
  const [dniTypeValue, setDniTypeValue] = useState('')
  const [oldDocument, setOldDocument] = useState('')
  const { user } = data.data.user_detail
  const btnModal = useRef();
  const modalDocument = useRef();

  const handleChangeDocument = (value)=>{
    handleChangeData(value);
    window.$('#change-document').modal('hide');
    
  }

  const getDocumentType = (dniType)=>{
    if(parameters.dniTypes.length){
      const documentType = parameters.dniTypes.find(dni => dni.description === dniType)
      return documentType?.code;
    }
  }
    useEffect(()=>{
      if(user){
        const value = getDocumentType(user.dniType);
        setDniTypeValue(value)
        setOldDocument(user.dni)
      }
    }, [user, parameters])
  return (
    <>
    <div
      className="modal fade"
      id="change-document"
      tabIndex="-1"
      role="dialog"
      aria-labelledby=""
      aria-hidden="true" 
      ref={modalDocument}
      >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
              <h2>
              Modificación de documento
            </h2>
          </div>
          <Formik
            className="mt-4"
            initialValues={initialDocumentValues}
            validationSchema={handleDocumentChangeValidation}
            onSubmit={handleChangeDocument} >
              {({ values, errors, touched, isValid, dirty, setFieldValue }) => (
          <Form className="form">
            <div className="modal-body">
              <div className="form-group">
                <label>Documento actual: </label>{" "}
                <label><strong>{user?.dni}</strong></label>
                <br/>
                <label>Tipo de documento: </label>{" "}
                <label><strong>{user?.dniType}</strong></label>
              </div>
              <div className="form-group">
              </div>
              <div className="form-group">
                <label htmlFor="dni">Nuevo documento</label>
                <Field
                  type="hidden"
                  id="dni_type"
                  name="dni_type"
                  value={dniTypeValue}
                  onSubmit={() => {
                    setFieldValue('dni_type', dniTypeValue)
                  }}
                />
                <Field
                  type="hidden"
                  id="oldValue"
                  name="oldValue"
                  value={oldDocument}
                  onSubmit={() => {
                    setFieldValue('oldValue', oldDocument)
                  }}
                />
                <Field
                  type="hidden"
                  id="updateType"
                  name="updateType"
                  value={2}
                  onSubmit={() => {
                    setFieldValue('updateType', 2)
                  }}
                />
                <Field
                  type="number"
                  className={`form-control ${
                    errors.newValue && touched.newValue ? 'is-invalid' : ''
                  }`}
                  id="newValue"
                  name="newValue"
                  required
                  onFocus={() => {
                    setFieldValue('dni_type', dniTypeValue)
                    setFieldValue('oldValue', oldDocument)
                    setFieldValue('updateType', 2)
                  }}
                />
                {errors.newValue && touched.newValue ? (
                  <div className="text-danger">{errors.newValue}</div>
                ) : null}
              </div>
              <div className="form-group">
                <Field
                as='textarea'
                  rows="6"
                  className="form-control"
                  id="observation"
                  name="observation"
                  required
                  placeholder="Dejanos saber los motivos por los cuales solicitas esta modificación."
                  // onChange={formik.handleChange}
                  // value={formik.values.confirmPassword}
                />
                {errors.observation && touched.observation ? (
                  <div className="text-danger">{errors.observation}</div>
                ) : null}
              </div>

              <IFCondition cond={process.env.REACT_APP_ENV !== 'local'}>
                <ReCAPTCHA
                sitekey={GOOGLE_CAPTCHA_KEY}
                ref={recaptchaRef}
                name="reCaptcha"
                onChange={(value) => {
                  setFieldValue('reCaptcha', value)
                }}
              /> 
            </IFCondition>
             <IFCondition cond={errors.reCaptcha && touched.reCaptcha}>
              <div className="text-danger">{errors.reCaptcha}</div>
            </IFCondition>
            </div>
            {/* <IFCondition cond={errorMessage}>
              <div className="alert alert-warning">{errorMessage}</div>
            </IFCondition> */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-secondary"
                ref={btnModal}
                data-dismiss="modal">
                Cancelar
              </button>
              
              <button
                type="submit"
                className={`btn btn-primary`}
                disabled={!(isValid && dirty)}
                >
                Continuar
              </button>
            </div>
          </Form>
              )}
          </Formik>
        </div>
      </div>
    </div>
    <Spinner visible={loading} />
    <Loading visible={loading} />
    </>
  );
};

export default ModalChangeDocument;
