

export const USER_INFO_LOAD = "sac/USER_INFO_LOAD";
export const USER_INFO_SUCCESS = "sac/USER_INFO_SUCCESS";
export const USER_INFO_FAILED = "sac/USER_INFO_FAILED";

const initialValues = { data: '', isLoading: false, errorMessage: '' }

const SacReducers = (state = initialValues, action) => {
    switch (action.type) {
        case USER_INFO_LOAD:
            return {
                ...state,
                isLoading: true,
                data: ''
            };
        case USER_INFO_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                isLoading: false,
                errorMessage: action.payload.error,
            };

        case USER_INFO_FAILED:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload.error,
            };
        default:
            return state;
    }
};

export default SacReducers;
